import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modules, Usecases, Domain } from '../core'

import NearbyStoreList from '../components/screens/NearbyStoreInput'
interface Data {
  storeList: Array<Domain.Store.Entity>
}
export default function NearbyStoreListContainer() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const [stores, setStores] = React.useState<Array<Domain.Store.Entity>>([])
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchNearbyStore(prefectureCd: string | undefined) {
        const didCallFetchNearbyStoresApi = (data: Data) => {
          setStores(data.storeList)
        }
        const reloader = () => {
          dispatch(
            Usecases.NearbyStores.fetchNearbyStore(
              reloader,
              prefectureCd,
              didCallFetchNearbyStoresApi
            )
          )
        }
        dispatch(
          Usecases.NearbyStores.fetchNearbyStore(
            reloader,
            prefectureCd,
            didCallFetchNearbyStoresApi
          )
        )
      },
      registerStore(
        storeToRegister: Domain.StoreToRegister.Entity,
        navigate: () => void
      ) {
        dispatch(Usecases.Stores.registerStore(storeToRegister, navigate))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )
  return <NearbyStoreList tenant={tenant} stores={stores} actions={actions} />
}
