import * as React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import { COLORS } from '../../../../constants'
import { Domain } from '../../../../core'
import stylesModule from '../styles.module.css'

interface Props {
  totalPrice: number
  prize: Domain.Event.PrizeEntity
  isSelected: boolean
  onPress: (prize: Domain.Event.PrizeEntity) => void
}

const styles = StyleSheet.create({
  outerContainer: {
    marginTop: 16,
    borderRadius: 5,
    borderWidth: 4,
    backgroundColor: COLORS.PINK_A,
    borderColor: COLORS.PINK_A,
  },
  outerContainerIsSelected: {
    backgroundColor: COLORS.ORANGE,
    borderColor: COLORS.ORANGE,
  },
  innerContainer: {
    borderRadius: 3,
    borderWidth: 0,
    backgroundColor: COLORS.PINK_A,
    paddingTop: 20,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  title: {
    fontSize: 22,
    fontWeight: '600',
    lineHeight: 24,
    color: COLORS.TOKYU_BLACK,
  },
  description: {
    marginTop: 4,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  imageContainer: {
    marginTop: 16,
  },
  prizeUnits: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
    color: COLORS.ORANGE,
    textAlign: 'center',
  },
  checkboxContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxNormal: {
    width: 15,
    height: 15,
    borderRadius: 2,
    borderColor: COLORS.ORANGE,
    borderWidth: 2,
    backgroundColor: COLORS.PINK_A,
  },
  checkboxIsSelected: {
    backgroundColor: COLORS.ORANGE,
  },
  checkboxText: {
    marginStart: 8,
    fontSize: 15,
    fontWeight: '300',
    lineHeight: 15,
    letterSpacing: -0.24,
    color: COLORS.TOKYU_BLACK,
  },
  checkmark: {
    borderColor: COLORS.WHITE,
    borderEndWidth: 2,
    borderBottomWidth: 2,
    top: '-10%',
    left: '25%',
    transform: [{ rotate: '45deg' }],
    width: 5,
    height: 10,
  },
  checkboxCannotBeSelected: {
    width: 15,
    height: 15,
    borderRadius: 2,
    borderColor: COLORS.GRAY_E,
    borderWidth: 2,
  },
  checkboxCannotBeSelectedCross: {
    position: 'absolute',
    width: 2,
    height: 10,
    top: '50%',
    left: '50%',
    backgroundColor: COLORS.GRAY_E,
  },
  checkboxCannotBeSelectedCrossLeft: {
    transform: [{ translateX: -1 }, { translateY: -5 }, { rotate: '-45deg' }],
  },
  checkboxCannotBeSelectedCrossRight: {
    transform: [{ translateX: -1 }, { translateY: -5 }, { rotate: '45deg' }],
  },
})

export default function Prize(props: Props) {
  const { totalPrice, prize, isSelected, onPress } = props

  // 応募口数計算
  const numberOfUnits = React.useMemo(
    () => Math.floor(totalPrice / prize.price),
    [totalPrice, prize.price]
  )

  const canBeSelected = numberOfUnits > 0

  return (
    <TouchableOpacity
      style={
        isSelected
          ? [styles.outerContainer, styles.outerContainerIsSelected]
          : styles.outerContainer
      }
      onPress={() => {
        if (canBeSelected) {
          onPress(prize)
        }
      }}
    >
      <View style={styles.innerContainer}>
        <Text style={styles.title}>{prize.title}</Text>
        <Text style={styles.description}>{prize.description}</Text>
        <View style={styles.imageContainer}>
          <img alt="" src={prize.image} className={stylesModule.prizeImage} />
        </View>
        <Text style={styles.prizeUnits}>
          {canBeSelected
            ? `${numberOfUnits}口の応募が可能です`
            : `${prize.price}円コースの対象商品です`}
        </Text>
        <View style={styles.checkboxContainer}>
          {canBeSelected && (
            <View
              style={
                isSelected
                  ? [styles.checkboxNormal, styles.checkboxIsSelected]
                  : styles.checkboxNormal
              }
            >
              {isSelected && <View style={styles.checkmark} />}
            </View>
          )}
          {canBeSelected || (
            <View style={styles.checkboxCannotBeSelected}>
              <View
                style={[
                  styles.checkboxCannotBeSelectedCross,
                  styles.checkboxCannotBeSelectedCrossLeft,
                ]}
              />
              <View
                style={[
                  styles.checkboxCannotBeSelectedCross,
                  styles.checkboxCannotBeSelectedCrossRight,
                ]}
              />
            </View>
          )}
          <Text style={styles.checkboxText}>この賞品を選択</Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}
