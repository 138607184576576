import * as React from 'react'
import { StyleSheet, View, Text, Image, Keyboard } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { CardType } from '../../../constants/card-types'
import { Domain } from '../../../core'
import { STYLES } from '../../../constants'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import ButtonSubmit from '../../share/ButtonSubmit'
import PhoneNumber from './PhoneNumber'
import BirthDate from './BirthDate'
import CardNo from './CardNo'

const styles = StyleSheet.create({
  cardImage: {
    paddingBottom: '12%',
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  cardName: {
    textAlign: 'center',
    fontSize: 16,
    marginVertical: 10,
  },
})

type CardToRegister = Domain.CreditCardToRegister.Entity | null

interface Props {
  userStatus: Domain.UserStatus.Entity | null
  actions: {
    fetchUserStatus: () => void
    registerCard: (card: CardToRegister, navigate: () => void) => void
    updateCard: (card: CardToRegister, navigate: () => void) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

const cardCd = CardType.tokyuCard

export default function NormalCardInput(props: Props) {
  const {
    userStatus,
    actions: {
      fetchUserStatus,
      registerCard,
      updateCard,
      clearScreenEmbeddedErrorAlert,
    },
  } = props

  const navigate = useNavigate()

  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  React.useEffect(() => fetchUserStatus(), [fetchUserStatus])

  const [phoneNo, setPhoneNo] = React.useState<string>('')
  const [birthYear, setBirthYear] = React.useState<string>('')
  const [birthMonth, setBirthMonth] = React.useState<string>('')
  const [birthDay, setBirthDay] = React.useState<string>('')
  const [creditNo6Digits, setCreditCardNo6Digits] = React.useState<string>('')
  const [creditNo4Digits, setCreditCardNo4Digits] = React.useState<string>('')
  const [phoneNoisValid, setPhoneNoisValid] = React.useState<boolean>(false)
  const [birthdateIsValid, setBirthdateIsValid] = React.useState<boolean>(false)
  const [cardNoisValid, setCardNoisValid] = React.useState<boolean>(false)

  const submitButtonEnabled =
    phoneNoisValid &&
    birthdateIsValid &&
    cardNoisValid &&
    phoneNo !== '' &&
    birthYear !== '' &&
    birthMonth !== '' &&
    birthDay !== '' &&
    creditNo6Digits !== '' &&
    creditNo4Digits !== ''

  const submitButtonTitle = React.useMemo(() => {
    return userStatus?.cardRegist
      ? 'カード情報を更新する'
      : 'カード情報を登録する'
  }, [userStatus])

  const top = React.useRef<View>(null)
  const scroll = (view: React.RefObject<View>) => {
    if (view && view.current) {
      view.current.focus()
    }
  }
  const navigateToCardRegistrationCompleted = React.useCallback(
    () => navigate(`/card-registration-completed/${userStatus?.storeRegist}`),
    [navigate, userStatus]
  )
  const handleOnPressSubmit = React.useCallback(() => {
    Keyboard.dismiss()
    scroll(top)
    const cardEntered = {
      cardCd,
      phoneNo,
      birthYear,
      birthMonth,
      birthDay,
      creditNo6Digits,
      creditNo4Digits,
    }
    if (userStatus?.cardRegist) {
      updateCard(cardEntered, navigateToCardRegistrationCompleted)
    } else {
      registerCard(cardEntered, navigateToCardRegistrationCompleted)
    }
  }, [
    userStatus,
    phoneNo,
    birthYear,
    birthMonth,
    birthDay,
    creditNo6Digits,
    creditNo4Digits,
    registerCard,
    updateCard,
    navigateToCardRegistrationCompleted,
  ])

  return (
    <View style={STYLES.generalStyle.container}>
      <View>
        <PageTitle title="カード情報を入力してください" />
        <Image
          source={require('../../../assets/images/normal_card.png')}
          style={styles.cardImage}
        />
        <Text ref={top} style={styles.cardName}>
          TOKYU CARDを選択中
        </Text>
        <PhoneNumber
          setPhoneNoisValid={setPhoneNoisValid}
          phoneNo={phoneNo}
          setPhoneNo={setPhoneNo}
        />
        <BirthDate
          setBirthdateIsValid={setBirthdateIsValid}
          birthDay={birthDay}
          setBirthDay={setBirthDay}
          birthMonth={birthMonth}
          setBirthMonth={setBirthMonth}
          birthYear={birthYear}
          setBirthYear={setBirthYear}
        />
        <CardNo
          setCardNoisValid={setCardNoisValid}
          creditNo6Digits={creditNo6Digits}
          setCreditCardNo6Digits={setCreditCardNo6Digits}
          creditNo4Digits={creditNo4Digits}
          setCreditCardNo4Digits={setCreditCardNo4Digits}
        />
        <ButtonSubmit
          onPress={handleOnPressSubmit}
          disabled={!submitButtonEnabled}
          text={submitButtonTitle}
        />
      </View>
      <QnA />
    </View>
  )
}
