import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../../constants'
import { Domain } from '../../../../core'
import Prize from './Prize'

interface Props {
  totalPrice: number
  prizes: Domain.Event.PrizeEntity[]
  selectedPrize: Domain.Event.PrizeEntity | undefined
  setSelectedPrize: React.Dispatch<
    React.SetStateAction<Domain.Event.PrizeEntity | undefined>
  >
}

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  step: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  selectPrizeDescription: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 23,
    color: '#616161',
  },
})

const isSelectedPrize = (
  selectedPrize: Domain.Event.PrizeEntity | undefined,
  targetPrize: Domain.Event.PrizeEntity
) => selectedPrize?.prizeId === targetPrize.prizeId

export default function SelectPrize(props: Props) {
  const { totalPrice, prizes, selectedPrize, setSelectedPrize } = props

  return (
    <View style={styles.container}>
      <Text style={styles.step}>④賞品を選択</Text>
      <Text style={styles.selectPrizeDescription}>
        以下より賞品をお選びください
      </Text>
      {prizes.map((p) => (
        <Prize
          key={p.prizeId}
          totalPrice={totalPrice}
          prize={p}
          isSelected={isSelectedPrize(selectedPrize, p)}
          onPress={(pressedPrize: Domain.Event.PrizeEntity) => {
            if (!isSelectedPrize(selectedPrize, pressedPrize)) {
              setSelectedPrize(pressedPrize)
            }
          }}
        />
      ))}
    </View>
  )
}
