import { Dispatch } from 'redux'
import { Api } from '../../lib'
import * as Domain from '../domain'

export function fetchService(reloader: () => void, didCallFetchServicesListApi: (data: Domain.Service.ServiceEntity) => void, link?: string) {
  const options = {
    reload: reloader,
  }

  return async function(dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      '',
      '',
      null,
      didCallFetchServicesListApi,
      dispatch,
      options,
      link
    )
  }
}
