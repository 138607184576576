import * as React from 'react'
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native'
import InfiniteScroll from 'react-infinite-scroller'
import { useNavigate } from 'react-router-dom'
import { Domain } from '../../../core'
import { COLORS, FONTS } from '../../../constants'
import Event from '../../share/Event'
import QnA from '../../share/QnA'
import PrivacyPolicy from '../../share/PrivacyPolicy'

const styles = StyleSheet.create({
  gerneralPadding: {
    paddingHorizontal: 15,
  },
  primaryBg: {
    backgroundColor: COLORS.PINK_A,
  },
  eventWrap: {
    paddingBottom: 24,
  },
  title: {
    color: COLORS.ORANGE,
    fontSize: FONTS.SIZE.M,
    fontWeight: '600',
    paddingTop: 24,
    paddingBottom: 8,
  },
  textFooterWrap: {
    marginLeft: 15,
    textAlign: 'left',
  },
  qnaText: {
    color: COLORS.ORANGE,
  },
  loadingIcon: {
    marginVertical: 15,
  },
  marutokuTopText: {
    marginTop: 60,
    marginBottom: 23,
    marginHorizontal: 'auto',
  },
  qnaCustomStyle: {
    marginBottom: 40,
    marginHorizontal: 'auto',
  },
})

type PaginationObjectType = {
  length: number
  numberItemsPagination: number
}
interface Props {
  eventList: Array<Domain.Event.ListEntity>
  currentDate: string
  actions: {
    fetchEndedEventsList: () => void
  }
}

export default function EndedEventsList(props: Props) {
  const {
    eventList,
    currentDate,
    actions: { fetchEndedEventsList },
  } = props

  const initialCount = 10
  const [activeEvents, setActiveEvents] = React.useState<
    Array<Domain.Event.ListEntity>
  >([])
  const [deactiveEvents, setDeactiveEvents] = React.useState<
    Array<Domain.Event.ListEntity>
  >([])
  const [numberItemsPaginationActive, setNumberItemsPaginationActive] =
    React.useState(initialCount)
  const [numberItemsPaginationDeactive, setNumberItemsPaginationDeactive] =
    React.useState(initialCount)
  const [isLoadMoreActiveEvent, setIsLoadMoreActiveEvent] = React.useState(true)
  const [isHasMore, setIsHasMore] = React.useState(false)

  function sortEvent(arr: Array<Domain.Event.ListEntity>) {
    return arr.sort(
      (a: Domain.Event.ListEntity, b: Domain.Event.ListEntity) => {
        return Date.parse(b.startDate) - Date.parse(a.startDate)
      }
    )
  }

  React.useEffect(() => {
    const activeEventsTemp = eventList.filter(
      (event: Domain.Event.ListEntity) => {
        return (
          Date.parse(event.startDate) <= Date.parse(currentDate) &&
          Date.parse(currentDate) <= Date.parse(event.endDate)
        )
      }
    )
    setActiveEvents(sortEvent(activeEventsTemp))
    if (activeEventsTemp.length > initialCount) {
      setIsHasMore(true)
    }
  }, [eventList, currentDate, setActiveEvents])

  React.useEffect(() => {
    const deactiveEventsTemp = eventList.filter(
      (event: Domain.Event.ListEntity) => {
        return (
          Date.parse(event.endDate) <= Date.parse(currentDate) &&
          Date.parse(currentDate) <= Date.parse(event.displayEndDate)
        )
      }
    )
    setDeactiveEvents(sortEvent(deactiveEventsTemp))
    if (deactiveEventsTemp.length > initialCount) {
      setIsHasMore(true)
    }
  }, [eventList, currentDate, setDeactiveEvents])

  const loadMoreItems = (arg: PaginationObjectType) => {
    if (window.scrollY !== 0) {
      let numberItems
      if (arg.numberItemsPagination + initialCount < arg.length) {
        numberItems = arg.numberItemsPagination + initialCount
      } else {
        numberItems = arg.length
        setIsLoadMoreActiveEvent(false)
      }
      if (isLoadMoreActiveEvent) {
        setNumberItemsPaginationActive(numberItems)
      } else {
        setNumberItemsPaginationDeactive(numberItems)
        if (arg.numberItemsPagination + initialCount >= arg.length) {
          setIsHasMore(false)
        }
      }
    }
  }

  const navigate = useNavigate()

  const goToDetail = React.useCallback(
    (eventId: string) => {
      const location = {
        pathname: `/events/${eventId}`,
      }

      const locationHasSearch = { ...location, search: 'back=true' }
      navigate(locationHasSearch)
    },
    [navigate]
  )

  const titlePage = '終了したLINE友だちキャンペーン'
  const paginationObject: PaginationObjectType = {
    length: isLoadMoreActiveEvent ? activeEvents.length : deactiveEvents.length,
    numberItemsPagination: isLoadMoreActiveEvent
      ? numberItemsPaginationActive
      : numberItemsPaginationDeactive,
  }
  React.useEffect(() => {
    fetchEndedEventsList()
  }, [fetchEndedEventsList])

  const qnaTokyuSoreTitle = '制度詳細・よくある質問'
  const qnaOtherAppTitle = 'よくある質問はこちら'
  const [isTokyuStore, setIsTokyuStore] = React.useState(false)

  React.useEffect(() => {
    if (process.env.REACT_APP_TENANT_ID === '0101') {
      setIsTokyuStore(true)
    }
  }, [])

  return (
    <View>
      <View style={[styles.primaryBg, styles.eventWrap]}>
        <Text style={[styles.title, styles.gerneralPadding]}>{titlePage}</Text>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => loadMoreItems(paginationObject)}
          hasMore={isHasMore}
          loader={
            <ActivityIndicator
              key={0}
              animating
              color={COLORS.GRAY_C}
              size={30}
              style={styles.loadingIcon}
            />
          }
          initialLoad={false}
        >
          <Event
            lists={activeEvents.slice(0, numberItemsPaginationActive)}
            theme={'dark'}
            emptyMsg={'表示対象の終了キャンペーンはありません。'}
            onPress={goToDetail}
            isShowEmpty={!eventList.length}
            isHideEntryStatus
          />
          <Event
            lists={deactiveEvents.slice(0, numberItemsPaginationDeactive)}
            theme={'dark'}
            emptyMsg={'表示対象の終了キャンペーンはありません。'}
            onPress={goToDetail}
            isHideEntryStatus
          />
        </InfiniteScroll>
      </View>

      {isTokyuStore && (
        <QnA
          title={'まる得 TOP'}
          textStyle={styles.qnaText}
          textFooterWrap={styles.textFooterWrap}
          customUrl={'/top-campaign'}
          customStyle={styles.marutokuTopText}
        />
      )}

      <QnA
        title={isTokyuStore ? qnaTokyuSoreTitle : qnaOtherAppTitle}
        textStyle={styles.qnaText}
        textFooterWrap={styles.textFooterWrap}
        isExternalLink={!!isTokyuStore}
        customStyle={isTokyuStore ? styles.qnaCustomStyle : undefined}
      />

      <PrivacyPolicy
        textStyle={styles.qnaText}
        textFooterWrap={styles.textFooterWrap}
      />
    </View>
  )
}
