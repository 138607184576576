import { combineReducers } from 'redux'

import * as ErrorModule from './error'
import * as Network from './network'
import * as Card from './card'
import * as Stores from './stores'
import * as NearbyStores from './nearbyStores'
import * as Prefectures from './prefectures'
import * as Municipalities from './municipalities'
import * as UserStatus from './userStatus'
import * as Location from './location'
import * as FAQ from './faq'
import * as Tenant from './tenant'
import * as CheckEvent from './checkEvent'
import * as Receipt from './receipt'

export {
  ErrorModule,
  Network,
  Card,
  Stores,
  NearbyStores,
  Prefectures,
  Municipalities,
  UserStatus,
  Location,
  FAQ,
  Tenant,
  CheckEvent,
  Receipt,
}

export type AppState = {
  readonly error: ReturnType<typeof ErrorModule.createInitialState>
  readonly network: ReturnType<typeof Network.createInitialState>
  readonly card: ReturnType<typeof Card.createInitialState>
  readonly stores: ReturnType<typeof Stores.createInitialState>
  readonly nearbyStores: ReturnType<typeof NearbyStores.createInitialState>
  readonly prefectures: ReturnType<typeof Prefectures.createInitialState>
  readonly municipalities: ReturnType<typeof Municipalities.createInitialState>
  readonly userStatus: ReturnType<typeof UserStatus.createInitialState>
  readonly location: ReturnType<typeof Location.createInitialState>
  readonly faq: ReturnType<typeof FAQ.createInitialState>
  readonly checkEvent: ReturnType<typeof CheckEvent.createInitialState>
  readonly tenant: ReturnType<typeof Tenant.createInitialState>
  readonly receipt: ReturnType<typeof Receipt.createInitialState>
}

export function createInitialState(): AppState {
  return {
    error: ErrorModule.createInitialState(),
    network: Network.createInitialState(),
    card: Card.createInitialState(),
    stores: Stores.createInitialState(),
    nearbyStores: NearbyStores.createInitialState(),
    prefectures: Prefectures.createInitialState(),
    municipalities: Municipalities.createInitialState(),
    userStatus: UserStatus.createInitialState(),
    location: Location.createInitialState(),
    faq: FAQ.createInitialState(),
    checkEvent: CheckEvent.createInitialState(),
    tenant: Tenant.createInitialState(),
    receipt: Receipt.createInitialState(),
  }
}

function createAppReducer() {
  return combineReducers<AppState>({
    error: ErrorModule.reducer,
    network: Network.reducer,
    card: Card.reducer,
    stores: Stores.reducer,
    nearbyStores: NearbyStores.reducer,
    prefectures: Prefectures.reducer,
    municipalities: Municipalities.reducer,
    userStatus: UserStatus.reducer,
    location: Location.reducer,
    faq: FAQ.reducer,
    checkEvent: CheckEvent.reducer,
    tenant: Tenant.reducer,
    receipt: Receipt.reducer,
  })
}

export default createAppReducer
