/* eslint-disable no-console */
import React, { useCallback } from 'react'
import { View } from 'react-native'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReceiptOCR from '../components/screens/ReceiptOCR'
import { Modules, Usecases } from '../core'
import { FetchReceiptUploadURLResponseEntity } from '../core/domain/event'
import { compressFile } from '../core/functions/file'
import { beginCommunication, endCommunication } from '../core/modules/network'

const { liff } = window

export default function ReceiptOCRContainer() {
  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )

  const navigate = useNavigate()
  const navigateToEventDetail = useCallback(() => {
    navigate(`/events/${receipt.event?.eventId}`)
  }, [navigate, receipt.event])

  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      uploadImage: async (
        totalPrice: number,
        userModifiedTotalPriceAfterOCR?: boolean
      ) => {
        // エントリー済み画像チェックOK
        const didCallValidateReceiptApi = () => {
          dispatch(Modules.Receipt.setTotalPrice(totalPrice))
          dispatch(
            Modules.Receipt.setTotalPriceIsModifiedAfterOCR(
              userModifiedTotalPriceAfterOCR
            )
          )
          navigateToEventDetail()
        }

        const didUploadImage = (userReceiptImagePath: string) => {
          // エントリー済み画像チェック
          dispatch(
            Usecases.Event.validateReceipt(
              {
                eventId: receipt.event?.eventId ?? '',
                tenantId: process.env.REACT_APP_TENANT_ID ?? '',
                userReceiptImagePath,
              },
              didCallValidateReceiptApi
            )
          )
        }

        const didCallFetchReceiptUploadURLApi = (
          data: FetchReceiptUploadURLResponseEntity
        ) => {
          if (receipt.fileBlob == null) return
          if (receipt.fileType == null) return

          const { userReceiptImagePath } = data
          dispatch(Modules.Receipt.setImagePath(userReceiptImagePath))

          // レシートアップロード
          dispatch(
            Usecases.Event.uploadImage(
              data.receiptUploadUrl,
              receipt.fileBlob,
              receipt.fileType,
              userReceiptImagePath,
              didUploadImage
            )
          )
        }

        // レシートアップロード用URLを取得
        dispatch(
          Usecases.Event.fetchReceiptUploadURL(
            receipt.event?.eventId ?? '',
            receipt.fileExtension ?? '',
            didCallFetchReceiptUploadURLApi
          )
        )
      },
      willLoadFile: () => {
        dispatch(beginCommunication())
      },
      didFinishFileLoading: () => {
        dispatch(endCommunication())
      },
      fileLoadingOnSuccess: (
        fileDataURL: string,
        fileBlob: Blob,
        fileType: string,
        fileExtension: string
      ) => {
        // 同一ファイル選択時にOCRをトリガーするために一度空にしてからfileDataURLをセットする
        dispatch(Modules.Receipt.setFile('', undefined, '', ''))
        dispatch(
          Modules.Receipt.setFile(
            fileDataURL,
            fileBlob,
            fileType,
            fileExtension
          )
        )
      },
      uploadImageForReceiptOCR: async (
        originalDataURL: string,
        setTotalPrice: React.Dispatch<React.SetStateAction<string>>,
        setOCRTotalPrice: React.Dispatch<React.SetStateAction<string>>,
        setOCRIsSuccess: React.Dispatch<
          React.SetStateAction<boolean | undefined>
        >,
        setUserModifiedTotalPriceAfterOCR: React.Dispatch<
          React.SetStateAction<boolean | undefined>
        >
      ) => {
        let base64EncodedString = originalDataURL.substring(
          originalDataURL.indexOf(',') + 1
        )
        if (receipt.isSmartReceipt as boolean) {
          const didCallOCRSmartReceiptApi = (
            isSuccess: boolean,
            totalPrice?: number
          ) => {
            setOCRIsSuccess(isSuccess)
            if (totalPrice != null) {
              setTotalPrice(`${totalPrice}`)
              setOCRTotalPrice(`${totalPrice}`)

              // OCRで金額を自動入力した場合は
              // OCRで読み取った金額を手入力で変更したかどうか のフラグをfalseにする
              setUserModifiedTotalPriceAfterOCR(false)
            } else {
              // OCRに失敗した場合は金額を空にする
              setTotalPrice('')
              setOCRTotalPrice('')
            }
          }

          // 6MBを超える場合、圧縮する
          if (base64EncodedString.length > 1024 * 1000 * 6) {
            dispatch(beginCommunication())
            const dataURL = await compressFile(originalDataURL)
            dispatch(endCommunication())
            if (dataURL != null) {
              base64EncodedString = dataURL.substring(dataURL.indexOf(',') + 1)
            }
          }

          dispatch(
            Usecases.Event.uploadImageForSmartReceiptOCR(
              {
                receipt: base64EncodedString,
              },
              didCallOCRSmartReceiptApi
            )
          )
        } else {
          const didCallOCRPaperReceiptApi = (
            isSuccess: boolean,
            totalPrice?: number
          ) => {
            setOCRIsSuccess(isSuccess)
            if (totalPrice != null) {
              setTotalPrice(`${totalPrice}`)
              setOCRTotalPrice(`${totalPrice}`)

              // OCRで金額を自動入力した場合は
              // OCRで読み取った金額を手入力で変更したかどうか のフラグをfalseにする
              setUserModifiedTotalPriceAfterOCR(false)
            } else {
              // OCRに失敗した場合は金額を空にする
              setTotalPrice('')
              setOCRTotalPrice('')
            }
          }

          // 10MBを超える場合、圧縮する
          if (base64EncodedString.length > 1024 * 1000 * 10) {
            dispatch(beginCommunication())
            const dataURL = await compressFile(originalDataURL)
            dispatch(endCommunication())
            if (dataURL != null) {
              base64EncodedString = dataURL.substring(dataURL.indexOf(',') + 1)
            }
          }

          dispatch(
            Usecases.Event.uploadImageForPaperReceiptOCR(
              {
                imageContent: base64EncodedString,
                withMeta: false,
                angle: false,
              },
              didCallOCRPaperReceiptApi
            )
          )
        }
      },
    }),
    [
      dispatch,
      receipt.event,
      receipt.isSmartReceipt,
      receipt.fileExtension,
      receipt.fileBlob,
      receipt.fileType,
      navigateToEventDetail,
    ]
  )

  if (receipt.fileDataURL == null) {
    // ブラウザリロード時LIFFアプリを閉じる
    liff.closeWindow()
    return <View />
  }
  return <ReceiptOCR receipt={receipt} actions={actions} />
}
