import * as React from 'react'
import { useParams, useLocation, Navigate } from 'react-router-dom'
import { Domain } from '../../../core'
const { liff } = window

type Props = {
  data: Domain.RichMenu.FetchedRichmenu | undefined
  userStatus: Domain.UserStatus.Entity | null
  actions: {
    fetchRichMenu: () => void
    fetchUserStatus: () => void
  }
}

export default function RedirectPage(props: Props) {
  const {
    data,
    userStatus,
    actions: { fetchRichMenu, fetchUserStatus },
  } = props
  const { type } = useParams()
  const { search } = useLocation()
  const [pathname, setPathname] = React.useState<string>('')
  const currentRichmenu = React.useRef<Domain.RichMenu.Entity | undefined>()

  React.useEffect(() => {
    fetchUserStatus()
  }, [fetchUserStatus])

  React.useEffect(() => {
    if (userStatus) {
      fetchRichMenu()
    }
  }, [fetchRichMenu, userStatus])

  const redirect = (url: string) => {
    if (url.substr(0, 1) === '/') {
      setPathname(url)
    } else {
      if (url === 'https://liff.line.me/1654106186-bm6VQVjd/web/guide') {
        liff.openWindow({ url })
      } else {
        window.location.replace(url)
      }
    }
  }

  React.useEffect(() => {
    currentRichmenu.current = userStatus?.cardRegist
      ? data?.regist.richmenuData
      : data?.default.richmenuData
    if (currentRichmenu.current) {
      // get area base on browser's domain path
      const temp = currentRichmenu.current.areas.find(
        (area: Domain.RichMenu.AreasEntity) => {
          return area.action.uri.includes(type as string)
        }
      )

      if (temp) {
        const redirectURLTemp = temp.action.redirectUri
        redirect(redirectURLTemp)
      } else {
        const query = new URLSearchParams(search)
        const isSubMenu = query.get('isSubMenu') || ''
        if (isSubMenu.toLowerCase() === 'true') {
          const redirectUri = query.get('redirectUri') || ''
          redirect(redirectUri)
        }
      }
    }
  }, [data, pathname, type, userStatus, search])

  if (!pathname.length) {
    return null
  } else {
    return <Navigate to={{ pathname }} replace />
  }
}
