import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modules, Usecases, Domain } from '../core'

import NearbyStoreGPSInput from '../components/screens/NearbyStoreGPSInput'

export default function NearbyStoreGPSListContainer() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const stores = useSelector((state: Modules.AppState) => state.nearbyStores)
  const location = useSelector((state: Modules.AppState) => state.location)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchNearbyStoresGPS(currentLocation: Domain.Location.Entity) {
        const reloader = () => {
          dispatch(
            Usecases.NearbyStores.fetchNearbyStoreGPS(currentLocation, reloader)
          )
        }

        dispatch(Modules.NearbyStores.clearNearbyStores())
        dispatch(
          Usecases.NearbyStores.fetchNearbyStoreGPS(currentLocation, reloader)
        )
      },
      registerStore(
        storeToRegister: Domain.StoreToRegister.Entity,
        navigate: () => void
      ) {
        dispatch(Usecases.Stores.registerStore(storeToRegister, navigate))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )
  return (
    location && (
      <NearbyStoreGPSInput
        tenant={tenant}
        stores={stores}
        location={location}
        actions={actions}
      />
    )
  )
}
