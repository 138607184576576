import * as Domain from '../domain'

type Stores = Array<Domain.Store.Entity>

export function createInitialState(): Stores {
  return []
}

export const SET_STORES = 'stores/set' as const
export const CLEAR_STORES = 'stores/clear' as const

export function setStores(stores: Stores) {
  return {
    type: SET_STORES,
    payload: {
      stores,
    },
  }
}

export function clearStores() {
  return {
    type: CLEAR_STORES,
  }
}

type SetStoresAction = ReturnType<typeof setStores>
type ClearStoresAction = ReturnType<typeof clearStores>
type Action = ClearStoresAction | SetStoresAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_STORES:
      return action.payload.stores
    case CLEAR_STORES:
      return []
    default:
      return state
  }
}
