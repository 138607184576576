import * as React from 'react'
import { StyleSheet, View, TextInput, Text, Image } from 'react-native'
import TextInputValidation from '../../share/TextInputValidation'
import RequiredLabel from '../../share/RequiredLabel'
import { COLORS, STYLES } from '../../../constants'

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  creditNumberGroup: {
    alignItems: 'center',
    marginBottom: 10,
  },
  inputSmall: {
    width: 100,
  },
  cardImage: {
    height: 45,
    width: 290,
    marginBottom: 15,
    resizeMode: 'contain',
  },
})

function isNumber(no: string): boolean {
  const pattern = /^\d*$/
  return no.match(pattern) !== null
}

function checkValid(creditNo6Digits: string, creditNo4Digits: string): boolean {
  return (
    (creditNo6Digits === '' && creditNo4Digits === '') ||
    (isNumber(creditNo6Digits) && isNumber(creditNo4Digits))
  )
}

interface Props {
  setCardNoisValid: (valid: boolean) => void
  creditNo6Digits: string
  setCreditCardNo6Digits: (cardNo: string) => void
  creditNo4Digits: string
  setCreditCardNo4Digits: (cardNo: string) => void
}

export default function CardNo(props: Props) {
  const {
    setCardNoisValid,
    creditNo6Digits,
    setCreditCardNo6Digits,
    creditNo4Digits,
    setCreditCardNo4Digits,
  } = props
  const [errors, setErrors] = React.useState<Array<string>>([])

  const textInputsValidation = React.useCallback(() => {
    const isValid = checkValid(creditNo6Digits, creditNo4Digits)
    setCardNoisValid(isValid)

    const errorMessages = []
    if (!isValid) {
      errorMessages.push('数値を入力してください')
    }

    setErrors(errorMessages)
  }, [creditNo6Digits, creditNo4Digits, setCardNoisValid, setErrors])

  React.useEffect(() => {
    textInputsValidation()
  }, [textInputsValidation])

  const onChangeCardNo6DigitsText = React.useCallback(
    (newValue: string) => {
      setCreditCardNo6Digits(newValue)
    },
    [setCreditCardNo6Digits]
  )

  const onChangeCardNo4DigitsText = React.useCallback(
    (newValue: string) => {
      setCreditCardNo4Digits(newValue)
    },
    [setCreditCardNo4Digits]
  )

  return (
    <View>
      <View style={STYLES.formGroupStyle.formSection}>
        <Text style={STYLES.formGroupStyle.formSectionTitle}>
          <RequiredLabel />
          カード番号を入力してください
        </Text>
        <Text style={STYLES.formGroupStyle.formSectionTitle}>
          お手持ちのカードに印字されたクレジットカード番号の上６桁、下４桁を入力してください
        </Text>
        <Image
          source={require('../../../assets/images/dummy_img.png')}
          style={styles.cardImage}
        />
        <TextInputValidation errors={errors} />
        <View style={[styles.flexRow, styles.creditNumberGroup]}>
          <Text style={STYLES.formGroupStyle.formLabel}>上6桁</Text>
          <TextInput
            placeholder="999999"
            placeholderTextColor={COLORS.GRAY_E}
            style={[STYLES.formGroupStyle.input, styles.inputSmall]}
            onChangeText={onChangeCardNo6DigitsText}
            maxLength={6}
            keyboardType={'phone-pad'}
          />
        </View>
        <View style={[styles.flexRow, styles.creditNumberGroup]}>
          <Text style={STYLES.formGroupStyle.formLabel}>下4桁</Text>
          <TextInput
            placeholder="9999"
            placeholderTextColor={COLORS.GRAY_E}
            style={[STYLES.formGroupStyle.input, styles.inputSmall]}
            onChangeText={onChangeCardNo4DigitsText}
            maxLength={4}
            keyboardType={'phone-pad'}
          />
        </View>
      </View>
    </View>
  )
}
