import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modules, Usecases } from '../core'

import RegisteredStoreList from '../components/screens/RegisteredStoreList'

export default function RegisteredStoreListContainer() {
  const stores = useSelector((state: Modules.AppState) => state.stores)
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchRegisteredStore() {
        const reloader = () => {
          dispatch(Usecases.Stores.fetchRegisteredStore(reloader))
        }
        dispatch(Modules.Stores.clearStores())
        dispatch(Usecases.Stores.fetchRegisteredStore(reloader))
      },
      deleteRegisteredStore(storeCd: string) {
        dispatch(Usecases.Stores.deleteRegisteredStoreAndReloadStores(storeCd))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )

  return (
    <RegisteredStoreList tenant={tenant} stores={stores} actions={actions} />
  )
}
