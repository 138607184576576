import { Dispatch } from 'redux'
import * as Domain from '../domain'
import { Api } from '../../lib'

export function fetchCampaignStatus(reloader: () => void, eventId: string | undefined, didCallFetchCheckCampaignStatus: (data: Domain.Campaign.CheckStatusCampaign) => void) {
  const options = {
    reload: reloader,
  }

  return async function(dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, `user-entry-tps/check-status-campaign`, `&eventId=${eventId}`, null, didCallFetchCheckCampaignStatus, dispatch, options)
  }
}
