import React from 'react'
import { useSelector } from 'react-redux'
import { Modules } from '../core'

import SelectCard from '../components/screens/SelectCard'

export default function SelectCardContainer() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  return <SelectCard tenant={tenant} />
}
