import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useNavigate, useParams } from 'react-router-dom'
import { Domain } from '../../../core'
import { COLORS } from '../../../constants'
import PageTitle from '../../share/PageTitle'
import ListStore from '../../share/ListStore'
import ButtonSubmit from '../../share/ButtonSubmit'
import selectBoxStyle from './SelectBox.module.css'
import stylesModule from '../NearbyStoreGPSInput/NearByStore.module.css'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const SUB_TITLE_1 = '登録する{value0}を選択してください'
const SUB_TITLE_2 = '{value0}をひとつ選択してください'
const SUBMIT_BTN_TITLE = '{value0}を登録する'

const { liff } = window

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.GRAY_A,
  },
  subTitle: {
    marginBottom: 5,
    fontSize: 16,
    lineHeight: 24,
  },
  listGrid: {
    paddingBottom: 110,
  },
})

interface Props {
  tenant: Domain.Tenant.Entity
  municipalities: Array<Domain.Municipality.Entity>
  stores: Array<Domain.Store.Entity>
  actions: {
    fetchStores: (prefectureCd: string | undefined) => void
    registerStore: (
      storeToRegister: Domain.StoreToRegister.Entity,
      navigate: () => void
    ) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

export default function CityInput(props: Props) {
  const {
    tenant,
    municipalities,
    stores,
    actions: { fetchStores, registerStore, clearScreenEmbeddedErrorAlert },
  } = props

  const navigate = useNavigate()
  const { prefectureCd } = useParams()
  const [storeCd, setStoreCd] = React.useState<string>('')
  const [filteredStores, setFilteredStores] =
    React.useState<Array<Domain.Store.Entity>>(stores)
  const prefectureName = `${sessionStorage.getItem('prefectureName')}すべて`
  const [focusStatus, setFocusStatus] = React.useState<boolean>(false)

  React.useEffect(() => fetchStores(prefectureCd), [fetchStores, prefectureCd])
  React.useEffect(() => setFilteredStores(stores), [stores])
  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  function handleFilter(arg: string) {
    setStoreCd('')
    if (arg) {
      setFilteredStores(
        stores.filter((val: Domain.Store.Entity) => {
          return val.municipalityCd === arg
        })
      )
    } else {
      setFilteredStores(stores)
    }
  }

  function overlayEvent(event: TouchEvent) {
    event.preventDefault()
  }

  React.useEffect(() => {
    const element = document.getElementById('overlay') as HTMLElement
    element.addEventListener('touchmove', (event) => overlayEvent(event))
    return () => {
      element.removeEventListener('touchmove', (event) => overlayEvent(event))
    }
  }, [])

  const navigateToStoreRegistrationCompleted = React.useCallback(
    () => navigate('/store-registration-completed'),
    [navigate]
  )
  const handleOnPressSubmit = React.useCallback(() => {
    const storeToRegister = { storeCd }
    registerStore(storeToRegister, navigateToStoreRegistrationCompleted)
  }, [registerStore, storeCd, navigateToStoreRegistrationCompleted])
  const buttonEnabled = React.useMemo(() => storeCd !== '', [storeCd])

  return (
    <View>
      {municipalities.length ? (
        <View style={styles.header}>
          <PageTitle title={tenant.nearbyStoresTitle} />
          <Text style={styles.subTitle}>
            {handleStoreGenreTitle(SUB_TITLE_1, tenant.category)}
          </Text>
          <Text style={styles.subTitle}>市区で絞り込むことが可能です</Text>
          <select
            className={selectBoxStyle.citySelect}
            name="city"
            onBlur={() => setFocusStatus(false)}
            onFocus={() => setFocusStatus(true)}
            onChange={(e) => handleFilter(e.target.value)}
          >
            <option value="">{prefectureName}</option>
            {municipalities.map((city: Domain.Municipality.Entity) => {
              return (
                <option value={city.municipalityCd} key={city.municipalityCd}>
                  {city.municipalityName}
                </option>
              )
            })}
          </select>
          <Text style={styles.subTitle}>五十音順で表示しています</Text>
        </View>
      ) : (
        <div className={stylesModule.header}>
          <PageTitle title={tenant.nearbyStoresTitle} />
          <Text style={styles.subTitle}>
            {handleStoreGenreTitle(SUB_TITLE_2, tenant.category)}
          </Text>
        </div>
      )}
      {/* Handle over only on ios Deviced */}

      <div
        id="overlay"
        className={`${selectBoxStyle.mainOverlay} ${
          focusStatus && liff.getOS() === 'ios'
            ? selectBoxStyle.overlayShow
            : ''
        }`}
        // Click call onBlur of select tag (solve for iphone 5 os 12.4.1)
        onClick={() => {
          ;(document.activeElement as HTMLElement).blur()
        }}
      />
      <ListStore
        stores={filteredStores}
        setStoreCd={setStoreCd}
        storeCd={storeCd}
        listGrid={municipalities.length ? styles.listGrid : null}
      />
      <div className={stylesModule.footer}>
        <ButtonSubmit
          onPress={handleOnPressSubmit}
          disabled={!buttonEnabled}
          text={handleStoreGenreTitle(SUBMIT_BTN_TITLE, tenant.category)}
        />
      </div>
    </View>
  )
}
