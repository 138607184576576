import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Usecases, Modules } from '../core'
import QuestionAndAnswer from '../components/screens/QuestionAndAnswer'
import { View } from 'react-native'

export default function QuestionAndAnswerContainer() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const faq = useSelector((state: Modules.AppState) => state.faq)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchFAQ() {
        const reloader = () => {
          dispatch(Usecases.FAQ.fetchFAQ(reloader, tenant.faqLink))
        }
        dispatch(Usecases.FAQ.fetchFAQ(reloader, tenant.faqLink))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),

    [dispatch, tenant]
  )
  if (tenant.faqLink) {
    return <QuestionAndAnswer tenant={tenant} faq={faq} actions={actions} />
  } else return <View />
}
