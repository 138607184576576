import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    backgroundColor: COLORS.PINK,
  },
  error: {
    fontSize: 14,
  },
})

interface Props {
  errors: Array<string>
}

export default function TextInputValidation(props: Props) {
  if (props.errors.length === 0) {
    return null
  }

  return (
    <View style={styles.container}>
      {props.errors.map((error, index) => (
        <Text style={styles.error} key={index}>
          {error}
        </Text>
      ))}
    </View>
  )
}
