import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { shallowEqual, useSelector } from 'react-redux'
import { COLORS } from '../../../../constants'
import { Modules } from '../../../../core'
import SharedLink from '../../../share/Link'

const { liff } = window

const styles = StyleSheet.create({
  outerContainer: {
    backgroundColor: COLORS.PINK_A,
  },
  innerContainer: {
    paddingStart: 16,
    paddingEnd: 16,
    marginTop: 24,
    backgroundColor: COLORS.WHITE,
  },
  linkView: {
    marginTop: 21,
    paddingBottom: 75,
  },
  linkText: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 16,
    letterSpacing: -0.26,
    color: COLORS.ORANGE,
    textAlign: 'left',
  },
})

export default function CampaignDetail() {
  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )

  return (
    <View style={styles.outerContainer}>
      <View style={styles.innerContainer}>
        <SharedLink
          title="キャンペーン詳細"
          propsStyle={styles.linkView}
          textStyle={styles.linkText}
          onPress={() => {
            const lpUrl = receipt.event?.lpUrl
            if (lpUrl != null && lpUrl.length > 0) {
              liff.openWindow({ url: lpUrl })
            }
          }}
        />
      </View>
    </View>
  )
}
