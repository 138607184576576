import * as Domain from '../domain'

type UserStatus = Domain.UserStatus.Entity | null


export function createInitialState(): UserStatus {
  return null
}

export const SET_USER_STATUS = 'user-status/set' as const
export const CLEAR_USER_STATUS = 'user-status/clear' as const

export function setUserStatus(userStatus: UserStatus) {
  return {
    type: SET_USER_STATUS,
    payload: {
      userStatus,
    },
  }
}

export function clearUserStatus() {
  return {
    type: CLEAR_USER_STATUS,
  }
}

type SetUserStatusAction = ReturnType<typeof setUserStatus>
type ClearUserStatusAction = ReturnType<typeof clearUserStatus>
type Action = SetUserStatusAction | ClearUserStatusAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_USER_STATUS:
      return action.payload.userStatus
    case CLEAR_USER_STATUS:
      return null
    default:
      return state
  }
}
