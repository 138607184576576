import React from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Usecases, Domain, Modules } from '../core'

import ServiceDetail from '../components/screens/ServiceDetail'

export default function ServiceDetailContainer() {
  const serviceInitialState = {
    bannerImage: '',
    title: '',
    lists: [],
  }
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const [serviceList, setServiceList] =
    React.useState<Domain.Service.ServiceEntity>(serviceInitialState)
  const dispatch = useDispatch<any>()

  const actions = React.useMemo(
    () => ({
      fetchServiceList() {
        const didCallFetchServicesListApi = (
          data: Domain.Service.ServiceEntity
        ) => {
          setServiceList(data)
        }
        const reloader = () => {
          dispatch(
            Usecases.Service.fetchService(
              reloader,
              didCallFetchServicesListApi,
              tenant.serviceLink
            )
          )
        }
        dispatch(
          Usecases.Service.fetchService(
            reloader,
            didCallFetchServicesListApi,
            tenant.serviceLink
          )
        )
      },
    }),
    [dispatch, tenant]
  )

  if (tenant.serviceLink) {
    return <ServiceDetail serviceList={serviceList} actions={actions} />
  } else return <View />
}
