import React from 'react'
import { View } from 'react-native'
import { shallowEqual, useSelector } from 'react-redux'
import EntryCompleted from '../components/screens/100thEntryCompleted'
import { Modules } from '../core'

const { liff } = window

export default function EntryCompletedContainer() {
  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )

  const close = React.useCallback(() => liff.closeWindow(), [])

  if (receipt.selectedPrize == null) {
    // ブラウザリロード時LIFFアプリを閉じる
    liff.closeWindow()
    return <View />
  }
  return <EntryCompleted close={close} />
}
