import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Domain, Modules, Usecases } from '../core'

import GreenCardInput from '../components/screens/GreenCardInput'

type PointCardToRegister = Domain.PointCardToRegister.Entity | null

export default function GreenCardInputContainer() {
  const userStatus = useSelector((state: Modules.AppState) => state.userStatus)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchUserStatus() {
        const reloder = () => {
          dispatch(Usecases.UserStatus.fetchUserStatus(reloder))
        }
        dispatch(Usecases.UserStatus.fetchUserStatus(reloder))
      },
      registerCard(card: PointCardToRegister, navigate: () => void) {
        dispatch(Usecases.Card.registerCard(card, navigate))
      },
      updateCard(card: PointCardToRegister, navigate: () => void) {
        dispatch(Usecases.Card.updateCard(card, navigate))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )

  return <GreenCardInput userStatus={userStatus} actions={actions} />
}
