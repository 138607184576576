import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Domain } from '../../../core'
import stylesModule from './styles.module.css'

const styles = StyleSheet.create({
  templateSection: {
    marginBottom: 24
  },
  gerneralPadding: {
    paddingHorizontal: 15
  },
})

interface Props {
  item: Domain.Service.ServiceTemplate
}

export default function Template(props: Props) {
  const { item } = props

  const ContentListRender = (contents: Array<Domain.Service.ServiceContentItem>) => {
    return contents.map((itemContent: Domain.Service.ServiceContentItem, index: number) => {
      return (
        <View key={`content-list-${index}`}>
          <div className={stylesModule.serviceTitle} dangerouslySetInnerHTML={ {__html: itemContent.title} } />
          <div className={stylesModule.serviceDescription} dangerouslySetInnerHTML={ {__html: itemContent.description} } />
        </View>
      )
    })
  }

  return (
    <View style={styles.templateSection} nativeID={item.id} >
      {!!item.image && (
        <View style={styles.gerneralPadding}><img alt="" src={item.image} className={stylesModule.serviceBanner} /></View>
      )}
      <View style={styles.gerneralPadding}>
        <div className={stylesModule.serviceNote} dangerouslySetInnerHTML={ {__html: item.note} } />
        {item.contents && item.contents.length > 0 && (
          ContentListRender(item.contents)
        )}
      </View>
    </View>
  );
}
