import * as React from 'react'
import { TouchableOpacity } from 'react-native'
import styles from './styles.module.css'
const { liff } = window

type Props = {
  imageSrc: string
  link: string
}

export default function Banner(props: Props) {
  const { imageSrc, link } = props

  const handleRedirect = React.useCallback(() => {
    liff.openWindow({url: link});
  }, [link])

  return (
    <TouchableOpacity onPress={handleRedirect}>
      <img className={styles.bannerImage} src={imageSrc} alt=""/>
    </TouchableOpacity>
  )
}
