import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
// import VConsole from 'vconsole';

// if (process.env.REACT_APP_VCONSOLE === 'true') {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const vcon = new VConsole();
// }

const container = document.getElementById('root')
if (container != null) {
  const root = createRoot(container)
  root.render(<App />)
}
