import * as React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  ImageSourcePropType,
  View,
} from 'react-native'
import { useNavigate } from 'react-router-dom'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 15,
    marginBottom: 15,
    minHeight: 80,
  },
  image: {
    width: '21%',
    maxWidth: 115,
    paddingBottom: '10%',
    resizeMode: 'contain',
  },
  titleWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '70%',
    marginLeft: 10,
  },
  title: {
    fontSize: 16,
  },
  arrow: {
    width: 13,
    resizeMode: 'contain',
  },
})

type Props = {
  title: string
  subTitle: string
  imageSrc: ImageSourcePropType
  link: string
  getOffset: () => number
}

export default function Card(props: Props) {
  const { title, subTitle, imageSrc, link, getOffset } = props

  const navigate = useNavigate()
  const n = React.useRef(0)
  const onPressIn = React.useCallback(() => {
    n.current = getOffset()
  }, [getOffset])

  const navigateToCardInput = React.useCallback(
    () => navigate(link),
    [navigate, link]
  )
  const onPressOut = React.useCallback(() => {
    const delta = Math.abs(getOffset() - n.current)

    if (delta > 0) {
      return
    }
    navigateToCardInput()
  }, [getOffset, navigateToCardInput])

  return (
    <TouchableOpacity
      style={styles.container}
      onPressOut={onPressOut}
      onPressIn={onPressIn}
      delayPressOut={100}
    >
      <Image source={imageSrc} style={styles.image} />
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{title}</Text>
        <Text>{subTitle}</Text>
      </View>
      <Image
        source={require('../../../assets/images/next_icon.png')}
        style={styles.arrow}
      />
    </TouchableOpacity>
  )
}
