import { Dispatch } from 'redux'
import { Domain, Functions } from '../core'
import { beginCommunication, endCommunication } from '../core/modules/network'
import { setError, clearError } from '../core/modules/error'
import { ErrorStatus } from '../constants/error-status'

const NETWORK_ERROR_MESSAGE =
  '通信ができません。通信設定及び、電波状況などをご確認ください。'
const SERVER_ERROR_MESSAGE = '通信エラーです。時間をおいて再度お試しください。'
export const SYSTEM_ERROR_MESSAGE =
  'システムエラーです。時間をおいて再度お試しください。'
const RELOAD_LABEL = '再読み込み'

export const tenantIs100t = () => process.env.REACT_APP_TENANT_ID === '100t'

export const errorObject: Domain.ApiErrorResponse.Entity = {
  errorCode: '',
  errorMessage: '',
  errorType: '',
}

export enum HttpMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export type DidCallApiCallback<T> = (
  data: T,
  dispatch: Dispatch,
  options?: Options
) => void
export interface Options {
  path?: string
  reload?: () => void
  navigate?: () => void
  showConfirmedModal?: () => void
}

function createError(apiErrorResponse: Domain.ApiErrorResponse.Entity) {
  if (apiErrorResponse.errorMessage) {
    return apiErrorResponse
  }
  return { ...errorObject, errorMessage: SERVER_ERROR_MESSAGE }
}

function isJsonResponse(response: Response): boolean {
  return (
    response.headers.get('Content-Type')?.includes('application/json') ?? false
  )
}

export function validateOnLine(dispatch: Dispatch) {
  if (Domain.App.isOnline()) {
    return true
  }
  dispatch(setError({ ...errorObject, errorMessage: NETWORK_ERROR_MESSAGE }))
  return false
}

// ----- call GET/POST/PUT/DELETE Api -----
export async function callApi<T>(
  httpMethod: HttpMethod,
  apiName: string,
  apiParams: string,
  body: string | null,
  didCallApiCallback: DidCallApiCallback<T>,
  dispatch: Dispatch,
  options?: Options,
  s3Link?: string,
  isShowReloadErrorScreen = false
) {
  const apiUrl = s3Link
    ? s3Link
    : `${process.env.REACT_APP_TOKYU_API_ENDPOINT}/${apiName}?tenantId=${process.env.REACT_APP_TENANT_ID}${apiParams}`
  try {
    dispatch(beginCommunication())
    dispatch(clearError())

    const needsToLineLogin = Functions.LineLogin.checkNeedToLineLogin()

    const headers = {
      Authorization: needsToLineLogin ? Domain.Auth.getAccessToken() : '',
      'Content-Type': 'application/json',
    }
    const init = body
      ? {
          method: httpMethod,
          headers,
          body: body,
        }
      : {
          method: httpMethod,
          headers,
        }

    const response = await fetch(apiUrl, s3Link ? { cache: 'no-cache' } : init)
    if (!response.ok) {
      if (response.status === ErrorStatus.MAINTENANCE_ERROR_STATUS) {
        dispatch(setError({ ...errorObject, errorCode: response.status }))
        return
      }

      if (isShowReloadErrorScreen) {
        throw response
      }
      if (!isJsonResponse(response)) {
        if (tenantIs100t()) {
          dispatch(
            setError({
              ...errorObject,
              errorMessage: SYSTEM_ERROR_MESSAGE,
            })
          )
        } else {
          dispatch(
            setError({ ...errorObject, errorMessage: SERVER_ERROR_MESSAGE })
          )
        }
        return
      }

      const apiErrorResponse: Domain.ApiErrorResponse.Entity =
        await response.json()
      if (
        apiErrorResponse.errorType === 'ApplicationError' ||
        apiErrorResponse.errorType === 'ResourceNotFoundError'
      ) {
        dispatch(setError(createError(apiErrorResponse)))
        return
      } else {
        if (tenantIs100t()) {
          dispatch(
            setError({
              ...errorObject,
              errorMessage: SYSTEM_ERROR_MESSAGE,
            })
          )
        } else {
          dispatch(
            setError({ ...errorObject, errorMessage: SERVER_ERROR_MESSAGE })
          )
        }
        return
      }
    }

    const data: T = await response.json()
    didCallApiCallback(data, dispatch, options)
  } catch (e) {
    if (options && options.reload) {
      dispatch(
        setError(
          new Domain.RecoverableError(
            NETWORK_ERROR_MESSAGE,
            RELOAD_LABEL,
            options.reload
          )
        )
      )
      return
    }

    if (!validateOnLine(dispatch)) {
      return
    }

    if (tenantIs100t()) {
      dispatch(setError({ ...errorObject, errorMessage: SYSTEM_ERROR_MESSAGE }))
    } else {
      dispatch(setError({ ...errorObject, errorMessage: SERVER_ERROR_MESSAGE }))
    }
  } finally {
    dispatch(endCommunication())
  }
}
