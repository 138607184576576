import * as React from 'react'
import { ViewStyle, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { COLORS } from '../../../constants'
const styles = StyleSheet.create({
  link: {
    textAlign: 'center',
    fontSize: 14,
    textDecorationLine: 'underline',
    color: COLORS.BLUE
  },
})

interface Props {
  title: string
  onPress: () => void,
  propsStyle?: ViewStyle,
  textStyle?: object
}

export default function Link(props: Props) {
  const { title, onPress, propsStyle, textStyle } = props
  return (
    <TouchableOpacity onPress={onPress} style={propsStyle}>
      <Text style={[styles.link, textStyle]}>{title}</Text>
    </TouchableOpacity>
  )
}
