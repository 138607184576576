import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  titleContainer: {
    borderTopWidth: 1,
    borderTopColor: COLORS.BLACK,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.BLACK,
    justifyContent: 'center',
    marginVertical: 20,
    paddingVertical: 15,
  },
  title: {
    fontSize: 16,
  },
})

interface Props {
  title: string
  id: string
}

export default function AnswerTitle(props: Props) {
  const { title, id } = props
  const childId = `child-${id}`

  return (
    <div id={childId}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
      </View>
    </div>
  )
}
