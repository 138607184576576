import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../../constants'
import { Domain } from '../../../../core'
import stylesModule from '../styles.module.css'

interface Props {
  prize: Domain.Event.PrizeEntity
}

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  step: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  prize: {
    padding: 16,
    marginTop: 24,
    borderRadius: 5,
    backgroundColor: '#FFEF89',
  },
  prizeCourseTitleLabel: {
    marginTop: 0,
  },
  titleLabel: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
    color: COLORS.ORANGE,
  },
  valueLabel: {
    marginTop: 8,
    fontSize: 22,
    fontWeight: '600',
    lineHeight: 24,
    color: COLORS.TOKYU_BLACK,
  },
  imageContainer: {
    marginTop: 8,
  },
})

export default function DesiredPrizeConfirmation(props: Props) {
  const { prize } = props

  return (
    <View style={styles.container}>
      <Text style={styles.step}>⑥応募賞品確認</Text>
      <View style={styles.prize}>
        <Text style={[styles.titleLabel, styles.prizeCourseTitleLabel]}>
          賞品コース
        </Text>
        <Text style={styles.valueLabel}>{prize.kind}</Text>
        <Text style={styles.titleLabel}>賞品カテゴリ</Text>
        <Text style={styles.valueLabel}>{prize.purchasedCategory}</Text>
        <Text style={styles.titleLabel}>賞品</Text>
        <Text style={styles.valueLabel}>{prize.title}</Text>
        <View style={styles.imageContainer}>
          <img alt="" src={prize.image} className={stylesModule.prizeImage} />
        </View>
      </View>
    </View>
  )
}
