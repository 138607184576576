import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../../constants'
import { Domain } from '../../../../core'
import { groupBy } from '../../../../core/functions/array'
import SharedLink from '../../../share/Link'
import Select from '../../../share/100th/Select'

const { liff } = window
const ALL = 'すべて'

interface Props {
  totalPrice: number
  prizes: Domain.Event.PrizeEntity[]
  setPrizesToSelect: React.Dispatch<
    React.SetStateAction<Domain.Event.PrizeEntity[]>
  >
  prizesUrl: string | undefined
}

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  step: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  prizeDetailLinkView: {
    marginTop: 16,
  },
  prizeDetailLinkText: {
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
    color: COLORS.ORANGE,
  },
  prizeCourseLabel: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  prizeCourseDescription: {
    marginTop: 11,
    fontSize: 18,
    fontWeight: '300',
    letterSpacing: -0.36,
    lineHeight: 22,
  },
  prizeCategoryLabel: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  select: {
    marginTop: 8,
  },
})

export default function SearchPrize(props: Props) {
  const { totalPrice, prizes, setPrizesToSelect, prizesUrl } = props

  // 賞品コース毎にグループ分け
  const prizesGroupedByKind = React.useMemo(
    () => groupBy(prizes, (p) => p.kind),
    [prizes]
  )

  // 賞品カテゴリー毎にグループ分け
  const prizesGroupedByCategory = React.useMemo(
    () => groupBy(prizes, (p) => p.purchasedCategory),
    [prizes]
  )

  // 賞品コース一覧を取得
  const prizeCourses = React.useMemo(() => {
    const options = prizesGroupedByKind.map((p) => {
      const course = p[0]
      return { id: course, name: course }
    })
    return [{ id: ALL, name: ALL }, ...options]
  }, [prizesGroupedByKind])

  // 選択中の賞品コース
  const [selectedPrizeCourse, setSelectedPrizeCourse] = React.useState<string>()

  // 指定した賞品コースに属するカテゴリー一覧を取得
  const prizeCategories = React.useCallback(
    (course: string) => {
      const all = { id: ALL, name: ALL }
      if (course === ALL) {
        const options = prizesGroupedByCategory.map((p) => {
          const category = p[0]
          return { id: category, name: category }
        })
        return [all, ...options]
      }

      const options = groupBy(
        prizesGroupedByKind.find((p) => p[0] === course)?.[1] ?? [],
        (p) => p.purchasedCategory
      ).map((p) => {
        const category = p[0]
        return { id: category, name: category }
      })
      return [all, ...options]
    },
    [prizesGroupedByKind, prizesGroupedByCategory]
  )

  // 選択中の賞品カテゴリー
  const [selectedPrizeCategory, setSelectedPrizeCategory] =
    React.useState<string>()

  const changeCourse = React.useCallback((course: string) => {
    setSelectedPrizeCourse(course)
    setSelectedPrizeCategory(ALL)
  }, [])

  // 賞品コース、カテゴリーで賞品を検索
  const filterPrizes = React.useCallback(() => {
    if (selectedPrizeCourse == null || selectedPrizeCategory == null) return

    let prizesToSelect: Domain.Event.PrizeEntity[]

    if (selectedPrizeCourse === ALL && selectedPrizeCategory === ALL) {
      // コース、カテゴリ共に「すべて」
      prizesToSelect = prizes
    } else if (selectedPrizeCourse === ALL && selectedPrizeCategory !== ALL) {
      // コースが「すべて」、カテゴリ指定あり
      prizesToSelect = prizes.filter(
        (p) => p.purchasedCategory === selectedPrizeCategory
      )
    } else if (selectedPrizeCourse !== ALL && selectedPrizeCategory === ALL) {
      // コース指定あり、カテゴリが「すべて」
      prizesToSelect = prizes.filter((p) => p.kind === selectedPrizeCourse)
    } else {
      // コース、カテゴリ共に指定あり
      prizesToSelect = prizes.filter(
        (p) =>
          p.kind === selectedPrizeCourse &&
          p.purchasedCategory === selectedPrizeCategory
      )
    }

    setPrizesToSelect(prizesToSelect)
  }, [setPrizesToSelect, prizes, selectedPrizeCourse, selectedPrizeCategory])

  React.useEffect(() => {
    // 賞品コースのデフォルト値を設定
    let defaultSelectedCourse: string | undefined
    if (totalPrice >= 5000) {
      // 「お買い上げ金額」5,000円以上：5,000円コース
      defaultSelectedCourse = prizes.find((p) => p.price >= 5000)?.kind
    } else {
      // 「お買い上げ金額」5,000円未満：2,000円コース
      defaultSelectedCourse = prizes.find(
        (p) => p.price >= 2000 && p.price < 5000
      )?.kind
    }
    setSelectedPrizeCourse(defaultSelectedCourse)
    setSelectedPrizeCategory(ALL)
  }, [prizes, totalPrice])

  React.useEffect(() => {
    filterPrizes()
  }, [filterPrizes])

  return (
    <View style={styles.container}>
      <Text style={styles.step}>③賞品を検索</Text>
      <SharedLink
        title="賞品の詳細はこちら"
        propsStyle={styles.prizeDetailLinkView}
        textStyle={styles.prizeDetailLinkText}
        onPress={() => {
          if (prizesUrl != null && prizesUrl.length > 0) {
            liff.openWindow({ url: prizesUrl })
          }
        }}
      />
      <Text style={styles.prizeCourseLabel}>賞品コース</Text>
      <View style={styles.select}>
        <Select
          handleChange={(e) => {
            changeCourse(e.target.value)
          }}
          listSelect={prizeCourses}
          selectedValue={selectedPrizeCourse}
        />
      </View>
      <Text style={styles.prizeCourseDescription}>
        ご利用額が5,000円以上の場合、A・Bコースどちらもご応募可能
      </Text>
      <Text style={styles.prizeCategoryLabel}>賞品カテゴリ</Text>
      <View style={styles.select}>
        <Select
          handleChange={(e) => {
            setSelectedPrizeCategory(e.target.value)
          }}
          listSelect={
            selectedPrizeCourse == null
              ? []
              : prizeCategories(selectedPrizeCourse)
          }
          selectedValue={selectedPrizeCategory}
        />
      </View>
    </View>
  )
}
