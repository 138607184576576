import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Store } from './core'
import LoadingPanel from './containers/LoadingPanel'
import ApiErrorAlert from './containers/ApiErrorAlert'
import InitialSetup from './InitialSetup'
import TagManager from './TagManager'

function App() {
  React.useEffect(() => {
    if (!process.env.REACT_APP_TOKYU_GTM_ID) return

    const GTM = process.env.REACT_APP_TOKYU_GTM_ID.split(',')
    for (let temp = 0; temp < GTM.length; temp++) {
      TagManager.initialize({ gtmId: GTM[temp] })
    }
  }, [])

  return (
    <Provider store={Store.store}>
      <Router>
        <LoadingPanel />
        <ApiErrorAlert />
        <InitialSetup />
      </Router>
    </Provider>
  )
}

export default App
