import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { STYLES } from '../../../constants'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import Card from './Card'
import { Domain } from '../../../core'
import { Tenant } from '../../../core/domain'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const PAGE_TITLE = 'お近くの{value0}を探す'

const styles = StyleSheet.create({
  subtitle: {
    marginBottom: 10,
    fontSize: 16,
  },
})

interface Props {
  tenant: Tenant.Entity
  prefectures: Array<Domain.Prefecture.Entity>
  actions: {
    fetchPrefectures: () => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

export default function PrefectureInput(props: Props) {
  const {
    tenant,
    prefectures,
    actions: { fetchPrefectures, clearScreenEmbeddedErrorAlert },
  } = props

  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  React.useEffect(() => fetchPrefectures(), [fetchPrefectures])

  return (
    <View style={STYLES.generalStyle.subContainer}>
      <View>
        <PageTitle title={handleStoreGenreTitle(PAGE_TITLE, tenant.category)} />
        <Text style={styles.subtitle}>都道府県を選択してください</Text>
        {prefectures.map((item, index) => {
          return <Card prefecture={item} key={index} />
        })}
      </View>
      <QnA />
    </View>
  )
}
