const SIZE = {
  XS: 12,
  S: 14,
  M: 16,
  L: 17,
  XL: 20,
  XXL: 24,
  XXXL: 36
}
export { SIZE }
