import * as React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useNavigate } from 'react-router-dom'
import Link from '../Link'

const styles = StyleSheet.create({
  footer: {
    marginTop: 60,
    marginBottom: 40,
    marginHorizontal: 'auto',
  },
})

interface Props {
  textFooterWrap?: object
  textStyle?: object
  title?: string
  isExternalLink?: boolean
  hash?: string
  handleClick?: Function
  customUrl?: string
  customStyle?: object
}

export default function QnA(props: Props) {
  const {
    textStyle,
    textFooterWrap,
    title,
    isExternalLink,
    hash,
    handleClick,
    customUrl,
    customStyle,
  } = props

  const navigate = useNavigate()
  const linkText = title || 'よくある質問はこちら'
  const link = customUrl
    ? customUrl
    : isExternalLink
    ? '/service-detail'
    : '/question-and-answer'
  const navigateToQuestionAndAnswer = React.useCallback(
    () => navigate({ pathname: link, hash }),
    [navigate, link, hash]
  )
  const onPress = React.useCallback(() => {
    navigateToQuestionAndAnswer()
    handleClick && handleClick()
  }, [navigateToQuestionAndAnswer, handleClick])

  return (
    <TouchableOpacity
      style={[customStyle ? customStyle : styles.footer, textFooterWrap]}
      onPress={onPress}
    >
      <Link title={linkText} onPress={onPress} textStyle={textStyle} />
    </TouchableOpacity>
  )
}
