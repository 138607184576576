import * as React from 'react'
import { StyleSheet, Text } from 'react-native'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  label: {
    padding: 3,
    backgroundColor: COLORS.RED,
    color: COLORS.WHITE,
    marginRight: 10,
    textDecorationLine: 'none'
  },
})

export default function RequiredLabel() {

  return (
    <Text style={styles.label}>必須</Text>
  )
}
