export function clearText (text: string) {
  return text.trim().replace(/\s\s+/g, ' ')
}

export function changeText(text: string, ...others: Array<string>) {
  let textTemp = text;
  others.forEach((result: string, index: number) => {
      textTemp = textTemp.replace(`{value${index}}`, result);
  });
  return textTemp;
}

export function handleStoreGenreTitle(text: string, category: string | undefined) {
  const store = {
    category: '1',
    title: '店舗',
  };
  const genre = {
    category: '2',
    title: 'ジャンル',
  };
  const cate = {
    category: '3',
    title: 'カテゴリ'
  };
  const usage = {
    category: '4',
    title: '利用用途'
  }

  if (category === genre.category) {
    return changeText(text, genre.title)
  }
  if (category === cate.category) {
    return changeText(text, cate.title)
  }
  if (category === store.category) {
    return changeText(text, store.title)
  }
  return changeText(text, usage.title)
}
