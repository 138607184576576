import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modules } from '../core'

import ApiErrorAlert from '../components/share/ApiErrorAlert'

export default function ApiErrorAlertContainer() {
  const error = useSelector((state: Modules.AppState) => state.error)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      clearError() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )

  return <ApiErrorAlert error={error} actions={actions} />
}
