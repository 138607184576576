import { Dispatch } from 'redux'
import { UserStatus } from '../domain'
import { Api } from '../../lib'
import { setUserStatus } from '../modules/userStatus'

type Data = UserStatus.Entity

function didCallFetchUserStatusApi(data: Data, dispatch: Dispatch) {
  dispatch(setUserStatus(data))
}

export function fetchUserStatus(reloader: () => void) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'userstatus', '', null, didCallFetchUserStatusApi, dispatch, options)
  }
}
