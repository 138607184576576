import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Usecases, Domain, Modules } from '../core'
import EventDetail from '../components/screens/EventDetail'
import { beginCommunication, endCommunication } from '../core/modules/network'
import { UploadStatusEnum } from '../constants/upload-image-status'
import EventDetail100th from '../components/screens/EventDetail/100th/EventDetail100th'
import { PrizeEntity } from '../core/domain/event'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ParamTypes = {
  eventId: string
}

export default function EventDetailContainer() {
  type EventDetailEntity = Domain.Event.DetailEntity
  type CheckEventEntiTy = Domain.Event.CheckEventEntiTy

  const { eventId } = useParams<ParamTypes>()
  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )

  const eventInitialState = {
    tenantId: '',
    eventId: '',
    eventImageDetail: '',
    headLineText: '',
    eventDescription: '',
    startDate: '',
    endDate: '',
    currentDate: '',
    imageText: '',
    isDisplayUploadImage: false,
    checkRegisterCard: false,
    isDisableDuration: false,
    isDisableIndex: false,
    commentInfos: [],
  }

  const checkEventInitialState = {
    eventRegist: false,
  }

  const [event, setEvent] = React.useState<EventDetailEntity>(eventInitialState)
  const [checkEvent, setCheckEvent] = React.useState<CheckEventEntiTy>(
    checkEventInitialState
  )

  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchEvent(_eventId: string) {
        const didCallFetchEventApi = (data: EventDetailEntity) => {
          setEvent(data)

          if (data.is100thCampaign != null ? data.is100thCampaign : false) {
            dispatch(Modules.Receipt.setEvent(data))
          }
        }
        const reloader = () => {
          dispatch(
            Usecases.Event.fetchEvent(reloader, _eventId, didCallFetchEventApi)
          )
        }
        dispatch(
          Usecases.Event.fetchEvent(reloader, _eventId, didCallFetchEventApi)
        )
      },

      fetchEventPrizes(_eventId: string) {
        const didCallFetchEventPrizesApi = (data: PrizeEntity[]) => {
          dispatch(Modules.Receipt.setPrizes(data))
        }
        const reloader = () => {
          dispatch(
            Usecases.Event.fetchEventPrizes(
              reloader,
              _eventId,
              didCallFetchEventPrizesApi
            )
          )
        }
        dispatch(
          Usecases.Event.fetchEventPrizes(
            reloader,
            _eventId,
            didCallFetchEventPrizesApi
          )
        )
      },

      fetchEventEntryStatus(_eventId: string) {
        const didCallFetchEventEntryStatus = (data: CheckEventEntiTy) => {
          setCheckEvent(data)
        }
        const reloader = () => {
          dispatch(
            Usecases.Event.fetchEventEntryStatus(
              reloader,
              _eventId,
              didCallFetchEventEntryStatus
            )
          )
        }
        dispatch(
          Usecases.Event.fetchEventEntryStatus(
            reloader,
            _eventId,
            didCallFetchEventEntryStatus
          )
        )
      },

      registerEvent(body: Domain.Event.PostEventEntity) {
        const didCallPostEventApi = () => {
          setCheckEvent({ eventRegist: true })
        }
        dispatch(Usecases.Event.registerEvent(body, didCallPostEventApi))
      },

      uploadImageAPI(
        blob: Blob,
        _eventId: string,
        setUploadStatus: (status: number) => void,
        scrollPosition: number
      ) {
        const headers = {
          Authorization: Domain.Auth.getAccessToken(),
          'Content-Type': 'image/jpeg',
        }
        return async (reduxDispatch: Dispatch) => {
          reduxDispatch(beginCommunication())
          try {
            const response = await fetch(
              `${process.env.REACT_APP_TOKYU_API_ENDPOINT}/user-entry/upload?eventId=${_eventId}&tenantId=${process.env.REACT_APP_TENANT_ID}`,
              {
                method: 'POST',
                headers,
                body: blob,
              }
            )

            const apiErrorResponse: Domain.ApiErrorResponse.Entity =
              await response.json()
            if (response.ok) {
              setUploadStatus(UploadStatusEnum.Done)
            } else {
              dispatch(Modules.ErrorModule.setError(apiErrorResponse))
            }
          } catch (e) {
            setUploadStatus(UploadStatusEnum.Failed)
          } finally {
            reduxDispatch(endCommunication())
            window.scrollTo(0, scrollPosition)
          }
        }
      },
    }),
    [dispatch]
  )

  const navigate = useNavigate()
  const navigateToReceiptOCR = useCallback(() => {
    navigate('/receipt-ocr')
  }, [navigate])
  const navigateTo100thEntryCompleted = useCallback(() => {
    navigate('/entry-completed')
  }, [navigate])
  const navigateToSmartReceipt = useCallback(() => {
    navigate('/smart-receipt')
  }, [navigate])
  const navigateToReceipt = useCallback(() => {
    navigate('/receipt')
  }, [navigate])

  const fileLoadingActions = React.useMemo(
    () => ({
      willLoad() {
        dispatch(beginCommunication())
      },
      didFinishLoading() {
        dispatch(endCommunication())
      },
      onSuccess(
        fileDataURL: string,
        fileBlob: Blob,
        fileType: string,
        fileExtension: string
      ) {
        dispatch(
          Modules.Receipt.setFile(
            fileDataURL,
            fileBlob,
            fileType,
            fileExtension
          )
        )
        dispatch(Modules.Receipt.setIsSmartReceipt(false))
        navigateToReceiptOCR()
      },
    }),
    [dispatch, navigateToReceiptOCR]
  )

  const otherActions = React.useMemo(
    () => ({
      entry(
        body: Domain.Event.UserEntryMultipleRequestEntity,
        selectedPrize: Domain.Event.PrizeEntity
      ) {
        const didCallUserEntryMultipleApi = () => {
          dispatch(Modules.Receipt.setSelectedPrize(selectedPrize))
          navigateTo100thEntryCompleted()
        }
        dispatch(Usecases.Event.entryEvent(body, didCallUserEntryMultipleApi))
      },
      onClickSmartReceiptButton() {
        navigateToSmartReceipt()
      },
      onClickReceiptLink() {
        navigateToReceipt()
      },
    }),
    [
      dispatch,
      navigateTo100thEntryCompleted,
      navigateToSmartReceipt,
      navigateToReceipt,
    ]
  )

  if (receipt.event != null && receipt.event.eventId === eventId) {
    // 100周年イベント詳細情報が取得済みの場合は100周年用のViewを表示
    return (
      <EventDetail100th
        fetchActions={actions}
        fileLoadingActions={fileLoadingActions}
        otherActions={otherActions}
      />
    )
  }

  dispatch(Modules.Receipt.clear())
  return <EventDetail event={event} checkEvent={checkEvent} actions={actions} />
}
