import * as React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image,
  Linking,
} from 'react-native'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../../constants'
import { Domain } from '../../../core'
import Link from '../../share/Link'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const ADD_STORE_TXT = '{value0}を登録できます'

function generateStyles(mainColor: string, colorButtonText: string) {
  const styles = StyleSheet.create({
    storeItem: {
      flexDirection: 'row',
      borderWidth: 1,
      marginTop: 15,
    },
    storeNumber: {
      textAlign: 'center',
      paddingVertical: 25,
      paddingHorizontal: 15,
      minWidth: 70,
      fontSize: 24,
      color: colorButtonText,
      backgroundColor: mainColor,
    },
    storeTitle: {
      display: 'flex',
      alignItems: 'center',
      padding: 15,
      fontWeight: 'bold',
      fontSize: 16,
    },
    promoteItem: {
      flexDirection: 'row',
      alignItems: 'center',
      borderWidth: 1,
      paddingHorizontal: 15,
      paddingVertical: 25,
      borderTopWidth: 0,
    },
    promoteTitle: {
      paddingLeft: 5,
      fontSize: 16,
    },
    storeTitleContainer: {
      width: '100%',
      backgroundColor: COLORS.GRAY_B,
    },
    addStoreTitleGroup: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    release: {
      marginLeft: 'auto',
      marginVertical: 5,
      marginRight: 5,
    },
    storeImage: {
      width: 42,
      height: 35,
      resizeMode: 'contain',
    },
    arrowImage: {
      width: 18,
      height: 18,
      position: 'absolute',
      right: 15,
      resizeMode: 'contain',
    },
    plusImage: {
      width: 18,
      height: 18,
      resizeMode: 'contain',
      marginRight: 5,
    },
  })
  return styles
}

interface Props {
  tenant: Domain.Tenant.Entity
  onDeletePress(param: string | undefined): void
  store: Domain.Store.Entity | null
  no: number
}

export default function Store(props: Props) {
  const { tenant, onDeletePress, store, no } = props

  const navigate = useNavigate()
  const styles = generateStyles(tenant.mainColor, tenant.colorButtonText)

  const navigateToAddStore = () => navigate('/add-store')
  const navigateToNearbyStore = () => navigate('/nearby-store-input')

  const handleOnPressAddStore = React.useCallback(() => {
    if (!tenant.isNearestStores && !tenant.isNearbyStores) {
      navigateToNearbyStore()
    } else {
      navigateToAddStore()
    }
  }, [navigateToAddStore, navigateToNearbyStore, tenant])

  const linkToStoreDetail = React.useCallback(() => {
    // @ts-ignore
    Linking.openURL(store?.storeUrl ? store.storeUrl : '', '_self')
  }, [store])

  function handleDeleteStore() {
    onDeletePress(store?.storeCd)
  }
  return (
    <View>
      {store ? (
        <View>
          <View style={styles.storeItem}>
            <Text style={styles.storeNumber}>{no}</Text>
            <Text style={[styles.storeTitle, styles.storeTitleContainer]}>
              {store.storeName}
            </Text>
          </View>
          <TouchableOpacity
            style={styles.promoteItem}
            onPress={linkToStoreDetail}
          >
            {tenant.flyerIcon && (
              <Image
                source={{ uri: tenant.flyerIcon }}
                style={styles.storeImage}
              />
            )}
            <Text style={styles.promoteTitle}>{tenant.flyerText}</Text>
            <Image
              source={require('../../../assets/images/next_icon.png')}
              style={styles.arrowImage}
            />
          </TouchableOpacity>
          <View style={styles.release}>
            <Link title="解除" onPress={handleDeleteStore} />
          </View>
        </View>
      ) : (
        <TouchableOpacity
          style={styles.storeItem}
          onPress={handleOnPressAddStore}
        >
          <Text style={styles.storeNumber}>{no}</Text>
          <Text style={styles.storeTitle}>
            <View style={styles.addStoreTitleGroup}>
              <Image
                source={require('../../../assets/images/plus_icon.png')}
                style={styles.plusImage}
              />
              <Text>
                {handleStoreGenreTitle(ADD_STORE_TXT, tenant.category)}
              </Text>
            </View>
          </Text>
        </TouchableOpacity>
      )}
    </View>
  )
}
