import React from 'react'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { Link } from 'react-router-dom'

const styles = StyleSheet.create({
  label: {
    fontSize: 36,
    fontWeight: 'bold',
  },
  links: {
    margin: 16,
  },
  item: {
    marginVertical: 16,
  },
  text: {
    fontSize: 16,
  },
})

interface Screen {
  name: string
  path: string
}

const LINKS: Array<Screen> = [
  { name: 'カード選択画面 (Card select screen)', path: 'select-card' },
  { name: '店舗追加画面 (Store add screen)', path: 'add-store' },
  {
    name: '登録店舗一覧画面 (Store list screen)',
    path: 'registered-store-list',
  },
  {
    name: '登録カード情報&解除画面 (Regist card info & cancel screen)',
    path: 'registered-card',
  },
  { name: 'Event List', path: 'events' },
  { name: 'Q&A画面 (Q&A screen)', path: 'question-and-answer' },
  {
    name: 'プライバシーポリシー画面 (privacy policy screen)',
    path: 'privacy-policy',
  },
  {
    name: '100周年キャンペーン',
    path: 'events/2022101901',
  },
  {
    name: 'ToLキャンペーン',
    path: 'events/99991',
  },
]

export default function Debug() {
  return (
    <>
      <Text style={styles.label}>Debug Panel</Text>
      <FlatList
        data={LINKS}
        renderItem={({ item }) => (
          <View style={styles.item}>
            <Link to={`/${item.path}`}>
              <Text style={styles.text}>{item.name}</Text>
            </Link>
          </View>
        )}
        keyExtractor={(item) => item.path}
        style={styles.links}
      />
    </>
  )
}
