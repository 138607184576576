import * as React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { COLORS } from '../../../../constants'
import ErrorView from '../../../share/100th/ErrorView'
import Select from '../../../share/100th/Select'

interface Props {
  zipCode: string
  setZipCode: React.Dispatch<React.SetStateAction<string>>
  zipCodeIsValid: (value: string) => boolean
  setFacility: React.Dispatch<React.SetStateAction<string>>
}

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  step: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  description: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  zipCodeLabelContainer: {
    marginTop: 11,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  titleLabel: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  requiredLabel: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 16,
    letterSpacing: -0.26,
    color: COLORS.ORANGE,
  },
  zipCodeInput: {
    marginTop: 8,
    height: 48,
    borderWidth: 1,
    borderColor: COLORS.GRAY_E,
    borderRadius: 4,
    paddingTop: 16,
    paddingBottom: 17,
    paddingStart: 8,
    fontSize: 15,
    fontWeight: '300',
    letterSpacing: -0.24,
    lineHeight: 15,
    color: COLORS.GRAY_H,
  },
  facilityLabelContainer: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  select: {
    marginTop: 9,
  },
})

export default function Questionnaire(props: Props) {
  const { zipCode, setZipCode, zipCodeIsValid, setFacility } = props

  const facilities: { id: string; name: string }[] = React.useMemo(
    () =>
      [
        '---',
        '百貨店、スーパー、ショッピングセンター',
        '売店、コンビニ',
        '映画館',
        'ホテル',
        'ゴルフ場',
        'スキー場、リゾート施設',
        'フィットネスクラブ',
        'ネットショッピング',
        'その他',
      ].map((facilityName) => ({
        id: facilityName,
        name: facilityName,
      })),
    []
  )

  // 郵便番号入力エラーを表示するかどうか
  const [showZipCodeError, setShowZipCodeError] = React.useState<boolean>(false)

  return (
    <View style={styles.container}>
      <Text style={styles.step}>⑤アンケート</Text>
      <Text style={styles.description}>以下の情報をご入力ください</Text>
      <View style={styles.zipCodeLabelContainer}>
        <Text style={styles.titleLabel}>お住いの郵便番号</Text>
        <Text style={styles.requiredLabel}>※必須</Text>
      </View>
      {/* 郵便番号入力エラー表示 */}
      {showZipCodeError && <ErrorView error="ZipCode" />}
      <TextInput
        style={styles.zipCodeInput}
        onChangeText={(newText) => {
          if (zipCodeIsValid(newText)) {
            // 郵便番号入力エラー非表示は即時反映
            setShowZipCodeError(false)
          }
          setZipCode(newText)
        }}
        onBlur={() => {
          // 郵便番号入力エラー表示はフォーカスが外れた時
          setShowZipCodeError(!zipCodeIsValid(zipCode))
        }}
        value={zipCode}
        placeholder="0000000"
        placeholderTextColor={COLORS.GRAY_E}
        maxLength={7}
        keyboardType="phone-pad" // number-pad だとmaxLengthが効かないためphone-padを使用
      />
      <View style={styles.facilityLabelContainer}>
        <Text style={styles.titleLabel}>今回ご利用いただいた施設</Text>
        <Text style={styles.requiredLabel}>※必須</Text>
      </View>
      <View style={styles.select}>
        <Select
          handleChange={(e) => {
            const { selectedIndex } = e.target
            const { text } = e.target.options[selectedIndex]
            setFacility(text)
          }}
          listSelect={facilities}
        />
      </View>
    </View>
  )
}
