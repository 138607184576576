import React from 'react'
import { StyleSheet, View } from 'react-native'
import Modal from '../Modal'

const styles = StyleSheet.create({
  modalAction: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

type Props = {
  message: string
  redirectUrl: () => void
}
type WrappedPropsType = {
  redirectUrl: () => void
}

const withModalRedirect = (Wrapped: React.ComponentType<WrappedPropsType>) => {
  return function (props: Props) {
    const { message, redirectUrl } = props
    return (
      <Modal needsToShow={true} text={message}>
        <View style={styles.modalAction}>
          <Wrapped redirectUrl={redirectUrl} />
        </View>
      </Modal>
    )
  }
}

export default withModalRedirect
