import * as React from 'react'
import { StyleSheet, View, TextInput, Text } from 'react-native'
import TextInputValidation from '../../share/TextInputValidation'
import { COLORS, STYLES } from '../../../constants'
import RequiredLabel from '../../share/RequiredLabel'

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  cardNumberGroup: {
    maxWidth: 480,
    alignItems: 'center',
  },
  cardNumberControlRight: {
    marginRight: 15,
  },
  cardNumberControl: {
    width: '30%',
  },
})

function checkBothNosEmpty(middlePartNo: string, lastPartNo: string) {
  return middlePartNo.length === 0 && lastPartNo.length === 0
}

function isNo(no: string): boolean {
  const pattern = /^\d*$/
  return no.match(pattern) !== null
}

function checkBothNosAreValid(
  middlePartNo: string,
  lastPartNo: string
): boolean {
  const bothNosAreEmpty = checkBothNosEmpty(middlePartNo, lastPartNo)
  return bothNosAreEmpty || (isNo(middlePartNo) && isNo(lastPartNo))
}

interface Props {
  setPointNosAreValid: (valid: boolean) => void
  middlePartPointNo: string
  setMiddlePartPointNo: (cardNo: string) => void
  lastPartPointNo: string
  setLastPartPointNo: (cardNo: string) => void
}

export default function MiddleAndLastPointNo(props: Props) {
  const {
    setPointNosAreValid,
    middlePartPointNo,
    setMiddlePartPointNo,
    lastPartPointNo,
    setLastPartPointNo,
  } = props

  const [errors, setErrors] = React.useState<Array<string>>([])

  const textInputsValidation = React.useCallback(() => {
    const bothNosAreValid = checkBothNosAreValid(
      middlePartPointNo,
      lastPartPointNo
    )
    setPointNosAreValid(bothNosAreValid)

    const errorMessages = []
    if (!bothNosAreValid) {
      errorMessages.push('数値を入力してください')
    }

    setErrors(errorMessages)
  }, [middlePartPointNo, lastPartPointNo, setPointNosAreValid, setErrors])

  React.useEffect(() => {
    textInputsValidation()
  }, [textInputsValidation])

  const onChangeMiddlePartText = React.useCallback(
    (newValue: string) => {
      setMiddlePartPointNo(newValue)
    },
    [setMiddlePartPointNo]
  )

  const onChangeLastPartText = React.useCallback(
    (newValue: string) => {
      setLastPartPointNo(newValue)
    },
    [setLastPartPointNo]
  )

  return (
    <View>
      <View style={STYLES.formGroupStyle.formSection}>
        <Text style={STYLES.formGroupStyle.formSectionTitle}>
          <RequiredLabel />
          カード番号を入力してください
        </Text>
        <TextInputValidation errors={errors} />
        <View style={[styles.flexRow, styles.cardNumberGroup]}>
          <Text style={STYLES.formGroupStyle.formLabel}>6502 3000</Text>
          <TextInput
            placeholder="9999"
            placeholderTextColor={COLORS.GRAY_E}
            style={[
              STYLES.formGroupStyle.input,
              styles.cardNumberControl,
              styles.cardNumberControlRight,
            ]}
            maxLength={4}
            onChangeText={onChangeMiddlePartText}
            keyboardType={'phone-pad'}
          />
          <TextInput
            placeholder="9999"
            placeholderTextColor={COLORS.GRAY_E}
            style={[STYLES.formGroupStyle.input, styles.cardNumberControl]}
            maxLength={4}
            onChangeText={onChangeLastPartText}
            keyboardType={'phone-pad'}
          />
        </View>
      </View>
    </View>
  )
}
