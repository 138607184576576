import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Usecases, Domain, Modules } from '../core'

import AddStore from '../components/screens/AddStore'

export default function AddStoreContainer() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      registerLocation(location: Domain.Location.Entity) {
        dispatch(Usecases.Location.didCallSetLocation(location))
      },
    }),
    [dispatch]
  )
  return <AddStore tenant={tenant} actions={actions} />
}
