import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useParams } from 'react-router-dom'
import PageTitle from '../../share/PageTitle'
import { STYLES } from '../../../constants'
import AnswerTitle from './AnswerTitle'
import StyleModule from './Style.module.css'
import { Domain } from '../../../core'

const styles = StyleSheet.create({
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
  },
  category: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  wrapper: {
    marginVertical: 20,
  },
  questionsGroup: {
    marginVertical: 10,
  },
  answerContent: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
    width: '100%',
    textAlign: 'left',
  },
})
interface Props {
  tenant: Domain.Tenant.Entity
  faq: Array<Domain.FAQ.Entity>
  actions: {
    fetchFAQ: () => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

export default function QuestionAndAnswer(props: Props) {
  const {
    tenant,
    faq,
    actions: { fetchFAQ, clearScreenEmbeddedErrorAlert },
  } = props
  const { answerId } = useParams()
  const onTopText = 'ページ上部へ戻る'

  React.useEffect(() => {
    if (tenant.faqLink) {
      fetchFAQ()
    }
  }, [fetchFAQ, tenant.faqLink])

  React.useEffect(
    () => clearScreenEmbeddedErrorAlert(),
    [clearScreenEmbeddedErrorAlert]
  )

  async function handleMove(id: string) {
    const element = (await document.getElementById(id)) as HTMLElement
    if (element) {
      // Answer title element
      const titleElement = document.getElementById(`child-${id}`) as HTMLElement
      const top =
        element.getBoundingClientRect().top +
        window.pageYOffset -
        window.outerHeight / 2 +
        titleElement.clientHeight
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }

  React.useEffect(() => {
    if (faq) {
      if (answerId) {
        handleMove(answerId)
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [answerId, faq])

  React.useEffect(
    function () {
      if (faq) {
        document.querySelectorAll('span.js-hash').forEach((element) => {
          element.addEventListener('click', function () {
            window.history.replaceState(
              {},
              '',
              `#${element.getAttribute('data-href')}`
            )
            window.dispatchEvent(new Event('replacestate'))
          })
        })
      }
    },
    [faq]
  )

  React.useEffect(function () {
    window.addEventListener('replacestate', function () {
      handleMove(`${window.location.hash.substr(1)}`)
    })
  }, [])

  function onTop() {
    window.history.replaceState(null, '', ' ')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <View style={STYLES.generalStyle.container}>
      <PageTitle title="よくある質問" />
      <Text style={styles.subtitle}>
        {tenant.tenantName}LINE公式アカウントについて
      </Text>

      <View style={styles.wrapper}>
        {faq.map((category: Domain.FAQ.Entity, i: number) => {
          return (
            <View key={i}>
              <Text style={styles.category}>{category.name}</Text>
              <View style={styles.questionsGroup}>
                {category.contents.map(
                  (
                    content: { question: string; answer: string },
                    j: number
                  ) => {
                    const id = `${i + 1}${j + 1}`
                    return (
                      <span
                        data-href={id}
                        className={`${StyleModule.question} js-hash`}
                        key={id}
                      >
                        {content.question}
                      </span>
                    )
                  }
                )}
              </View>
            </View>
          )
        })}
        {/* ANSWERS AREA */}
        {faq.map((category: Domain.FAQ.Entity, i: number) => {
          return (
            <View key={i}>
              {category.contents.map(
                (content: { question: string; answer: string }, j: number) => {
                  const id = `${i + 1}${j + 1}`
                  return (
                    <View key={id} nativeID={id}>
                      <AnswerTitle title={content.question} id={id} />
                      <Text style={styles.answerContent}>
                        <div
                          dangerouslySetInnerHTML={{ __html: content.answer }}
                        />
                      </Text>
                      <div className={StyleModule.onTopBtn}>
                        <span onClick={onTop}>{onTopText}</span>
                      </div>
                    </View>
                  )
                }
              )}
            </View>
          )
        })}
      </View>
    </View>
  )
}
