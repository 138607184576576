import { Dispatch } from 'redux'
import * as Domain from '../domain'
import { Api } from '../../lib'

interface DataProduct {
  products: Array<Domain.Product.ListEntity>,
}

export function fetchProductsList(reloader: () => void, didCallFetchProductListApi: (data: DataProduct) => void, link?: string) {
  const options = { 
    reload: reloader,
  }
  return async function(dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, '', '', null, didCallFetchProductListApi, dispatch, options, link)
  }
}
