import React from 'react'
import { useLocation } from 'react-router-dom'
import NeedsSignIn from './components/NeedsSignIn'
import { Domain, Functions } from './core'
import { View } from 'react-native'
import Routes from './Routes'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { Api } from './lib'
import { setTenant, clearTenant } from './core/modules/tenant'
import { isToLCampaignLIFF } from './core/functions/tol'

const needsToLineLogin = Functions.LineLogin.checkNeedToLineLogin()

export default function InitialSetup() {
  const [signInFinished, setSignInFinished] = React.useState(false)
  const [signInFailed, setSignInFailed] = React.useState(false)
  function changeFavicon(link: string) {
    const element = window.document.getElementById('favicon') as HTMLLinkElement
    element.href = link
  }

  const dispatch = useDispatch<any>()
  const { search } = useLocation()

  const fetchTenantDetail = React.useCallback(() => {
    function didFetchTenantDetail(
      data: Domain.Tenant.Entity,
      reduxDispatch: Dispatch
    ) {
      changeFavicon(data.favicon)

      if (isToLCampaignLIFF()) {
        document.title = process.env.REACT_APP_TOL_CAMPAIGN_HEADER_TITLE ?? ''
      } else {
        document.title = data.tenantName
      }

      reduxDispatch(clearTenant())
      reduxDispatch(setTenant(data))
    }
    const reloader = () => {
      dispatch(fetchTenantDetail())
    }
    return function (reduxDispatch: Dispatch) {
      Api.callApi(
        Api.HttpMethod.get,
        `tenants/${process.env.REACT_APP_TENANT_ID}`,
        '',
        null,
        didFetchTenantDetail,
        reduxDispatch,
        {
          reload: reloader,
        },
        '',
        true
      )
    }
  }, [dispatch])

  React.useEffect(() => {
    setTimeout(() => {
      const searchParams = new URLSearchParams(search)

      // テナント詳細取得APIがbackクエリパラメーターの付与によって複数回実行されないようにする
      const isBack = (searchParams.get('back') ?? '').toLowerCase() === 'true'
      if (isBack) {
        return
      }

      const isToLCampaign =
        (searchParams.get('tol-campaign') ?? '').toLowerCase() === 'true'
      Domain.Auth.init(isToLCampaign).then((value: boolean) => {
        dispatch(fetchTenantDetail())
        if (value && needsToLineLogin) {
          const isRedirectAfterLogin =
            window.location.pathname === encodeURI('/Menu/券売機チャージ_D') ||
            window.location.pathname === encodeURI('/Menu/券売機チャージ_R')
          Domain.Auth.signIn({
            redirectUri: isRedirectAfterLogin
              ? window.location.href
              : undefined,
          })
            .then(() => {
              setSignInFailed(false)
            })
            .catch(() => {
              setSignInFailed(true)
            })
            .finally(() => {
              setSignInFinished(true)
            })
        }
      })
    }, 500)
  }, [dispatch, fetchTenantDetail, search])

  if (needsToLineLogin && !signInFinished) {
    return <View />
  } else if (needsToLineLogin && signInFailed) {
    return <NeedsSignIn />
  }
  return (
    <>
      <Routes />
    </>
  )
}
