import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { COLORS } from '../../../constants'
import modalContainerStyle from './Modal.module.css'

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    maxWidth: 400,
    borderRadius: 5,
    backgroundColor: COLORS.WHITE,
    padding: 45
  },
  modalLabels: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  modalText: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 40,
  },
})

interface Props {
  needsToShow: boolean
  title?: string
  text?: string
  children: React.ReactNode
}

export default function Modal(props: Props) {
  const { needsToShow, children, title, text } = props

  if (!needsToShow) {
    return null
  }

  return (
    <div className={modalContainerStyle.modalContainer}>
      <View style={styles.modal}>
        <View style={styles.modalLabels}>
          <Text style={styles.modalTitle}>{title}</Text>
          <Text style={styles.modalText}>{text}</Text>
        </View>
        {children}
      </View>
    </div>
  )
}
