import * as React from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { useNavigate } from 'react-router-dom'
import ListStore from '../../share/ListStore'
import { Domain } from '../../../core'
import PageTitle from '../../share/PageTitle'
import ButtonSubmit from '../../share/ButtonSubmit'
import stylesModule from './NearByStore.module.css'
import { Tenant } from '../../../core/domain'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const PAGE_TITLE = 'お近くの{value0}を探す'
const PAGE_SUB_TITLE = '{value0}をひとつ選択してください'
const SUBMIT_BTN_TITLE = '{value0}を登録する'

const styles = StyleSheet.create({
  container: {
    maxHeight: '100vh',
    height: '100%',
  },
  subTitle: {
    marginBottom: 5,
    fontSize: 16,
    lineHeight: 24,
  },
  listStore: {},
})

interface Props {
  tenant: Tenant.Entity
  stores: Array<Domain.Store.Entity>
  location: Domain.Location.Entity
  actions: {
    fetchNearbyStoresGPS: (location: Domain.Location.Entity) => void
    registerStore: (
      storeToRegister: Domain.StoreToRegister.Entity,
      navigate: () => void
    ) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

export default function NearbyStoreInput(props: Props) {
  const {
    tenant,
    stores,
    location,
    actions: {
      fetchNearbyStoresGPS,
      registerStore,
      clearScreenEmbeddedErrorAlert,
    },
  } = props

  const navigate = useNavigate()

  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  React.useEffect(
    () => fetchNearbyStoresGPS(location),
    [fetchNearbyStoresGPS, location]
  )

  const navigateToStoreRegistrationCompleted = React.useCallback(
    () => navigate('/store-registration-completed'),
    []
  )
  const [storeCd, setStoreCd] = React.useState<string>('')
  const handleOnPressSubmit = React.useCallback(() => {
    const storeToRegister = { storeCd }
    registerStore(storeToRegister, navigateToStoreRegistrationCompleted)
  }, [registerStore, storeCd, navigateToStoreRegistrationCompleted])
  const buttonEnabled = React.useMemo(() => storeCd !== '', [storeCd])

  const headerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    styles.listStore = { paddingTop: headerRef.current?.offsetHeight as number }
  }, [])
  return (
    <View style={styles.container}>
      <div ref={headerRef} className={stylesModule.header}>
        <PageTitle title={handleStoreGenreTitle(PAGE_TITLE, tenant.category)} />
        <Text style={styles.subTitle}>
          {handleStoreGenreTitle(PAGE_SUB_TITLE, tenant.category)}
        </Text>
      </div>
      <div className={stylesModule.content}>
        <ListStore
          stores={stores}
          setStoreCd={setStoreCd}
          storeCd={storeCd}
          listGrid={styles.listStore}
        />
      </div>
      <div className={stylesModule.footer}>
        <ButtonSubmit
          onPress={handleOnPressSubmit}
          disabled={!buttonEnabled}
          text={handleStoreGenreTitle(SUBMIT_BTN_TITLE, tenant.category)}
        />
      </div>
    </View>
  )
}
