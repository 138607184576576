import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonSubmit from '../../share/ButtonSubmit'

export default function Button() {
  const navigate = useNavigate()
  const navigateToSelectCard = React.useCallback(
    () => navigate('/select-card'),
    [navigate]
  )
  const handleOnPress = React.useCallback(() => {
    navigateToSelectCard()
  }, [navigateToSelectCard])

  return (
    <ButtonSubmit
      onPress={handleOnPress}
      disabled={false}
      text="カードを変更する"
    />
  )
}
