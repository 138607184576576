/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import QnA from '../../share/QnA'
import { COLORS } from '../../../constants'
import Link from '../../share/Link'
import Modal from '../../share/Modal'
import { Domain, Modules } from '../../../core'
import { CardType } from '../../../constants/card-types'

const { liff } = window

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 15,
  },
  title: {
    marginTop: 50,
    marginBottom: 30,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subTitle: {
    marginTop: 30,
    fontSize: 18,
  },
  content: {
    marginTop: 20,
    fontSize: 18,
  },
  itemTitle: {
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginTop: 10,
    fontSize: 13,
  },
  frequentLink: {
    marginTop: 30,
    marginBottom: 30,
  },
  itemContent: {
    fontSize: 18,
    marginTop: 5,
    marginBottom: 10,
  },
  marginTop30: {
    marginTop: 30,
  },
  closeLiffBtn: {
    marginTop: 50,
  },
  closeLiffText: {
    textAlign: 'center',
    color: COLORS.BLUE,
    textDecorationLine: 'underline',
  },
  cancel: {
    marginTop: 30,
    marginHorizontal: 'auto',
  },
  modalButtons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButton: {
    margin: 'auto',
  },
  modalButtonLabel: {
    fontSize: 17,
    fontWeight: 'bold',
    margin: 'auto',
  },
  link: {
    color: COLORS.BLUE,
    textDecorationLine: 'underline',
  },
})

interface Props {
  card: Domain.Card.Entity | null
  actions: {
    fetchRegisteredCard: () => void
    deleteRegisteredCard: (showConfirmedModal: () => void) => void
  }
}

const isDevelopment = process.env.NODE_ENV === 'development'

const getCardName = (cardCd?: string): string => {
  switch (cardCd) {
    case CardType.tokyuCard:
      return 'TOKYU CARD'
    case CardType.tokyuPointCardRed:
      return 'TOKYU POINT CARD（赤）'
    case CardType.tokyuPointCardGreen:
      return 'TOP&現金専用カード（緑）'
    default:
      return ''
  }
}

const getCardTitle = (cardCd?: string): string => {
  switch (cardCd) {
    case CardType.tokyuCard:
      return 'クレジットカード会員'
    case CardType.tokyuPointCardRed:
      return 'TOKYU POINT CARD（赤）'
    case CardType.tokyuPointCardGreen:
      return 'TOP&現金専用カード（緑）'
    default:
      return ''
  }
}

export default function CardRegistered(props: Props) {
  const {
    card,
    actions: { fetchRegisteredCard, deleteRegisteredCard },
  } = props

  const tenant = useSelector((state: Modules.AppState) => state.tenant)

  const onPressBackToLineApp = React.useCallback(() => {
    if (!process.env.REACT_APP_TOGO_LIFF) return null
    window.location.href = process.env.REACT_APP_TOGO_LIFF
    return null
  }, [])

  // Confirmation Modal
  const [needsToShowConfirmationModal, setNeedsToShowConfirmationModal] =
    React.useState(false)
  const [needsToShowConfirmedModal, setNeedsToShowConfirmedModal] =
    React.useState(false)

  const onPressDelete = React.useCallback(
    () => setNeedsToShowConfirmationModal(true),
    []
  )

  const onPressConfirmationModalOK = React.useCallback(() => {
    setNeedsToShowConfirmationModal(false)
    deleteRegisteredCard(() => setNeedsToShowConfirmedModal(true))
  }, [deleteRegisteredCard])

  const onPressConfirmationModalCancel = React.useCallback(
    () => setNeedsToShowConfirmationModal(false),
    []
  )

  const onPressConfirmedModallOK = React.useCallback(() => {
    if (isDevelopment) {
      setNeedsToShowConfirmedModal(false)
    } else {
      liff.closeWindow()
    }
  }, [])

  // TODO: connect API after API is available
  React.useEffect(() => fetchRegisteredCard(), [fetchRegisteredCard])

  const cardName = getCardName(card?.cardCd)
  const cardTitle = getCardTitle(card?.cardCd)

  return (
    <View style={styles.wrapper}>
      <Modal
        needsToShow={needsToShowConfirmationModal}
        title="登録中のカードを解除します"
        text={tenant.confirmDeleteCardText}
      >
        <View style={styles.modalButtons}>
          <TouchableOpacity onPress={onPressConfirmationModalCancel}>
            <Text style={styles.modalButtonLabel}>キャンセル</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onPressConfirmationModalOK}>
            <Text style={styles.modalButtonLabel}>解除する</Text>
          </TouchableOpacity>
        </View>
      </Modal>

      <Modal
        needsToShow={needsToShowConfirmedModal}
        text="カードが解除されました"
      >
        <TouchableOpacity
          onPress={onPressConfirmedModallOK}
          style={styles.modalButton}
        >
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>

      {card?.cardCd ? (
        <View>
          <Text style={styles.title}>ご登録中のカード</Text>

          <View style={styles.content}>
            <Text style={styles.itemTitle}>券種</Text>
            <Text style={styles.itemContent}>{cardName}</Text>
            <Text style={styles.itemTitle}>{cardTitle}</Text>
            <Text style={styles.itemContent}>{card.pointNo}</Text>
          </View>

          <View style={styles.marginTop30}>
            <Text style={styles.itemContent}>
              お客様はTOKYU POINT CARD on LINEのご利用を開始されていません。
            </Text>
            <Text style={styles.itemContent}>
              ポイントバーコードのご利用、および他のカードをご登録される方は、TOKYU
              POINT CARD on LINEをご利用ください。
            </Text>
          </View>
        </View>
      ) : (
        <View>
          <Text style={styles.title}>ご登録中のカード</Text>
          <Text style={styles.subTitle}>カードが未登録です。</Text>
          <Text style={styles.subTitle}>
            TOKYU POINT CARD on LINEをご利用ください。
          </Text>
        </View>
      )}

      <TouchableOpacity
        onPress={onPressBackToLineApp}
        style={styles.closeLiffBtn}
      >
        <Text style={styles.closeLiffText}>
          ＜ TOKYU POINT CARD on LINEに戻る
        </Text>
      </TouchableOpacity>

      {card?.cardCd && (
        <Link
          propsStyle={styles.cancel}
          title="解除する ＞"
          onPress={onPressDelete}
        />
      )}

      <QnA customStyle={styles.frequentLink} />
    </View>
  )
}
