import React from 'react'
import Slider from 'react-slick'
import './styles.css'

interface Props {
  settings?: {},
  children: React.ReactNode
}

export default function SliderContainer(props: Props) {
  const { children, settings } = props

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}

SliderContainer.defaultProps = {
  settings: {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true
  }
}
