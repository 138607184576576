import { Dispatch } from 'redux'
import { Api } from '../../lib'
import * as Domain from '../domain'
import { setNearbyStores } from '../modules/nearbyStores'
interface Data {
  storeList: Array<Domain.Store.Entity>
}

// ----- GET /near by stores API -----
export function fetchNearbyStore (
  reloader: () => void,
  prefectureCd: string | undefined,
  didCallFetchNearbyStoresApi: (data: Data, dispatch: Dispatch) => void,
) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, prefectureCd ? 'nearbystores' : 'allstores', prefectureCd ? `&prefectureCd=${prefectureCd}` : '', null, didCallFetchNearbyStoresApi, dispatch, options)
  }
}
// ----- GET /near by stores GPS API -----

function didCallFetchNearbyStoresGPSApi(data: Data, dispatch: Dispatch, ) {
  dispatch(setNearbyStores(data.storeList))
}

export function fetchNearbyStoreGPS (location: Domain.Location.Entity, reloader: () => void) {
  const options = { reload: reloader }
  const apiParams = `&longitude=${location.longitude}&latitude=${location.latitude}`
  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'neareststores', apiParams, null, didCallFetchNearbyStoresGPSApi, dispatch, options)
  }
}
