import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { COLORS } from '../../../constants'
import stylesModule from './styles.module.css'

const styles = StyleSheet.create({
  generalButton: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: COLORS.ORANGE
  },
  anchorItem: {
    display: 'flex',
    justifyContent: 'center',
    paddingVertical: 10,
    marginBottom: 8
  },
  primaryColor: {
    color: COLORS.ORANGE
  }
})

interface Props {
  text: string | undefined,
  arrowIcon: string,
  scrollTarget: string,
  onPress: (scrollTarget: string) => void
}

export default function Button(props: Props) {
  const { text, onPress, scrollTarget, arrowIcon } = props

  return (
    <TouchableOpacity onPress={()=>onPress(scrollTarget)}>
      <Text style={[styles.anchorItem, styles.generalButton, styles.primaryColor]}>
        {text}
        <img src={arrowIcon} className={stylesModule.arrowIcon} alt="" />
      </Text>
    </TouchableOpacity>
  )
}
