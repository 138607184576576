import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../constants'
import { FileError, loadFile } from '../../../core/functions/file'
import CampaignDetail from '../../share/100th/CampaignDetail'
import FileButton from '../EventDetail/100th/FileButton'
import stylesModule from './styles.module.css'
import ErrorView from '../../share/100th/ErrorView'
import ButtonSubmit from '../../share/ButtonSubmit'

const { liff } = window

interface Props {
  actions: {
    willLoadFile: () => void
    didFinishFileLoading: () => void
    fileLoadingOnSuccess: (
      fileDataURL: string,
      fileBlob: Blob,
      fileType: string,
      fileExtension: string
    ) => void
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    paddingHorizontal: 16,
    paddingBottom: 24,
  },
  topMessage: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  fileButton: {
    marginTop: 16,
  },
  stepTitleLabel: {
    marginTop: 32,
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.ORANGE,
    textAlign: 'center',
  },
  firstStep: {
    marginTop: 9,
  },
  otherStep: {
    marginTop: 24,
  },
  step: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  step1Container: {
    marginTop: 16,
    paddingTop: 13,
    paddingBottom: 16,
    paddingHorizontal: 16,
    backgroundColor: COLORS.PINK_A,
  },
  step1ImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  step1ImageLabel: {
    width: '64%',
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  step2Container: {
    marginTop: 16,
    backgroundColor: COLORS.PINK_A,
    padding: 16,
  },
  step2ImageContainer: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  step2ImageTitleLabel: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  step2ImageBodyLabel: {
    width: '58%',
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  twoColumnsImageContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumnContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.PINK_A,
    padding: 16,
  },
  rightColumnContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.PINK_A,
    marginStart: 17,
    padding: 16,
  },
  imageInColumnTitleLabel: {
    marginBottom: 16,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
    textAlign: 'center',
  },
  noteContainer: {
    marginTop: 24,
  },
  noteTitleLabel: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  noteBodyLabel: {
    marginTop: 11,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  smartReceiptButton: {
    marginTop: 8,
    borderRadius: 24,
    backgroundColor: COLORS.WHITE,
    borderWidth: 1,
    borderColor: COLORS.ORANGE,
    height: 63,
  },
  smartReceiptButtonText: {
    color: COLORS.ORANGE,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
    textAlign: 'center',
  },
})

const icon = require('../../../assets/resources/100th/smartreceipt_icon.png')
const ss01 = require('../../../assets/resources/100th/smartreceipt_ss01.png')
const ss02 = require('../../../assets/resources/100th/smartreceipt_ss02.png')
const ss03 = require('../../../assets/resources/100th/smartreceipt_ss03.png')
const ss04 = require('../../../assets/resources/100th/smartreceipt_ss04.png')

export default function SmartReceipt(props: Props) {
  const {
    actions: { willLoadFile, didFinishFileLoading, fileLoadingOnSuccess },
  } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // ファイルエラー
  const [fileError, setFileError] = React.useState<FileError>()

  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.topMessage}>
          スマートレシートのスクリーンショット画像を登録してください
        </Text>
        {/* ファイルエラー表示 */}
        {fileError !== undefined && <ErrorView error={fileError} />}
        <FileButton
          text="スマートレシートを登録"
          handleChooseFile={(e) => {
            loadFile(
              e,
              willLoadFile,
              didFinishFileLoading,
              (
                fileDataURL: string,
                fileBlob: Blob,
                fileType: string,
                fileExtension: string
              ) => {
                setFileError(undefined)
                fileLoadingOnSuccess(
                  fileDataURL,
                  fileBlob,
                  fileType,
                  fileExtension
                )
              },
              (error: FileError) => {
                setFileError(error)
              }
            )
          }}
          buttonStyle={styles.fileButton}
        />
        <Text style={styles.stepTitleLabel}>
          スマートレシートの{'\n'}登録手順
        </Text>
        <Text style={[styles.step, styles.firstStep]}>
          ①スマートレシートアプリを起動
        </Text>
        <View style={styles.step1Container}>
          <View style={styles.step1ImageContainer}>
            <Text style={styles.step1ImageLabel}>
              スマートレシートアプリを起動し、応募に利用するレシートを表示してください。
            </Text>
            <img src={icon} alt="" className={stylesModule.icon} />
          </View>
          <ButtonSubmit
            disabled={false}
            onPress={() => {
              liff.openWindow({
                url: 'https://f6pf.adj.st/home?adj_t=n0my0qg',
                external: true,
              })
            }}
            text={`スマートレシートアプリを${'\n'}起動`}
            buttonStyle={styles.smartReceiptButton}
            textStyle={styles.smartReceiptButtonText}
          />
        </View>
        <Text style={[styles.step, styles.otherStep]}>
          ②スクリーンショットの撮影
        </Text>
        <View style={styles.step2Container}>
          <Text style={styles.step2ImageTitleLabel}>撮影方法</Text>
          <View style={styles.step2ImageContainer}>
            <Text style={styles.step2ImageBodyLabel}>
              スマートレシートのスマートフォンアプリから、応募に利用するレシートの詳細画面をスクリーンショット機能で撮影してください。
              {'\n'}
              なお、スクリーンショットの撮影方法は機種によって違いますので撮影方法をご確認ください。
            </Text>
            <img src={ss01} alt="" className={stylesModule.ss01} />
          </View>
        </View>
        <View style={styles.step2Container}>
          <Text style={styles.step2ImageTitleLabel}>
            必要事項が見えるように
          </Text>
          <View style={styles.step2ImageContainer}>
            <Text style={styles.step2ImageBodyLabel}>
              レシートの下記内容がわかるように撮影してください。{'\n\n'}
              ①購入店舗名・合計金額{'\n'}②レシート内の店舗名{'\n'}
              ③購入日時・レジ番号
            </Text>
            <img src={ss02} alt="" className={stylesModule.ss02} />
          </View>
        </View>
        <View style={styles.twoColumnsImageContainer}>
          <View style={styles.leftColumnContainer}>
            <Text style={styles.imageInColumnTitleLabel}>画像を加工しない</Text>
            <img src={ss03} alt="" className={stylesModule.imageInColumn} />
          </View>
          <View style={styles.rightColumnContainer}>
            <Text style={styles.imageInColumnTitleLabel}>
              レシート一覧は無効
            </Text>
            <img src={ss04} alt="" className={stylesModule.imageInColumn} />
          </View>
        </View>
        <View style={styles.noteContainer}>
          <Text style={styles.noteTitleLabel}>その他の注意事項</Text>
          <Text style={styles.noteBodyLabel}>
            ●スマートレシートのアップロードは1枚のみです。1枚の写真に必要項目がわかるように撮影してください。
            {'\n'}
            ●必要事項が判別できない場合は、応募が無効となります。予めご了承ください。
          </Text>
        </View>
        <Text style={[styles.step, styles.otherStep]}>③この画面に戻る</Text>
        <Text style={[styles.step, styles.otherStep]}>
          ④スマートレシートを登録する
        </Text>
        <Text style={styles.noteBodyLabel}>
          ※撮影したスクリーンショットは、多くの場合、iPhoneは「フォトライブラリ」内、Androidは「ファイル」＞「フォト」＞「Screenshots」内に保存されています
        </Text>
        {/* ファイルエラー表示 */}
        {fileError !== undefined && <ErrorView error={fileError} />}
        <FileButton
          text="スマートレシートを登録"
          handleChooseFile={(e) => {
            loadFile(
              e,
              willLoadFile,
              didFinishFileLoading,
              (
                fileDataURL: string,
                fileBlob: Blob,
                fileType: string,
                fileExtension: string
              ) => {
                setFileError(undefined)
                fileLoadingOnSuccess(
                  fileDataURL,
                  fileBlob,
                  fileType,
                  fileExtension
                )
              },
              (error: FileError) => {
                setFileError(error)
              }
            )
          }}
          buttonStyle={styles.fileButton}
        />
      </View>
      <CampaignDetail />
    </View>
  )
}
