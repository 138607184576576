import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modules, Usecases, Domain } from '../core'

import CityInput from '../components/screens/CityInput'

export default function CityInputContainer() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const municipalities = useSelector(
    (state: Modules.AppState) => state.municipalities
  )
  const stores = useSelector((state: Modules.AppState) => state.stores)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchStores(prefectureCd: string | undefined) {
        const reloader = () => {
          dispatch(Usecases.Municipalities.fetchStores(reloader, prefectureCd))
        }
        dispatch(Modules.Municipalities.clearMunicipalities())
        dispatch(Modules.Stores.clearStores())
        dispatch(Usecases.Municipalities.fetchStores(reloader, prefectureCd))
      },
      registerStore(
        storeToRegister: Domain.StoreToRegister.Entity,
        navigate: () => void
      ) {
        dispatch(Usecases.Stores.registerStore(storeToRegister, navigate))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )

  return (
    <CityInput
      tenant={tenant}
      municipalities={municipalities}
      stores={stores}
      actions={actions}
    />
  )
}
