import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Domain } from '../../../core'
import ActiveEventList from './ActiveEventList'
import ProductlistWrapper from './ProductListWrapper'
import FooterCampaign from './FooterCampaign'
import CampaignServiceList from './CampaignServiceList'
import stylesModule from './styles.module.css'
import marutokuPlusFooterBanner from '../../../assets/images/marutoku_plus_footer_banner.png'

interface Props {
  activeEventList: Array<Domain.Event.ListEntity>
  listProductCampaign: Array<Domain.Product.ListEntity>
  currentDate: string
  actions: {
    fetchActiveEventsList: () => void
    fetchCampaignStatus: () => void
    fetchCampaignProductsList: () => void
  }
  statusCampaign: boolean
}

export default function TopCampaign(props: Props) {
  const {
    activeEventList,
    listProductCampaign,
    currentDate,
    actions: { fetchActiveEventsList, fetchCampaignStatus, fetchCampaignProductsList },
    statusCampaign,
  } = props

  const [statusLoadMore, setStatusLoadMore] = React.useState(true)

  const getStatusLoadMore = (status: boolean) => {
    setStatusLoadMore(status)
  }

  const goToTPSLiff = () => {
    if (!process.env.REACT_APP_TPS_LIFF) return
    window.location.href = process.env.REACT_APP_TPS_LIFF
  }

  async function scrollToElement(id: string) {
    const element = (await document.getElementById(id)) as HTMLElement
    if (element) {
      const top = element.getBoundingClientRect().top
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }

  React.useEffect(() => {
    fetchActiveEventsList()
  }, [fetchActiveEventsList])

  React.useEffect(() => {
    fetchCampaignStatus()
  }, [fetchCampaignStatus])

  React.useEffect(() => {
    fetchCampaignProductsList()
  }, [fetchCampaignProductsList])

  React.useEffect(() => {
    const hash = window.location.hash.substr(1)
    if (!hash) return
    const timer = setTimeout(() => scrollToElement(hash), 1500)
    return () => {
      clearTimeout(timer)
    }
  }, [activeEventList])

  return (
    <View>
      <CampaignServiceList />
      <ActiveEventList currentDate={currentDate} activeEventList={activeEventList} />
      <ProductlistWrapper listProductCampaign={listProductCampaign} getStatusLoadMore={getStatusLoadMore} />
      <FooterCampaign statusLoadMore={statusLoadMore} />
      <div className={stylesModule.footerBanner}>
        {!statusCampaign && (
          <TouchableOpacity onPress={goToTPSLiff}>
            <img className={stylesModule.thumbnail} src={marutokuPlusFooterBanner} alt="まる得プラス" />
          </TouchableOpacity>
        )}
      </div>
      <div className={stylesModule.footerBannerSafeAreaBox}></div>
    </View>
  )
}
