import React from 'react'
import { useDispatch } from 'react-redux'
import { Usecases, Domain } from '../core'

import EventsList from '../components/screens/EventsList'

export default function EventsListContainer() {
  interface Data {
    eventsList: Array<Domain.Event.ListEntity>
    currentDate: string
  }

  const [eventList, setEventList] = React.useState<
    Array<Domain.Event.ListEntity>
  >([])
  const [currentDate, setCurrentDate] = React.useState<string>('')

  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchEventsList() {
        const didCallFetchEventsListApi = (data: Data) => {
          setEventList(data.eventsList)
          setCurrentDate(data.currentDate)
        }
        const reloader = () => {
          dispatch(
            Usecases.Event.fetchEventsList(reloader, didCallFetchEventsListApi)
          )
        }
        dispatch(
          Usecases.Event.fetchEventsList(reloader, didCallFetchEventsListApi)
        )
      },
    }),
    [dispatch]
  )

  return (
    <EventsList
      currentDate={currentDate}
      eventList={eventList}
      actions={actions}
    />
  )
}
