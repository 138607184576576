const GRAY = '#d8d8d8'
const GRAY_A = '#B5B5B5'
const GRAY_B = '#F5F5F5'
const GRAY_C = '#6B6B6B'
const GRAY_D = '#D4D4D4'
const GRAY_E = '#DDDDDD'
const GRAY_F = '#666666'
const GRAY_H = '#333333'
const YELLOW = '#FFB601'
const WHITE = '#FFFFFF'
const BLACK = '#000000'
const PINK = '#F9E9ED'
const PINK_A = '#FAF4ED'
const ORANGE = '#D95E2E'
const BLUE = '#0E73DC'
const RED = '#E40708'
const RED_A = '#D32D26'
const SUCCESS = '#008800'
const TRANSPARENT = 'rgba(0, 0, 0, 0.5)'
const TOKYU_BLACK = '#282828'
const ALERT_RED = '#E58481'
const ERROR = '#F9E8ED'

export {
  GRAY,
  GRAY_A,
  GRAY_B,
  GRAY_C,
  GRAY_D,
  GRAY_E,
  GRAY_F,
  GRAY_H,
  YELLOW,
  WHITE,
  BLACK,
  PINK,
  PINK_A,
  ORANGE,
  BLUE,
  RED,
  RED_A,
  SUCCESS,
  TRANSPARENT,
  TOKYU_BLACK,
  ALERT_RED,
  ERROR,
}
