import { Dispatch } from 'redux'
import * as Domain from '../domain'
import { setCard } from '../modules/card'
import { Api } from '../../lib'

// ----- GET /card API -----
type Data = Domain.Card.Entity | null

function didCallFetchRegisteredCardApi(data: Data, dispatch: Dispatch) {
  dispatch(setCard(data))
}

export function fetchRegisteredCard (reloader: () => void) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'card', '', null, didCallFetchRegisteredCardApi, dispatch, options)
  }
}

// ----- POST /card API -----
type CardToRegister = Domain.CreditCardToRegister.Entity | Domain.PointCardToRegister.Entity | null

function didCallRegisterCardApi(data: {}, dispatch: Dispatch, options?: Api.Options) {
  if (options && options.navigate) {
    options.navigate()
  }
}

export function registerCard (card: CardToRegister, navigate: () => void) {
  const options = {
    navigate,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.post, 'card', '', JSON.stringify(card), didCallRegisterCardApi, dispatch, options)
  }
}

// ----- PUT /card API -----
function didCallUpdateCardApi(data: {}, dispatch: Dispatch, options?: Api.Options) {
  if (options && options.navigate) {
    options.navigate()
  }
}

export function updateCard (card: CardToRegister, navigate: () => void) {
  // FIXME: it doesn't work!!
  const options = {
    navigate,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.put, 'card', '', JSON.stringify(card), didCallUpdateCardApi, dispatch, options)
  }
}

// ----- DELETE /card -----
function didCallDeleteRegistredCard(data: {},dispatch: Dispatch, options?: Api.Options) {
  if (options && options.showConfirmedModal) {
    options.showConfirmedModal()
  }
}

export function deleteRegisteredCard (showConfirmedModal: () => void) {
  const options = {
    showConfirmedModal,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.delete, 'card', '', null, didCallDeleteRegistredCard, dispatch, options)
  }
}
