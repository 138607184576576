import React from 'react'
import { Domain } from '../../../core'
import stylesModule from './style.module.css'

interface Props {
  listSelect: Array<Domain.Product.ListSelect>
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  selectedValue?: string | string[] | number
}
function SelectComponent(props: Props) {
  const { listSelect, handleChange, selectedValue } = props

  return (
    <select
      value={selectedValue}
      onChange={(e) => handleChange(e)}
      className={stylesModule.select}
    >
      {listSelect.length > 0 &&
        listSelect.map((item: Domain.Product.ListSelect, index: number) => (
          <option value={item.id} key={index}>
            {item.name}
          </option>
        ))}
    </select>
  )
}

export default SelectComponent
