import * as Domain from '../domain'

type Municipalities = Array<Domain.Municipality.Entity>

export function createInitialState(): Municipalities {
  return []
}

export const SET_MUNICIPALITIES = 'municipalities/set' as const
export const CLEAR_MUNICIPALITIES = 'municipalities/clear' as const

export function setMunicipalities(municipalities: Municipalities) {
  return {
    type: SET_MUNICIPALITIES,
    payload: {
      municipalities,
    },
  }
}

export function clearMunicipalities() {
  return {
    type: CLEAR_MUNICIPALITIES,
  }
}

type SetMunicipalitiesAction = ReturnType<typeof setMunicipalities>
type ClearMunicipalitiesAction = ReturnType<typeof clearMunicipalities>
type Action = ClearMunicipalitiesAction | SetMunicipalitiesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_MUNICIPALITIES:
      return action.payload.municipalities
    case CLEAR_MUNICIPALITIES:
      return []
    default:
      return state
  }
}
