import React from 'react'
import { useDispatch } from 'react-redux'
import { Usecases, Domain } from '../core'

import TopCampaign from '../components/screens/TopCampaign'

export default function TopCampaignContainer() {
  interface Data {
    eventsList: Array<Domain.Event.ListEntity>
    currentDate: string
  }
  interface DataProduct {
    products: Array<Domain.Product.ListEntity>
  }

  const [activeEventList, setActiveEventList] = React.useState<
    Array<Domain.Event.ListEntity>
  >([])
  const [listProducts, setListProducts] = React.useState<
    Array<Domain.Product.ListEntity>
  >([])
  const [currentDate, setCurrentDate] = React.useState<string>('')
  const [statusCampaign, setStatusCampaign] = React.useState<boolean>(false)

  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchActiveEventsList() {
        const didCallFetchEventsListApi = (data: Data) => {
          setActiveEventList(data.eventsList)
          setCurrentDate(data.currentDate)
        }
        const reloader = () => {
          dispatch(
            Usecases.Event.fetchEventsList(
              reloader,
              didCallFetchEventsListApi,
              '&type=active'
            )
          )
        }
        dispatch(
          Usecases.Event.fetchEventsList(
            reloader,
            didCallFetchEventsListApi,
            '&type=active'
          )
        )
      },
      fetchCampaignStatus() {
        const didCallFetchStatusCampaign = (
          data: Domain.Campaign.CheckStatusCampaign
        ) => {
          setStatusCampaign(data.statusCampaignTps)
        }
        const reloader = () => {
          dispatch(
            Usecases.Campaign.fetchCampaignStatus(
              reloader,
              '',
              didCallFetchStatusCampaign
            )
          )
        }
        dispatch(
          Usecases.Campaign.fetchCampaignStatus(
            reloader,
            '',
            didCallFetchStatusCampaign
          )
        )
      },
      fetchCampaignProductsList() {
        const didCallFetchProductsListApi = (data: DataProduct) => {
          setListProducts(data.products)
        }
        const productLink = `${process.env.REACT_APP_CAMPAIGN_BUCKET}/master/products/data/shouhon.json`
        const reloader = () => {
          dispatch(
            Usecases.Product.fetchProductsList(
              reloader,
              didCallFetchProductsListApi,
              productLink
            )
          )
        }
        dispatch(
          Usecases.Product.fetchProductsList(
            reloader,
            didCallFetchProductsListApi,
            productLink
          )
        )
      },
    }),
    [dispatch]
  )

  return (
    <TopCampaign
      currentDate={currentDate}
      activeEventList={activeEventList}
      listProductCampaign={listProducts}
      actions={actions}
      statusCampaign={statusCampaign}
    />
  )
}
