import { StyleSheet } from 'react-native'
import { COLORS } from '.'

const generalStyle = StyleSheet.create({
  container: {
    minHeight: '100vh',
    paddingHorizontal: 15,
  },
  subContainer: {
    paddingHorizontal: 15,
  }
})

const formGroupStyle = StyleSheet.create({
  formSection: {
    marginTop: 20,
  },
  formSectionTitle: {
    marginBottom: 15,
    fontSize: 16,
    lineHeight: 24,
  },
  formLabel: {
    marginRight: 10,
    fontSize: 16,
  },
  input: {
    padding: 13,
    borderWidth: 1,
    fontSize: 16,
  },
})

const buttonSubmit = StyleSheet.create({
  submitBtn: {
    flexDirection: 'row',
    backgroundColor: COLORS.RED,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    marginTop: 20,
    position: 'relative',
  },
  submitBtnTitle: {
    color: COLORS.WHITE,
    fontSize: 20,
    letterSpacing: 0.5,
    lineHeight: 28,
  },
  arrow: {
    width: '4%',
    resizeMode: 'contain',
    paddingBottom: 20,
    position: 'absolute',
    right: 15,
  },
})

export { generalStyle, formGroupStyle, buttonSubmit }
