import * as Domain from '../domain'

type Location = Domain.Location.Entity

export function createInitialState(): Location {
  return {}
}

export const SET_LOCATION = 'location/set' as const
export const CLEAR_LOCATION = 'location/clear' as const

export function setLocation(location: Location) {
  return {
    type: SET_LOCATION,
    payload: {
      location,
    },
  }
}

export function clearLocation() {
  return {
    type: CLEAR_LOCATION,
  }
}

type SetLocationAction = ReturnType<typeof setLocation>
type ClearLocationAction = ReturnType<typeof clearLocation>
type Action = ClearLocationAction | SetLocationAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_LOCATION:
      return action.payload.location
    case CLEAR_LOCATION:
      return {}
    default:
      return state
  }
}
