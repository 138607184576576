import React from 'react'
import { Domain } from '../../../../core'
import stylesModule from './style.module.css'

interface Props {
  listSelect: Array<Domain.Product.ListSelect>
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  // eslint-disable-next-line react/require-default-props
  selectedValue?: string | string[] | number
}
export default function SelectComponent(props: Props) {
  const { listSelect, handleChange, selectedValue } = props

  return (
    <select
      value={selectedValue}
      onChange={(e) => handleChange(e)}
      className={stylesModule.select}
    >
      {listSelect.length > 0 &&
        listSelect.map((item: Domain.Product.ListSelect, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <option value={item.id} key={index}>
            {item.name}
          </option>
        ))}
    </select>
  )
}
