import React from 'react'
import { useDispatch } from 'react-redux'
import { Usecases, Domain } from '../core'

import EndedEventsList from '../components/screens/EndedEventsList'

export default function EndedEventsListContainer() {
  interface Data {
    eventsList: Array<Domain.Event.ListEntity>
    currentDate: string
  }

  const [currentDate, setCurrentDate] = React.useState<string>('')
  const [eventList, setEventList] = React.useState<
    Array<Domain.Event.ListEntity>
  >([])

  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchEndedEventsList() {
        const didCallFetchEventsListApi = (data: Data) => {
          setEventList(data.eventsList)
          setCurrentDate(data.currentDate)
        }
        const reloader = () => {
          dispatch(
            Usecases.Event.fetchEventsList(
              reloader,
              didCallFetchEventsListApi,
              '&type=ended'
            )
          )
        }
        dispatch(
          Usecases.Event.fetchEventsList(
            reloader,
            didCallFetchEventsListApi,
            '&type=ended'
          )
        )
      },
    }),
    [dispatch]
  )

  return (
    <EndedEventsList
      currentDate={currentDate}
      eventList={eventList}
      actions={actions}
    />
  )
}
