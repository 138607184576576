import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  ImageProps,
} from 'react-native'
import { useSelector } from 'react-redux'
import { Modules } from '../../../core'
import { COLORS } from '../../../constants'

interface Props {
  onPress: () => void
  disabled: boolean
  text: string
  imagePath?: ImageProps | undefined
  noImage?: boolean | false
  buttonStyle?: object
  textStyle?: object
}

export default function ButtonSubmit(props: Props) {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const { onPress, disabled, text, noImage, buttonStyle, textStyle } = props

  const image = props.imagePath ? props.imagePath : { uri: tenant.buttonIcon }

  const style = StyleSheet.create({
    submitBtn: {
      flexDirection: 'row',
      backgroundColor: tenant ? tenant.mainColor : COLORS.RED,
      alignItems: 'center',
      justifyContent: 'center',
      height: 50,
      marginTop: 20,
      position: 'relative',
    },
    submitBtnTitle: {
      color: tenant ? tenant.colorButtonText : COLORS.WHITE,
      fontSize: buttonStyle ? 15 : 20,
      letterSpacing: 0.5,
      lineHeight: 28,
    },
    submitBtnTitleDisable: {
      color: COLORS.WHITE,
      fontSize: buttonStyle ? 15 : 20,
      letterSpacing: 0.5,
      lineHeight: 28,
    },
    arrow: {
      width: '4%',
      resizeMode: 'contain',
      paddingBottom: 20,
      position: 'absolute',
      right: 15,
    },
    submitBtnDisabled: {
      flexDirection: 'row',
      backgroundColor: COLORS.GRAY_C,
      alignItems: 'center',
      justifyContent: 'center',
      height: 50,
      position: 'relative',
      marginTop: 20,
    },
  })

  return (
    <TouchableOpacity
      style={
        disabled
          ? [style.submitBtnDisabled, buttonStyle]
          : [style.submitBtn, buttonStyle]
      }
      onPress={onPress}
      disabled={disabled}
    >
      <Text
        style={
          disabled
            ? [style.submitBtnTitleDisable, textStyle]
            : [style.submitBtnTitle, textStyle]
        }
      >
        {text}
      </Text>
      {!noImage && (
        <Image
          source={
            disabled ? require('../../../assets/images/next_icon_1.png') : image
          }
          style={style.arrow}
        />
      )}
    </TouchableOpacity>
  )
}
