import * as React from 'react'
import { StyleSheet, View, TextInput, Text } from 'react-native'
import TextInputValidation from '../../share/TextInputValidation'
import { COLORS, STYLES } from '../../../constants'
import RequiredLabel from '../../share/RequiredLabel'

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  inputSmall: {
    width: 70,
  },
  creditNumberGroup: {
    alignItems: 'center',
    marginBottom: 10,
  },
})

function isNo(no: string): boolean {
  const pattern = /^\d*$/
  return no.match(pattern) !== null
}

function checkValid(securityCd: string): boolean {
  return securityCd.length === 0 || isNo(securityCd)
}

interface Props {
  setSecurityCdisValid: (valid: boolean) => void
  securityCd: string
  setSecurityCd: (securityCd: string) => void
}

export default function SecurityCode(props: Props) {
  const { setSecurityCdisValid, securityCd, setSecurityCd } = props
  const [errors, setErrors] = React.useState<Array<string>>([])

  const textInputsValidation = React.useCallback(() => {
    const isValid = checkValid(securityCd)
    setSecurityCdisValid(isValid)

    const errorMessages = []
    if (!isValid) {
      errorMessages.push('数値を入力してください')
    }

    setErrors(errorMessages)
  }, [securityCd, setSecurityCdisValid, setErrors])

  React.useEffect(() => {
    textInputsValidation()
  }, [textInputsValidation])

  const onChangeSecurityCodeText = React.useCallback(
    (newValue: string) => {
      setSecurityCd(newValue)
    },
    [setSecurityCd]
  )

  return (
    <View>
      <View style={STYLES.formGroupStyle.formSection}>
        <Text style={STYLES.formGroupStyle.formSectionTitle}>
          <RequiredLabel />
          コードを入力してください
        </Text>
        <Text style={STYLES.formGroupStyle.formSectionTitle}>
          TOKYU POINT
          CARDの裏面に記載されているセキュリティコードを入力してください
        </Text>
        <TextInputValidation errors={errors} />
        <View style={[styles.flexRow, styles.creditNumberGroup]}>
          <Text style={STYLES.formGroupStyle.formLabel}>3桁</Text>
          <TextInput
            placeholder="999"
            placeholderTextColor={COLORS.GRAY_E}
            style={[STYLES.formGroupStyle.input, styles.inputSmall]}
            onChangeText={onChangeSecurityCodeText}
            maxLength={3}
            keyboardType={'phone-pad'}
          />
        </View>
      </View>
    </View>
  )
}
