import { Dispatch } from 'redux'
import { Api } from '../../lib'
import { setFAQ } from '../modules/faq'
import * as Domain from '../domain'

interface Data {
  categories: Array<Domain.FAQ.Entity>
}

function didCallFetchFAQ(data: Data, dispatch: Dispatch) {
  dispatch(setFAQ(data.categories))
}

export function fetchFAQ(reloader: () => void, faqLink: string) {
  const options = {
    reload: reloader,
  }

  return async function(dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      '',
      '',
      null,
      didCallFetchFAQ,
      dispatch,
      options,
      faqLink
    )
  }
}
