import * as React from 'react'
import { StyleSheet, Text } from 'react-native'
import { Functions } from '../../../core'
import { FONTS } from '../../../constants'

const styles = StyleSheet.create({
  eventDate: {
    textAlign: 'center',
    fontSize: FONTS.SIZE.M,
    marginTop: 20,
    fontWeight: 'bold',
  },
})

interface Props {
  startDate: string
  endDate: string
  isDisableDuration: boolean,
  eventDateStyle?: object
}

export default function Duration(props: Props) {
  const { startDate, endDate, isDisableDuration, eventDateStyle } = props

  if (!isDisableDuration) {
    return <Text style={[styles.eventDate, eventDateStyle]}>受付期間：{Functions.Format.formatStartEndDate(startDate, endDate)}</Text>
  } else {
    return null
  }
}
