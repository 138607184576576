import * as React from 'react'
import { useParams } from 'react-router-dom'
import {
  StyleSheet,
  View,
  Image,
  Text,
  Keyboard,
  ImageStyle,
} from 'react-native'
import { Domain } from '../../../core'
import { COLORS, FONTS } from '../../../constants'
import QnA from '../../share/QnA'
import PrivacyPolicy from '../../share/PrivacyPolicy'
import Duration from './Duration'
import Comment from './Comment'
import stylesModule from './styles.module.css'
import { useDispatch } from 'react-redux'
import { UploadStatusEnum } from '../../../constants/upload-image-status'
import ButtonSubmit from '../../share/ButtonSubmit'
import { isToLCampaignLIFF } from '../../../core/functions/tol'

const styles = StyleSheet.create({
  contentSection: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    textAlign: 'center',
    backgroundColor: COLORS.PINK_A,
  },
  contentTitleSection: {
    textAlign: 'center',
    paddingHorizontal: 0,
    marginBottom: 20,
  },
  eventTitle: {
    textAlign: 'center',
    fontSize: FONTS.SIZE.XL,
    fontWeight: '600',
    lineHeight: 28,
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 15,
    backgroundColor: COLORS.PINK_A,
    color: COLORS.ORANGE,
  },
  eventDate: {
    color: COLORS.RED_A,
  },
  inputSection: {
    paddingHorizontal: 15,
  },
  uploadTitle: {
    fontSize: FONTS.SIZE.M,
    lineHeight: 28,
    marginBottom: 15,
    marginTop: 28,
  },
  uploadBtnTitle: {
    fontSize: FONTS.SIZE.XL,
  },
  cameraIcon: {
    width: 20,
    resizeMode: 'contain',
    marginRight: 20,
  },
  submitBtnWrap: {
    paddingHorizontal: 15,
    paddingTop: 5,
  },
  submitButton: {
    borderRadius: 24,
  },
  imageUploadWrap: {
    width: '100vw',
    marginLeft: -15,
  },
  imageUploadSuccessWrap: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  imageUploadErrorWrap: {
    backgroundColor: COLORS.PINK,
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  imageStatusIcon: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
    position: 'absolute',
  },
  imageName: {
    fontSize: FONTS.SIZE.M,
    marginLeft: 25,
  },
  imageNameSuccess: {
    color: COLORS.SUCCESS,
  },
  uploadImageErrMsg: {
    marginBottom: 5,
  },
  textFooterWrap: {
    marginLeft: 15,
    textAlign: 'left',
  },
  qnaText: {
    color: COLORS.ORANGE,
  },
  marutokuTopText: {
    marginTop: 60,
    marginBottom: 23,
    marginHorizontal: 'auto',
  },
  qnaCustomStyle: {
    marginBottom: 40,
    marginHorizontal: 'auto',
  },
  tolEventFooter: {
    marginBottom: 30,
  },
})
interface Props {
  event: Domain.Event.DetailEntity
  checkEvent: Domain.Event.CheckEventEntiTy
  actions: {
    fetchEvent: (eventId: string) => void
    fetchEventEntryStatus: (eventId: string) => void
    registerEvent: (body: Domain.Event.PostEventEntity) => void
    uploadImageAPI: (
      blob: Blob,
      evenId: string,
      setUploadStatus: (status: number) => void,
      scrollPosition: number
    ) => void
  }
}
type ParamTypes = {
  eventId: string
}

export default function EventDetail(props: Props) {
  const {
    event,
    checkEvent,
    actions: {
      fetchEvent,
      fetchEventEntryStatus,
      registerEvent,
      uploadImageAPI,
    },
  } = props

  const { eventId } = useParams<ParamTypes>()
  const [comment, setComment] = React.useState([])
  const [disabled, setDisabled] = React.useState<boolean>(true)
  const [uploadStatus, setUploadStatus] = React.useState<UploadStatusEnum>()
  const [submitTitle, setSubmitTitle] = React.useState('')
  const [imageName, setImageName] = React.useState<string>('')
  const [noRedirect, setNoRedirect] = React.useState<boolean>(false)

  const imageLimitSize = 5000000 //5MB
  const scrollPosition = React.useRef<number>(0)
  const dispatch = useDispatch<any>()

  const findIndexEmptyComment = (arg: {}) => {
    return !Object.entries(arg)[0][1]
  }

  const eventRegistFormValid = React.useCallback(() => {
    const commentEmptyIndex = comment.findIndex(findIndexEmptyComment)
    const isInvalid =
      (event.commentInfos.length &&
        comment.length !== event.commentInfos.length) ||
      (event.isDisplayUploadImage && uploadStatus !== UploadStatusEnum.Done) ||
      commentEmptyIndex !== -1
    return !isInvalid
  }, [event, uploadStatus, comment])

  React.useEffect(() => {
    fetchEventEntryStatus(eventId ?? '')
    fetchEvent(eventId ?? '')
  }, [fetchEventEntryStatus, fetchEvent, eventId])

  React.useEffect(() => {
    if (checkEvent.eventRegist) {
      setSubmitTitle('エントリー済みです')
      setNoRedirect(true)
      setDisabled(true)
    } else {
      if (Date.parse(event.currentDate) < Date.parse(event.endDate)) {
        setSubmitTitle('エントリーする')
        setNoRedirect(false)
        setDisabled(!eventRegistFormValid())
      } else {
        setSubmitTitle('終了しました')
        setNoRedirect(true)
        setDisabled(true)
      }
    }
  }, [eventRegistFormValid, checkEvent, event, uploadStatus, comment])

  function handleChooseFile(e: React.ChangeEvent<HTMLInputElement>) {
    const acceptedFormat = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/heic',
      'image/heif',
    ]
    const image = (e.target.files as FileList)[0]
    if (image) {
      setImageName(image.name)
      if (!acceptedFormat.includes(image.type)) {
        setUploadStatus(UploadStatusEnum.Failed)
        e.target.value = ''
      } else {
        if (image.size > imageLimitSize) {
          setUploadStatus(UploadStatusEnum.Failed)
        } else {
          scrollPosition.current = window.scrollY
          const blob = new Blob([image])
          dispatch(
            uploadImageAPI(
              blob,
              event.eventId,
              setUploadStatus,
              scrollPosition.current
            )
          )
        }
      }
    }
  }

  const top = React.useRef<View>(null)
  const scroll = (view: React.RefObject<View>) => {
    if (view && view.current) {
      view.current.focus()
    }
  }

  function submitForm() {
    Keyboard.dismiss()
    scroll(top)
    const data = {
      tenantId: process.env.REACT_APP_TENANT_ID || '',
      eventId: eventId ?? '',
      comment,
    }
    registerEvent(data)
  }

  const qnaTokyuSoreTitle = '制度詳細・よくある質問'
  const qnaOtherAppTitle = 'よくある質問はこちら'
  const [isTokyuStore, setIsTokyuStore] = React.useState(false)

  React.useEffect(() => {
    if (process.env.REACT_APP_TENANT_ID === '0101') {
      setIsTokyuStore(true)
    }
  }, [])

  return (
    <View>
      {event.eventId !== '' && (
        <>
          {!!event.eventImageDetail && (
            <img
              alt=""
              src={event.eventImageDetail}
              className={stylesModule.eventBanner}
            />
          )}
          <View style={styles.contentTitleSection}>
            <Text style={styles.eventTitle}>{event.headLineText}</Text>
            <Duration
              startDate={event.startDate}
              endDate={event.endDate}
              isDisableDuration={event.isDisableDuration}
              eventDateStyle={styles.eventDate}
            />
            <View style={styles.submitBtnWrap}>
              <ButtonSubmit
                noImage={noRedirect}
                onPress={submitForm}
                disabled={disabled}
                text={submitTitle}
                buttonStyle={styles.submitButton}
              />
            </View>
          </View>
          <View style={styles.contentSection}>
            <div
              className={stylesModule.eventDescription}
              dangerouslySetInnerHTML={{ __html: event.eventDescription }}
            />
          </View>
          {!checkEvent.eventRegist &&
            Date.parse(event.currentDate) < Date.parse(event.endDate) && (
              // ENTRY EVENT
              <View style={styles.inputSection}>
                {event.isDisplayUploadImage && (
                  <View>
                    <Text style={styles.uploadTitle}>{event.imageText}</Text>
                    <View>
                      {uploadStatus === UploadStatusEnum.Done && (
                        <View style={styles.imageUploadSuccessWrap}>
                          <Image
                            source={require('../../../assets/images/image-polaroid.png')}
                            style={styles.imageStatusIcon as ImageStyle}
                          />
                          <Text
                            numberOfLines={1}
                            style={[styles.imageName, styles.imageNameSuccess]}
                          >
                            {imageName}
                          </Text>
                        </View>
                      )}
                      {uploadStatus === UploadStatusEnum.Failed && (
                        <View style={styles.imageUploadWrap}>
                          <View style={styles.imageUploadErrorWrap}>
                            <Image
                              source={require('../../../assets/images/bitmap.png')}
                              style={styles.imageStatusIcon as ImageStyle}
                            />
                            <Text
                              numberOfLines={1}
                              style={[
                                styles.imageName,
                                styles.uploadImageErrMsg,
                              ]}
                            >
                              この画像はエントリーできません
                            </Text>
                            <Text numberOfLines={1} style={styles.imageName}>
                              {imageName}
                            </Text>
                          </View>
                        </View>
                      )}
                    </View>
                    <label htmlFor="file" className={stylesModule.uploadBtn}>
                      <Image
                        source={require('../../../assets/images/camera_icon.png')}
                        style={styles.cameraIcon as ImageStyle}
                      />
                      <Text style={styles.uploadBtnTitle}>画像を選択する</Text>
                    </label>
                    <input
                      className={stylesModule.fileInput}
                      id="file"
                      onChange={(e) => {
                        handleChooseFile(e)
                      }}
                      type="file"
                      accept=".jpg, .jpeg, .png, .gif, .heif, .heic"
                    />
                  </View>
                )}
                {event.commentInfos.map((data) => {
                  return (
                    <Comment
                      key={data.comment_title}
                      data={data}
                      comment={comment}
                      setComment={setComment}
                    />
                  )
                })}
              </View>
              // ENTRY EVENT END
            )}
          <Duration
            startDate={event.startDate}
            endDate={event.endDate}
            eventDateStyle={styles.eventDate}
            isDisableDuration={event.isDisableDuration}
          />
          <View style={styles.submitBtnWrap}>
            <ButtonSubmit
              noImage={noRedirect}
              onPress={submitForm}
              disabled={disabled}
              text={submitTitle}
              buttonStyle={styles.submitButton}
            />
          </View>
          {!isToLCampaignLIFF() && (
            <>
              {isTokyuStore && !event.isDisableIndex && (
                <QnA
                  title={'まる得 TOP'}
                  textStyle={styles.qnaText}
                  textFooterWrap={styles.textFooterWrap}
                  customUrl={'/top-campaign'}
                  customStyle={styles.marutokuTopText}
                />
              )}
              <QnA
                title={
                  isTokyuStore && !event.isDisableIndex
                    ? qnaTokyuSoreTitle
                    : qnaOtherAppTitle
                }
                textStyle={styles.qnaText}
                textFooterWrap={styles.textFooterWrap}
                isExternalLink={isTokyuStore && !event.isDisableIndex}
                customStyle={
                  isTokyuStore && !event.isDisableIndex
                    ? styles.qnaCustomStyle
                    : undefined
                }
              />
              <PrivacyPolicy
                textStyle={styles.qnaText}
                textFooterWrap={styles.textFooterWrap}
              />
            </>
          )}
          {isToLCampaignLIFF() && <View style={styles.tolEventFooter} />}
        </>
      )}
    </View>
  )
}
