import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useNavigate, useParams } from 'react-router-dom'
import PageTitle from '../../share/PageTitle'
import ListStore from '../../share/ListStore'
import ButtonSubmit from '../../share/ButtonSubmit'
import { Domain } from '../../../core'
import stylesModule from '../NearbyStoreGPSInput/NearByStore.module.css'
import Banner from './Banner'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const SUBMIT_BTN_TITLE = '{value0}を登録する'
const TENANT_ID = process.env.REACT_APP_TENANT_ID

const styles = StyleSheet.create({
  container: {
    maxHeight: '100vh',
    height: '100%',
  },
  subTitle: {
    marginBottom: 5,
    fontSize: 16,
    lineHeight: 24,
  },
  banner: {
    padding: 15,
  },
  listStore: {},
})

interface Props {
  tenant: Domain.Tenant.Entity
  stores: Array<Domain.Store.Entity>
  actions: {
    fetchNearbyStore: (prefectureCd: string | undefined) => void
    registerStore: (
      storeToRegister: Domain.StoreToRegister.Entity,
      navigate: () => void
    ) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

export default function NearbyStoreInput(props: Props) {
  const {
    tenant,
    stores,
    actions: { fetchNearbyStore, registerStore, clearScreenEmbeddedErrorAlert },
  } = props

  const navigate = useNavigate()
  const { prefectureCd } = useParams()

  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  React.useEffect(
    () => fetchNearbyStore(prefectureCd),
    [fetchNearbyStore, prefectureCd]
  )

  const [storeCd, setStoreCd] = React.useState<string>('')

  const banners = [...tenant.bannerStores]

  const navigateToStoreRegistrationCompleted = React.useCallback(
    () => navigate('/store-registration-completed'),
    [navigate]
  )

  const handleOnPressSubmit = React.useCallback(() => {
    const storeToRegister = { storeCd }
    registerStore(storeToRegister, navigateToStoreRegistrationCompleted)
  }, [registerStore, storeCd, navigateToStoreRegistrationCompleted])

  const buttonEnabled = React.useMemo(() => storeCd !== '', [storeCd])

  const headerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    styles.listStore = { paddingTop: headerRef.current?.offsetHeight as number }
  }, [tenant])

  return (
    <View style={styles.container}>
      <div ref={headerRef} className={stylesModule.header}>
        <PageTitle title={tenant.nearbyStoresTitle} />
        <Text style={styles.subTitle}>{tenant.nearbyStoresDescription}</Text>
      </div>
      <div className={stylesModule.content}>
        <ListStore
          stores={stores}
          setStoreCd={setStoreCd}
          storeCd={storeCd}
          listGrid={styles.listStore}
        />
        <View style={styles.banner}>
          {banners.map((banner, index: number) => {
            if (banner.imageURL) {
              return (
                <Banner
                  imageSrc={banner.imageURL}
                  link={banner.redirectURL}
                  key={index}
                />
              )
            } else {
              return null
            }
          })}
        </View>
      </div>
      <div className={stylesModule.footer}>
        <ButtonSubmit
          onPress={handleOnPressSubmit}
          disabled={!buttonEnabled}
          text={
            TENANT_ID?.includes('0302')
              ? '施設利用用途を登録する'
              : handleStoreGenreTitle(SUBMIT_BTN_TITLE, tenant.category)
          }
        />
      </div>
    </View>
  )
}
