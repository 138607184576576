import * as React from 'react'
import { ViewStyle ,View, Image, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { COLORS } from '../../../constants'
import { Domain, Modules } from '../../../core'

interface Props {
  stores: Array<Domain.Store.Entity>
  storeCd: string
  setStoreCd: (storeCd: string) => void
  listGrid?: ViewStyle | null
}

export default function Link(props: Props) {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const styles = StyleSheet.create({
    radioOff: {
      height: 20,
      width: 20,
      marginRight: 10,
    },
    radioOn: {
      height: 14,
      width: 14,
      position: 'absolute',
      left: 18,
    },
    store: {
      paddingHorizontal: 15,
      paddingVertical: 20,
      borderBottomWidth: 1,
      borderColor: COLORS.GRAY_A,
      flexDirection: 'row',
      alignItems: 'center',
    },
    storeName: {
      fontSize: 16,
    },
    choosenStore: {
      backgroundColor: tenant ? tenant.nearbyStoreHoverColor : COLORS.PINK
    },
  })

  const { stores, storeCd, setStoreCd, listGrid } = props
  return (
    <View style={listGrid}>
      {stores.map(data => {
        return (
          <View key={data.storeCd}>
            {storeCd === data.storeCd ? (
              <TouchableOpacity style={[styles.store, styles.choosenStore]}>
                <Image style={styles.radioOff} source={require('../../../assets/images/radio_off.png')} />
                <Image style={styles.radioOn} source={{uri: tenant.radioImage}} />
                <Text style={styles.storeName}>{data.storeName}</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => setStoreCd(data.storeCd)} style={styles.store}>
                <Image style={styles.radioOff} source={require('../../../assets/images/radio_off.png')} />
                <Text style={styles.storeName}>{data.storeName}</Text>
              </TouchableOpacity>
            )}
          </View>
        )
      })}
    </View>
  )
}
