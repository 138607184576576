import * as Domain from '../domain'

type NearbyStore = Array<Domain.Store.Entity>

export function createInitialState(): NearbyStore {
  return []
}

export const SET_NEAR_BY_STORES = 'nearbyStores/set' as const
export const CLEAR_NEAR_BY_STORES = 'nearbyStores/clear' as const

export function setNearbyStores(stores: NearbyStore) {
  return {
    type: SET_NEAR_BY_STORES,
    payload: {
      stores,
    },
  }
}

export function clearNearbyStores() {
  return {
    type: CLEAR_NEAR_BY_STORES,
  }
}

type SetNearbyStoresAction = ReturnType<typeof setNearbyStores>
type ClearNearbyStoresAction = ReturnType<typeof clearNearbyStores>
type Action = ClearNearbyStoresAction | SetNearbyStoresAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_NEAR_BY_STORES:
      return action.payload.stores
    case CLEAR_NEAR_BY_STORES:
      return []
    default:
      return state
  }
}
