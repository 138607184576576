import { Dispatch } from 'redux'
import { Api } from '../../lib'
import { Domain } from '..'

export function fetchRichMenu(reloader: () => void, didCallFetchRichMenuApi: (data: Domain.RichMenu.FetchedRichmenu) => void) {
  const options = {
    reload: reloader,
  }

  return async function(dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'rich-menu/active', `&tenantName=${process.env.REACT_APP_TENANT_NAME}`, null, didCallFetchRichMenuApi, dispatch, options)
  }
}
