import * as React from 'react'
import { View, Text, StyleSheet, Linking } from 'react-native'
import QnA from '../../share/QnA'
import { STYLES, COLORS } from '../../../constants'
import tableStyle from './Table.module.css'

const styles = StyleSheet.create({
  content: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
    marginBottom: 20,
  },
  link: {
    color: COLORS.BLUE,
    textDecorationLine: 'underline',
  },
})

export default function LineAccountPartnerInfo() {
  const handleLink = React.useCallback((link: string) => {
    // @ts-ignore
    Linking.openURL(link, '_self')
  }, [])

  const linePartnerArr = [
    {
      name: '株式会社東急ストア',
      homePageUrl: 'https://www.tokyu-store.co.jp/',
      policyUrl: 'https://www.tokyu-store.co.jp/privacy/',
    },
    {
      name: '株式会社東急百貨店',
      homePageUrl: 'https://www.tokyu-dept.co.jp/',
      policyUrl: 'https://www.tokyu-dept.co.jp/corporate/privacy/',
    },
  ]

  return (
    <View style={STYLES.generalStyle.container}>
      <View>
        <Text style={styles.content}>
          {'\n'}別表：東急株式会社がLINE公式アカウントを提供する提携会社など
        </Text>
        <table className={tableStyle.table}>
          <thead>
            <tr>
              <th className={tableStyle.border}>
                <Text>社名</Text>
              </th>
              <th className={tableStyle.border}>
                <Text>ホームページURL</Text>
              </th>
              <th className={tableStyle.border}>
                <Text>個人情報保護方針URL</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {linePartnerArr.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={tableStyle.border}>
                    <Text>{item.name}</Text>
                  </td>
                  <td className={tableStyle.border}>
                    <Text
                      onPress={() => handleLink(item.homePageUrl)}
                      style={styles.link}
                    >
                      {item.homePageUrl}
                    </Text>
                  </td>
                  <td className={tableStyle.border}>
                    <Text
                      onPress={() => handleLink(item.policyUrl)}
                      style={styles.link}
                    >
                      {item.policyUrl}
                    </Text>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </View>
      <QnA />
    </View>
  )
}
