import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Usecases, Domain, Modules } from '../core'
import RedirectPage from '../components/screens/RedirectPage'

export default function RedirectRichMenuContainer() {
  const [data, setData] = React.useState<Domain.RichMenu.FetchedRichmenu>()
  const userStatus = useSelector((state: Modules.AppState) => state.userStatus)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchRichMenu() {
        const didCallFetchRichMenuApi = (
          richmenuData: Domain.RichMenu.FetchedRichmenu
        ) => {
          setData(richmenuData)
        }
        const reloader = () => {
          dispatch(
            Usecases.RichMenu.fetchRichMenu(reloader, didCallFetchRichMenuApi)
          )
        }
        dispatch(
          Usecases.RichMenu.fetchRichMenu(reloader, didCallFetchRichMenuApi)
        )
      },
      fetchUserStatus() {
        const reloader = () => {
          dispatch(Usecases.UserStatus.fetchUserStatus(reloader))
        }
        dispatch(Usecases.UserStatus.fetchUserStatus(reloader))
      },
    }),
    [dispatch]
  )

  return <RedirectPage userStatus={userStatus} data={data} actions={actions} />
}
