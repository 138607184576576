import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Linking } from 'react-native'
import { useNavigate } from 'react-router-dom'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import { STYLES, COLORS } from '../../../constants'

const styles = StyleSheet.create({
  contentSection: {
    marginBottom: 30,
  },
  content: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
  },
  linkWrapper: {
    width: '100%',
  },
  linkAction: {
    width: 'auto',
    marginRight: 'auto',
  },
  link: {
    textDecorationLine: 'underline',
    color: COLORS.BLUE,
    width: '100%',
  },
})

export default function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const navigateToLineAccountPartnerInfo = React.useCallback(
    () => navigate('/line-account-partner'),
    [navigate]
  )
  const linkToToLineAccountPartnerInfo = React.useCallback(() => {
    navigateToLineAccountPartnerInfo()
  }, [navigateToLineAccountPartnerInfo])

  const linkToTopCard = React.useCallback(() => {
    // @ts-ignore
    Linking.openURL('https://www.topcard.co.jp/privacy2/', '_self')
  }, [])

  const linkToTokyuPrivacyPolicy = React.useCallback(() => {
    Linking.openURL(
      'https://www.tokyu.co.jp/company/csr/society/transmission/privacy_policy1.html',
      // @ts-ignore
      '_self'
    )
  }, [])
  return (
    <View style={STYLES.generalStyle.container}>
      <PageTitle title="東急グループLINE公式アカウント利用者向けサービス 個人情報取扱い方針" />
      <Text style={[styles.contentSection, styles.content]}>
        この「東急グループLINE公式アカウント利用者向けサービス　個人情報取扱い方針」は、東急株式会社（以下、当社）の提携会社などの協力のもと、当社が提供する東急グループの各LINE公式アカウント（以下、グループ各アカウント）にて提供するサービス（以下、本サービス）に適用されます。
      </Text>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          １．本サービス
          {'\n'}
          本サービスとはグループ各アカウントにて提供される以下のサービスを指します。
          {'\n'}① TOKYU POINT会員連携サービス
          {'\n'}（グループ各アカウントにTOKYU
          POINT会員情報を登録し連携した利用者に提供される、TOKYU
          POINT会員情報を活用したTOKYU
          POINT会員限定のクーポンや情報の受け取り等のサービス）
          {'\n'}② 交通系ICカード連携サービス
          {'\n'}
          （グループ各アカウントにPASMO/Suica番号を登録し連携した利用者に提供される、自動改札機及び運賃機の通過データ等を活用した利用者限定のクーポンや情報の受け取り等のサービス）
          {'\n'}③
          上記①②の他、グループ各アカウントの利用者が入力または設定された情報に基づく情報提供サービス等
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          ２．お預かりする個人情報
          {'\n'}当社は、本サービスにて利用者の以下の個人情報を取得いたします。
          {'\n'}
          LINEプロフィール情報（LINE内で公開される名前、プロフィール画像、ステータスメッセージ）、LINEユーザー識別子（LINEが一意に割り当てた内部識別子）、その他グループ各アカウントで入力または設定された情報、位置情報、本サービスに関連したグループ各社への問い合わせいただいた内容
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          ３．個人情報の利用目的
          {'\n'}本サービスにおける個人情報の利用目的は以下のとおりです。
          {'\n'}
          グループ各アカウントにおける本サービスの実施、及びその改善のための本サービス利用動向分析、その他のマーケティング分析
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          ４．個人情報の共同利用
          {'\n'}お預かりする個人情報は以下のとおり共同利用いたします。
          {'\n'}① 共同して利用される個人情報の項目
          {'\n'}　上記のお預かりする個人情報
          {'\n'}② 共同して利用する者の範囲
          <View style={styles.linkWrapper}>
            <TouchableOpacity
              onPress={linkToToLineAccountPartnerInfo}
              style={styles.linkAction}
            >
              <Text style={[styles.content, styles.link]}>
                当社がLINE公式アカウントを提供する別表に定める提携会社など
              </Text>
            </TouchableOpacity>
          </View>
          {'\n'}③ 共同して利用する者の利用目的
          {'\n'}上記の個人情報の利用目的
          {'\n'}④ 当該個人情報の管理について責任を有する者の氏名または名称
          {'\n'}東急株式会社
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          ５．個人情報等の第三者利用
          {'\n'}
          当社は共同利用する提携会社などを除き、個人情報を第三者に提供することはありません。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          ６．適切な管理
          {'\n'}
          ①ユーザーの個人情報等において、漏えい、紛失、破壊、改ざん又はユーザーの個人情報等への不正なアクセスを防止するため、個人情報等の取扱いについて、適時、適切に見直しを行い、個人情報等の安全で正確な管理に努めます。
          {'\n'}
          ②本サービスの提供に当たり、個人情報等の取扱業務の全部または一部を利用目的の範囲内で第三者に委託する場合があります。
          この場合、個人情報の適正な管理が期待できる委託先を選定したうえで、適正な取扱いを確保するための措置を契約上義務付けます。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          ７．個人情報に関するお問い合わせ先
          {'\n'}
          上記以外の個人情報保護方針に関する事項、および個人情報に関するお問い合わせは以下の該当するサイトをご参照ください。
          {'\n'}① TOKYU POINT会員の皆様{'\n'}
          <View style={styles.linkWrapper}>
            <TouchableOpacity onPress={linkToTopCard} style={styles.linkAction}>
              <Text style={[styles.content, styles.link]}>
                TOKYU POINTにおける個人情報の取扱いについて
              </Text>
            </TouchableOpacity>
          </View>
          {'\n'}② TOKYU POINT会員以外の皆様{'\n'}
          <View style={styles.linkWrapper}>
            <TouchableOpacity
              onPress={linkToTokyuPrivacyPolicy}
              style={styles.linkAction}
            >
              <Text style={[styles.content, styles.link]}>
                東急株式会社　個人情報保護方針{'\n'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.linkWrapper}>
            <TouchableOpacity
              onPress={linkToToLineAccountPartnerInfo}
              style={styles.linkAction}
            >
              <Text style={[styles.content, styles.link]}>
                ほか、別表記載の提携会社などの個人情報保護方針
              </Text>
            </TouchableOpacity>
          </View>
        </Text>
      </View>

      <Text style={styles.content}>（2020年4月20日制定）</Text>
      <Text style={styles.content}>（2020年6月1日改定）</Text>
      <QnA />
    </View>
  )
}
