import * as React from 'react'
import { StyleSheet, View, Text, Image, Keyboard } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { CardType } from '../../../constants/card-types'
import { Domain } from '../../../core'
import { COLORS, STYLES } from '../../../constants'
import QnA from '../../share/QnA'
import ButtonSubmit from '../../share/ButtonSubmit'
import PageTitle from '../../share/PageTitle'
import MiddleAndLastPointNo from './MiddleAndLastPointNo'
import SecurityCode from './SecurityCode'

const styles = StyleSheet.create({
  cardImage: {
    paddingBottom: '12%',
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  cardName: {
    textAlign: 'center',
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
  },
  note: {
    color: COLORS.RED,
    fontSize: 16,
    marginBottom: 10,
  },
})

type CardToRegister = Domain.PointCardToRegister.Entity | null

interface Props {
  userStatus: Domain.UserStatus.Entity | null
  actions: {
    fetchUserStatus: () => void
    registerCard: (card: CardToRegister, navigate: () => void) => void
    updateCard: (card: CardToRegister, navigate: () => void) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

const cardCd = CardType.tokyuPointCardRed
const firstPartPointNo = '65023000'

export default function RedCardInput(props: Props) {
  const {
    userStatus,
    actions: {
      fetchUserStatus,
      registerCard,
      updateCard,
      clearScreenEmbeddedErrorAlert,
    },
  } = props

  const navigate = useNavigate()

  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  React.useEffect(() => fetchUserStatus(), [fetchUserStatus])

  const [middlePartPointNo, setMiddlePartPointNo] = React.useState<string>('')
  const [lastPartPointNo, setLastPartPointNo] = React.useState<string>('')
  const [securityCd, setSecurityCd] = React.useState<string>('')
  const [pointNosAreValid, setPointNosAreValid] = React.useState<boolean>(false)
  const [securityCdisValid, setSecurityCdisValid] =
    React.useState<boolean>(false)

  const submitButtonEnabled =
    pointNosAreValid &&
    securityCdisValid &&
    middlePartPointNo.length !== 0 &&
    lastPartPointNo.length !== 0 &&
    securityCd.length !== 0

  const submitButtonTitle = React.useMemo(() => {
    return userStatus?.cardRegist
      ? 'カード情報を更新する'
      : 'カード情報を登録する'
  }, [userStatus])

  const top = React.useRef<View>(null)
  const scroll = (view: React.RefObject<View>) => {
    if (view && view.current) {
      view.current.focus()
    }
  }
  const navigateToCardRegistrationCompleted = React.useCallback(
    () => navigate(`/card-registration-completed/${userStatus?.storeRegist}`),
    [navigate, userStatus]
  )
  const handleOnPressSubmit = React.useCallback(() => {
    Keyboard.dismiss()
    scroll(top)
    const pointNo = firstPartPointNo + middlePartPointNo + lastPartPointNo
    const cardEntered = {
      cardCd,
      pointNo,
      securityCd,
    }

    if (userStatus?.cardRegist) {
      updateCard(cardEntered, navigateToCardRegistrationCompleted)
    } else {
      registerCard(cardEntered, navigateToCardRegistrationCompleted)
    }
  }, [
    userStatus,
    middlePartPointNo,
    lastPartPointNo,
    securityCd,
    registerCard,
    updateCard,
    navigateToCardRegistrationCompleted,
  ])

  return (
    <View style={STYLES.generalStyle.container}>
      <View>
        <PageTitle title="カード情報を入力してください" />
        <Image
          source={require('../../../assets/images/red_card.png')}
          style={styles.cardImage}
        />
        <Text ref={top} style={styles.cardName}>
          TOKYU POINT CARD（赤）を選択中
        </Text>
        <Text style={styles.note}>
          TOKYU POINT CARD（赤）のご登録については、TOKYU POINT
          CARD（⾚）の会員情報登録が必要です。詳しくは「よくある質問」をご覧ください。
        </Text>
        <MiddleAndLastPointNo
          setPointNosAreValid={setPointNosAreValid}
          middlePartPointNo={middlePartPointNo}
          setMiddlePartPointNo={setMiddlePartPointNo}
          lastPartPointNo={lastPartPointNo}
          setLastPartPointNo={setLastPartPointNo}
        />
        <SecurityCode
          setSecurityCdisValid={setSecurityCdisValid}
          securityCd={securityCd}
          setSecurityCd={setSecurityCd}
        />
        <ButtonSubmit
          onPress={handleOnPressSubmit}
          disabled={!submitButtonEnabled}
          text={submitButtonTitle}
        />
      </View>
      <QnA />
    </View>
  )
}
