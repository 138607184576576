import * as React from 'react'
import { Domain } from '../../../../core'

interface Props {
  event: Domain.Event.DetailEntity
}

export default function Description(props: Props) {
  const { event } = props

  if (event.eventDescription.length > 0) {
    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: event.eventDescription }}
      />
    )
  }
  return null
}
