import * as React from 'react'
import styles from './styles.module.css'
import { TouchableOpacity } from 'react-native'
import { useNavigate } from 'react-router-dom'
const { liff } = window

type Props = {
  imageSrc: string
  link: string
  getOffset: () => number
}

export default function Banner(props: Props) {
  const { imageSrc, link, getOffset } = props

  const n = React.useRef(0)
  const onPressIn = React.useCallback(() => {
    n.current = getOffset()
  }, [getOffset])

  function validURL(str: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(str)
  }
  const navigate = useNavigate()

  const onPressOut = React.useCallback(() => {
    const delta = Math.abs(getOffset() - n.current)
    if (delta > 0) {
      return
    }
    if (validURL(link)) {
      liff.openWindow({
        url: link,
      })
    } else {
      navigate({ pathname: link })
    }
  }, [navigate, link, getOffset])

  return (
    <TouchableOpacity
      onPressOut={onPressOut}
      onPressIn={onPressIn}
      delayPressOut={100}
    >
      <img className={styles.bannerImage} src={imageSrc} alt="" />
    </TouchableOpacity>
  )
}
