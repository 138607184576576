import * as React from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import * as Domain from '../../../core/domain'
import { ErrorStatus } from '../../../constants/error-status'
import styles from './ApiErrorAlert.module.css'
import ScreenEmbeddedErrorAlert from './ScreenEmbeddedErrorAlert'
import ModalErrorAlert from './ModalErrorAlert'
import RedirectModal from '../RedirectModal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Modules } from '../../../core'
import { isToLCampaignLIFF } from '../../../core/functions/tol'

const { liff } = window

interface Props {
  error: Domain.RecoverableError | Domain.ApiErrorResponse.Entity | null
  actions: {
    clearError: () => void
  }
}

function isRecoverableError(
  target: Domain.RecoverableError | Domain.ApiErrorResponse.Entity
): target is Domain.RecoverableError {
  return (target as Domain.RecoverableError).label !== undefined
}

export default function ApiErrorAlert(props: Props) {
  const {
    error,
    actions: { clearError },
  } = props

  const navigate = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams(useLocation().search)
  const dispatch = useDispatch<any>()
  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )

  const closeModal = React.useCallback(() => {
    clearError()
  }, [clearError])

  React.useEffect(() => {
    if (error) {
      clearError()
    }
  }, [location])

  const [isTokyuStore, setIsTokyuStore] = React.useState(false)

  React.useEffect(() => {
    if (process.env.REACT_APP_TENANT_ID === '0101') {
      setIsTokyuStore(true)
    }
  }, [])

  if (error == null) {
    return null
  }
  if (isRecoverableError(error)) {
    return (
      <div className={styles.container}>
        <ScreenEmbeddedErrorAlert error={error} />
      </div>
    )
  }

  const navigateToSelectCard = () => navigate({ pathname: '/select-card' })

  const backToEventList = () => {
    if (search.get('back')) {
      navigate(-1)
    } else {
      liff.closeWindow()
    }
  }

  const redirectToTogo = () => {
    const campaignLIFFUrl = isToLCampaignLIFF()
      ? process.env.REACT_APP_TOL_CAMPAIGN_LIFF_URL
      : process.env.REACT_APP_LIFF_URL

    const campaignUrl = encodeURIComponent(
      `${campaignLIFFUrl}${window.location.pathname}`
    )
    const redirectUrl = `${process.env.REACT_APP_TOGO_LIFF}?campaignUrl=${campaignUrl}`
    liff.openWindow({ url: redirectUrl })
  }

  const navigateToEventDetail = () => {
    const currentPath = location.pathname
    const eventId = receipt.event?.eventId
    const eventDetailPath = `/events/${eventId}`

    if (currentPath !== eventDetailPath) {
      navigate(eventDetailPath)
    } else {
      navigate(eventDetailPath, { replace: true })
    }

    // データを初期化
    dispatch(Modules.Receipt.clear())
  }

  switch (error.errorCode) {
    case ErrorStatus.USED_RECEIPT:
      return (
        <RedirectModal
          redirectUrl={navigateToEventDetail}
          message={error.errorMessage}
        />
      )
    case ErrorStatus.MAINTENANCE_ERROR_STATUS:
      return <Navigate to="/mantainance" replace />
    case ErrorStatus.CARD_REQUIRED_ERROR_STATUS:
      return isTokyuStore ? (
        <RedirectModal
          redirectUrl={redirectToTogo}
          message={error.errorMessage}
        />
      ) : (
        <RedirectModal
          redirectUrl={navigateToSelectCard}
          message={error.errorMessage}
        />
      )
    case ErrorStatus.ENTRY_ENDED_EVENT_ERROR_STATUS:
    case ErrorStatus.ENTRY_DELETED_EVENT_ERROR_STATUS:
      return (
        <RedirectModal
          redirectUrl={backToEventList}
          message={error.errorMessage}
        />
      )
    default:
      return (
        <div className={styles.container}>
          <ModalErrorAlert error={error} onPress={closeModal} />
        </div>
      )
  }
}
