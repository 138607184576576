/* eslint-disable default-param-last */
import * as Domain from '../domain'

type Receipt = Domain.Receipt.Entity
type Event = Domain.Event.DetailEntity
type Prize = Domain.Event.PrizeEntity

export const createInitialState = (): Receipt => ({})

const SET_RECEIPT_EVENT = 'receipt/event/set' as const
const SET_RECEIPT_PRIZES = 'receipt/prizes/set' as const
const SET_RECEIPT_FILE = 'receipt/file/set' as const
const SET_RECEIPT_IS_SMART_RECEIPT = 'receipt/isSmartReceipt/set' as const
const SET_RECEIPT_TOTAL_PRICE = 'receipt/totalPrice/set' as const
const SET_RECEIPT_IMAGE_PATH = 'receipt/imagePath/set' as const
const SET_RECEIPT_TOTAL_PRICE_IS_MODIFIED_AFTER_OCR =
  'receipt/totalPriceIsModifiedAfterOCR/set' as const
const SET_RECEIPT_OCR_DATA = 'receipt/ocrData/set' as const
const SET_RECEIPT_SELECTED_PRIZE = 'receipt/selectedPrize/set' as const

const CLEAR_RECEIPT_OCR_DATA = 'receipt/ocrData/clear' as const
const CLEAR_RECEIPT = 'receipt/clear' as const

// action creators
export const setEvent = (event: Event) => ({
  type: SET_RECEIPT_EVENT,
  payload: { event },
})

export const setPrizes = (prizes: Prize[]) => ({
  type: SET_RECEIPT_PRIZES,
  payload: { prizes },
})

export const setFile = (
  fileDataURL: string,
  fileBlob: Blob | undefined,
  fileType: string,
  fileExtension: string
) => ({
  type: SET_RECEIPT_FILE,
  payload: { fileDataURL, fileBlob, fileType, fileExtension },
})

export const setIsSmartReceipt = (isSmartReceipt: boolean) => ({
  type: SET_RECEIPT_IS_SMART_RECEIPT,
  payload: { isSmartReceipt },
})

export const setTotalPrice = (totalPrice: number) => ({
  type: SET_RECEIPT_TOTAL_PRICE,
  payload: { totalPrice },
})

export const setImagePath = (userReceiptImagePath: string) => ({
  type: SET_RECEIPT_IMAGE_PATH,
  payload: { userReceiptImagePath },
})

export const setTotalPriceIsModifiedAfterOCR = (isModified?: boolean) => ({
  type: SET_RECEIPT_TOTAL_PRICE_IS_MODIFIED_AFTER_OCR,
  payload: { isModified },
})

export const setOCRData = (
  purchasedFacilityName: string,
  purchasedStoreName: string,
  purchasedDate: string,
  phoneNumber: string
) => ({
  type: SET_RECEIPT_OCR_DATA,
  payload: {
    purchasedFacilityName,
    purchasedStoreName,
    purchasedDate,
    phoneNumber,
  },
})

export const setSelectedPrize = (selectedPrize: Prize) => ({
  type: SET_RECEIPT_SELECTED_PRIZE,
  payload: { selectedPrize },
})

export const clearOCRData = () => ({
  type: CLEAR_RECEIPT_OCR_DATA,
})

export const clear = () => ({ type: CLEAR_RECEIPT })

// actions
type SetEventAction = ReturnType<typeof setEvent>
type SetPrizesAction = ReturnType<typeof setPrizes>
type SetFileAction = ReturnType<typeof setFile>
type SetIsSmartReceiptAction = ReturnType<typeof setIsSmartReceipt>
type SetTotalPriceAction = ReturnType<typeof setTotalPrice>
type SetImagePathAction = ReturnType<typeof setImagePath>
type SetTotalPriceIsModifiedAfterOCRAction = ReturnType<
  typeof setTotalPriceIsModifiedAfterOCR
>
type SetOCRDataAction = ReturnType<typeof setOCRData>
type SetSelectedPrizeAction = ReturnType<typeof setSelectedPrize>

type ClearOCRDataAction = ReturnType<typeof clearOCRData>
type ClearAction = ReturnType<typeof clear>
type Action =
  | ClearOCRDataAction
  | ClearAction
  | SetEventAction
  | SetPrizesAction
  | SetFileAction
  | SetIsSmartReceiptAction
  | SetTotalPriceAction
  | SetImagePathAction
  | SetTotalPriceIsModifiedAfterOCRAction
  | SetOCRDataAction
  | SetSelectedPrizeAction

// reducer
export const reducer = (
  state: Receipt = createInitialState(),
  action: Action
): Receipt => {
  switch (action.type) {
    case SET_RECEIPT_EVENT:
      return {
        ...state,
        event: action.payload.event,
      }
    case SET_RECEIPT_PRIZES:
      return {
        ...state,
        prizes: action.payload.prizes,
      }
    case SET_RECEIPT_FILE:
      return {
        ...state,
        fileDataURL: action.payload.fileDataURL,
        fileBlob: action.payload.fileBlob,
        fileType: action.payload.fileType,
        fileExtension: action.payload.fileExtension,
      }
    case SET_RECEIPT_IS_SMART_RECEIPT:
      return {
        ...state,
        isSmartReceipt: action.payload.isSmartReceipt,
      }
    case SET_RECEIPT_TOTAL_PRICE:
      return {
        ...state,
        totalPrice: action.payload.totalPrice,
      }
    case SET_RECEIPT_IMAGE_PATH:
      return {
        ...state,
        userReceiptImagePath: action.payload.userReceiptImagePath,
      }
    case SET_RECEIPT_TOTAL_PRICE_IS_MODIFIED_AFTER_OCR:
      return {
        ...state,
        isModified: action.payload.isModified,
      }
    case SET_RECEIPT_OCR_DATA:
      return {
        ...state,
        purchasedFacilityName: action.payload.purchasedFacilityName,
        purchasedStoreName: action.payload.purchasedStoreName,
        purchasedDate: action.payload.purchasedDate,
        phoneNumber: action.payload.phoneNumber,
      }
    case SET_RECEIPT_SELECTED_PRIZE:
      return {
        ...state,
        selectedPrize: action.payload.selectedPrize,
      }
    case CLEAR_RECEIPT_OCR_DATA:
      return {
        ...state,
        purchasedFacilityName: createInitialState().purchasedFacilityName,
        purchasedStoreName: createInitialState().purchasedStoreName,
        purchasedDate: createInitialState().purchasedDate,
        phoneNumber: createInitialState().phoneNumber,
      }
    case CLEAR_RECEIPT:
      return createInitialState()
    default:
      return state
  }
}
