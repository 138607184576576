import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modules, Usecases } from '../core'

import RegisteredCard from '../components/screens/RegisteredCard'
import RegisteredCardV2 from '../components/screens/RegisteredCardV2'

export default function RegisteredCardContainer() {
  const card = useSelector((state: Modules.AppState) => state.card)
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchRegisteredCard() {
        const reloder = () => {
          dispatch(Usecases.Card.fetchRegisteredCard(reloder))
        }
        dispatch(Modules.Card.clearCard())
        dispatch(Usecases.Card.fetchRegisteredCard(reloder))
      },
      deleteRegisteredCard(showConfirmedModal: () => void) {
        dispatch(Usecases.Card.deleteRegisteredCard(showConfirmedModal))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )

  const tenantId =
    process.env.REACT_APP_TENANT_ID !== undefined
      ? process.env.REACT_APP_TENANT_ID
      : ''

  return tenantId.includes('0101') ? (
    <RegisteredCardV2 card={card} actions={actions} />
  ) : (
    <RegisteredCard card={card} actions={actions} />
  )
}
