import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Domain } from '../../../core'
import { COLORS } from '../../../constants'
import QnA from '../../share/QnA'
import PrivacyPolicy from '../../share/PrivacyPolicy'
import ButtonArrow from '../../share/ButtonArrow'
import Template01 from './Template01'
import Template02 from './Template02'
import arrowDownIcon from '../../../assets/images/arrow_down.svg'
import arrowUpIcon from '../../../assets/images/arrow_up.svg'
import stylesModule from './styles.module.css'

const styles = StyleSheet.create({
  serviceContainer: {
    paddingHorizontal: 0,
  },
  gerneralPadding: {
    paddingHorizontal: 15,
  },
  anchorSection: {
    paddingVertical: 24,
    paddingHorizontal: 15,
    textAlign: 'center',
  },
  primaryBg: {
    backgroundColor: COLORS.PINK_A,
  },
  title: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: '600',
    color: COLORS.ORANGE,
    marginBottom: 24,
  },
  textFooterWrap: {
    marginLeft: 15,
    textAlign: 'left',
  },
  qnaText: {
    color: COLORS.ORANGE,
  },
  buttonWrap: {
    paddingHorizontal: 15,
    paddingTop: 16,
    paddingBottom: 24,
    backgroundColor: COLORS.PINK_A,
  },
  marutokuTopText: {
    marginTop: 60,
    marginBottom: 23,
    marginHorizontal: 'auto',
  },
  qnaCustomStyle: {
    marginBottom: 40,
    marginHorizontal: 'auto',
  },
})

type ServiceListItem = Domain.Service.ServiceTemplate
interface Props {
  serviceList: Domain.Service.ServiceEntity
  actions: {
    fetchServiceList: () => void
  }
}

export default function ServiceDetail(props: Props) {
  const {
    serviceList,
    actions: { fetchServiceList },
  } = props

  async function scrollToElement(id: string) {
    const element = (await document.getElementById(id)) as HTMLElement
    if (element) {
      const top = element.getBoundingClientRect().top
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }

  React.useEffect(function () {
    window.addEventListener('replacestate', function () {
      scrollToElement(`${window.location.hash.substr(1)}`)
    })
  }, [])

  const handleAnchorScroll = (scrollTarget: string) => {
    window.history.replaceState({}, '', `#${scrollTarget}`)
    window.dispatchEvent(new Event('replacestate'))
  }

  const ServiceContentRender = () => {
    return serviceList.lists.map((item, index) => {
      if (item.template === 1) {
        return <Template01 item={item} key={`service-content-${index}`} />
      } else if (item.template === 2) {
        return <Template02 item={item} key={`service-content-${index}`} />
      }
      return null
    })
  }

  React.useEffect(() => {
    fetchServiceList()
  }, [fetchServiceList])
  React.useEffect(() => {
    const hash = window.location.hash.substr(1)
    if (!hash) return
    scrollToElement(hash)
  }, [serviceList])

  const qnaTokyuSoreTitle = '制度詳細・よくある質問'
  const qnaOtherAppTitle = 'よくある質問はこちら'
  const [isTokyuStore, setIsTokyuStore] = React.useState(false)

  React.useEffect(() => {
    if (process.env.REACT_APP_TENANT_ID === '0101') {
      setIsTokyuStore(true)
    }
  }, [])

  return (
    <View style={styles.serviceContainer} nativeID="top">
      <View style={styles.gerneralPadding}>
        <img
          alt=""
          src={`${process.env.REACT_APP_FAQ_BUCKET}/tokyustore/images/service_banner01.png`}
          className={stylesModule.serviceBanner01}
        />
        <img
          alt=""
          src={`${process.env.REACT_APP_FAQ_BUCKET}/tokyustore/images/service_banner02.png`}
          className={stylesModule.serviceBanner02}
        />
      </View>

      <View style={[styles.anchorSection, styles.primaryBg]}>
        <Text style={styles.title}>{serviceList.title}</Text>
        <View>
          {serviceList.lists.length &&
            serviceList.lists.map((item) => (
              <ButtonArrow
                onPress={handleAnchorScroll}
                text={item.anchor}
                scrollTarget={item.id}
                key={item.id}
                arrowIcon={arrowDownIcon}
              />
            ))}
        </View>
      </View>

      <View style={styles.primaryBg}>{ServiceContentRender()}</View>

      <View style={styles.buttonWrap}>
        <ButtonArrow
          onPress={handleAnchorScroll}
          text="ページの先頭へ戻る"
          scrollTarget="top"
          arrowIcon={arrowUpIcon}
        />
      </View>

      {isTokyuStore && (
        <QnA
          title={'まる得 TOP'}
          textStyle={styles.qnaText}
          textFooterWrap={styles.textFooterWrap}
          customUrl={'/top-campaign'}
          customStyle={styles.marutokuTopText}
        />
      )}

      <QnA
        title={isTokyuStore ? qnaTokyuSoreTitle : qnaOtherAppTitle}
        textStyle={styles.qnaText}
        textFooterWrap={styles.textFooterWrap}
        isExternalLink={!!isTokyuStore}
        handleClick={
          isTokyuStore
            ? () => {
                window.location.reload()
              }
            : undefined
        }
        customStyle={isTokyuStore ? styles.qnaCustomStyle : undefined}
      />

      <PrivacyPolicy
        textStyle={styles.qnaText}
        textFooterWrap={styles.textFooterWrap}
      />
    </View>
  )
}
