import * as Domain from '../domain'

type CheckEvent = Domain.Event.CheckEventEntiTy

const initialState = {
  eventRegist: false
}


export function createInitialState(): CheckEvent {
  return initialState
}

export const SET_CHECK_EVENT = 'checkEvent/set' as const
export const CLEAR_CHECK_EVENT = 'checkEvent/clear' as const


export function setCheckEvent(checkEvent: CheckEvent) {
  return {
    type: SET_CHECK_EVENT,
    payload: {
      checkEvent,
    },
  }
}

export function clearCheckEvent() {
  return {
    type: CLEAR_CHECK_EVENT,
  }
}

type SetCheckEventAction = ReturnType<typeof setCheckEvent>
type ClearCheckEventAction = ReturnType<typeof clearCheckEvent>
type Action = SetCheckEventAction | ClearCheckEventAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_CHECK_EVENT:
      return action.payload.checkEvent
    case CLEAR_CHECK_EVENT:
      return initialState
    default:
      return state
  }
}
