import React from 'react'
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom'

import DebugPanel from './DebugPanel'
import CardRegistrationCompleted from './containers/CardRegistrationCompleted'
import NormalCardInput from './containers/NormalCardInput'
import RedCardInput from './containers/RedCardInput'
import GreenCardInput from './containers/GreenCardInput'
import SelectCard from './containers/SelectCard'
import RegisteredCard from './containers/RegisteredCard'
import StoreRegistrationCompleted from './components/screens/StoreRegistrationCompleted'
import AddStore from './containers/AddStore'
import PrefectureInput from './containers/PrefectureInput'
import CityInput from './containers/CityInput'
import NearbyStoreInput from './containers/NearbyStoreInput'
import NearbyStoreGPSInput from './containers/NearbyStoreGPSInput'
import PrivacyPolicy from './containers/PrivacyPolicy'
import QuestionAndAnswer from './containers/QuestionAndAnswer'
import RegisteredStoreList from './containers/RegisteredStoreList'
import RedirectRichMenu from './containers/RedirectRichMenu'
import LineAccountPartnerInfo from './components/screens/LineAccountPartnerInfo'
import Maintenance from './components/screens/Maintenance'
import EventsList from './containers/EventsList'
import EndedEventsList from './containers/EndedEventsList'
import EventDetail from './containers/EventDetail'
import ServiceDetail from './containers/ServiceDetail'
import TopCampaign from './containers/TopCampaign'
import ReceiptOCR from './containers/ReceiptOCR'
import EntryCompleted from './containers/100thEntryCompleted'
import SmartReceipt from './containers/SmartReceipt'
import Receipt from './containers/Receipt'

const needsToShowDebugPanel =
  process.env.REACT_APP_NEEDS_TO_SHOW_DEBUG_PANEL === 'true'

export default function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/select-card" element={<SelectCard />} />
      <Route path="/normal-card-input" element={<NormalCardInput />} />
      <Route path="/red-card-input" element={<RedCardInput />} />
      <Route path="/green-card-input" element={<GreenCardInput />} />
      <Route
        path="/card-registration-completed/:storeRegist"
        element={<CardRegistrationCompleted />}
      />
      <Route path="/add-store" element={<AddStore />} />
      <Route path="/prefecture-input" element={<PrefectureInput />} />
      <Route
        path="/nearby-store-input/:prefectureCd?"
        element={<NearbyStoreInput />}
      />
      <Route path="/nearby-store-gps" element={<NearbyStoreGPSInput />} />
      <Route
        path="/store-registration-completed"
        element={<StoreRegistrationCompleted />}
      />
      <Route path="/registered-store-list" element={<RegisteredStoreList />} />
      <Route path="/registered-card" element={<RegisteredCard />} />
      <Route
        path="/question-and-answer/:answerId?"
        element={<QuestionAndAnswer />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/city-input/:prefectureCd" element={<CityInput />} />
      <Route path={'/Menu/:type'} element={<RedirectRichMenu />} />
      <Route path={'/Page/:type'} element={<RedirectRichMenu />} />
      <Route
        path="/line-account-partner"
        element={<LineAccountPartnerInfo />}
      />
      <Route path="/events" element={<EventsList />} />
      <Route path="/ended-events" element={<EndedEventsList />} />
      <Route path="/events/:eventId" element={<EventDetail />} />
      <Route path="/service-detail" element={<ServiceDetail />} />
      <Route path="/maintenance" element={<Maintenance />} />
      <Route path="/top-campaign" element={<TopCampaign />} />
      <Route path="/receipt-ocr" element={<ReceiptOCR />} />
      <Route path="/entry-completed" element={<EntryCompleted />} />
      <Route path="/receipt" element={<Receipt />} />
      <Route path="/smart-receipt" element={<SmartReceipt />} />

      <Route
        path="/"
        element={needsToShowDebugPanel ? <DebugPanel /> : <></>}
      />
    </ReactRouterRoutes>
  )
}
