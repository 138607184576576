interface Args {
  gtmId: string
  events?: {}
  dataLayer?: string
  dataLayerName?: string
}

const Snippets = {
  tags: function (args: Args) {
    const { gtmId, events, dataLayer, dataLayerName } = args

    if (!gtmId) return
    
    const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  
    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(events).slice(1, -1)}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${gtmId}');`
  
    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName)
  
    return {
      iframe,
      script,
      dataLayerVar
    }
  },
  dataLayer: function (dataLayer: string | undefined, dataLayerName: string | undefined) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`
  }
}

const TagManager = {
  dataScript: function (dataLayer: string) {
    const script = document.createElement('script')
    script.innerHTML = dataLayer
    return script
  },
  gtm: function (args: Args) {
    const snippets = Snippets.tags(args)
    if (!snippets) return

    const noScript = () => {
      const noscript = document.createElement('noscript')
      noscript.innerHTML = snippets.iframe
      return noscript
    }

    const script = () => {
      const scriptEl = document.createElement('script')
      scriptEl.innerHTML = snippets.script
      return scriptEl
    }

    const dataScript = this.dataScript(snippets.dataLayerVar)

    return {
      noScript,
      script,
      dataScript
    }
  },
  initialize: function (args: Args) {
    const { gtmId, events = {}, dataLayer, dataLayerName = 'dataLayer' } = args
    const gtm = this.gtm({
      gtmId,
      events: events,
      dataLayer: dataLayer || undefined,
      dataLayerName: dataLayerName
    })
    if (!gtm) return
    if (dataLayer) document.head.appendChild(gtm.dataScript)
    document.head.insertBefore(gtm.script(), document.head.childNodes[0])
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
  }
}

export default TagManager
