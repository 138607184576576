import * as React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import Store from './Store'
import { Domain } from '../../../core'
import Modal from '../../share/Modal'
import { STYLES } from '../../../constants'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const MODAL_TXT = '{value0}を解除しますか？'
const PAGE_SUB_TITLE = '{value0}登録されています'
const TENANT_ID = process.env.REACT_APP_TENANT_ID

const styles = StyleSheet.create({
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
  },
  modalButtons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButtonLabel: {
    fontSize: 17,
    fontWeight: 'bold',
  },
})

interface Props {
  tenant: Domain.Tenant.Entity
  stores: Array<Domain.Store.Entity>
  actions: {
    fetchRegisteredStore: () => void
    deleteRegisteredStore: (storeCd: string) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

export default function RegisteredStoreList(props: Props) {
  const {
    tenant,
    stores,
    actions: { fetchRegisteredStore, deleteRegisteredStore, clearScreenEmbeddedErrorAlert },
  } = props
  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  React.useEffect(() => fetchRegisteredStore(), [fetchRegisteredStore])
  const [deleteStoreCd, setDeleteStoreCd] = React.useState('')

  const [needsToShowConfirmationModal, setNeedsToShowConfirmationModal] = React.useState(false)

  const handleDeleteStore = (cd: string) => {
    setDeleteStoreCd(cd)
    setNeedsToShowConfirmationModal(true)
  }

  const storesList = []
  for (let index = 0; index <= 2; index++) {
    storesList.push(
      <Store tenant={tenant} key={index} store={stores[index]} no={index + 1} onDeletePress={handleDeleteStore} />,
    )
  }

  const onPressConfirmationModalOK = React.useCallback(() => {
    setNeedsToShowConfirmationModal(false)
    deleteRegisteredStore(deleteStoreCd)
  }, [deleteRegisteredStore, deleteStoreCd])
  const onPressConfirmationModalCancel = React.useCallback(() => setNeedsToShowConfirmationModal(false), [])

  return (
    <View>
      <Modal needsToShow={needsToShowConfirmationModal} text={handleStoreGenreTitle(MODAL_TXT, tenant.category)}>
        <View style={styles.modalButtons}>
          <TouchableOpacity onPress={onPressConfirmationModalCancel}>
            <Text style={styles.modalButtonLabel}>キャンセル</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onPressConfirmationModalOK}>
            <Text style={styles.modalButtonLabel}>削除する</Text>
          </TouchableOpacity>
        </View>
      </Modal>

      <View style={STYLES.generalStyle.container}>
        <View>
          <PageTitle title={tenant.text.titleRegisteredStoreList} />
          <Text style={styles.subtitle}>
            {TENANT_ID?.includes('0302')
              ? `現在、${stores.length}つ登録されています。`
              : `現在 ${stores.length} ${handleStoreGenreTitle(PAGE_SUB_TITLE, tenant.category)}`}
          </Text>
          {storesList}
        </View>
        <QnA />
      </View>
    </View>
  )
}
