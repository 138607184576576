import * as Domain from '../domain'

type Prefectures = Array<Domain.Prefecture.Entity>

export function createInitialState(): Prefectures {
  return []
}

export const SET_PREFECTURES = 'prectures/set' as const
export const CLEAR_PREFECTURES = 'prectures/clear' as const

export function setPrefectures(prefectures: Prefectures) {
  return {
    type: SET_PREFECTURES,
    payload: {
      prefectures,
    },
  }
}

export function clearPrefectures() {
  return {
    type: CLEAR_PREFECTURES,
  }
}

type SetPrefecturesAction = ReturnType<typeof setPrefectures>
type ClearPrefecturesAction = ReturnType<typeof clearPrefectures>
type Action = ClearPrefecturesAction | SetPrefecturesAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PREFECTURES:
      return action.payload.prefectures
    case CLEAR_PREFECTURES:
      return []
    default:
      return state
  }
}
