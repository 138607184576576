import * as React from 'react'
import { StyleSheet, TouchableOpacity, Linking, Text } from 'react-native'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  footer: {
    marginBottom: 30,
    marginTop: -20,
    marginHorizontal: 'auto',
  },
  linkAction: {
    width: 'auto',
    marginRight: 'auto',
  },
  link: {
    textDecorationLine: 'underline',
    color: COLORS.BLUE,
    width: '100%',
  },
  content: {
    letterSpacing: 0.3,
    lineHeight: 26,
  },
})
interface Props {
  textStyle?: object
  textFooterWrap?: object
}

export default function PrivacyPolicy(props: Props) {
  const { textStyle, textFooterWrap } = props
  const onPress = React.useCallback(() => {
    // @ts-ignore
    Linking.openURL(process.env.REACT_APP_POLICY_LINK as string, '_self')
  }, [])

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.linkAction, styles.footer, textFooterWrap]}
    >
      <Text style={[styles.content, styles.link, textStyle]}>
        個人情報取扱い方針
      </Text>
    </TouchableOpacity>
  )
}
