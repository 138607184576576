import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { Domain } from '../../../core'
import QnA from '../../share/QnA'
import PrivacyPolicy from '../../share/PrivacyPolicy'
import Event from './Event'
import InfiniteScroll from 'react-infinite-scroller'
import { ActivityIndicator } from 'react-native'
import { COLORS, STYLES } from '../../../constants'

const styles = StyleSheet.create({
  loadingIcon: {
    marginVertical: 15,
  },
})

type ListType = Domain.Event.ListEntity
type PaginationObjectType = {
  length: number
  numberItemsPagination: number
}
interface Props {
  eventList: Array<ListType>
  currentDate: string
  actions: {
    fetchEventsList: () => void
  }
}

export default function EventsList(props: Props) {
  const {
    eventList,
    currentDate,
    actions: { fetchEventsList },
  } = props

  const navigate = useNavigate()

  const goToDetail = React.useCallback(
    (eventId: string, replaceHistory = false) => {
      const location = {
        pathname: `/events/${eventId}`,
      }
      if (replaceHistory) {
        navigate(location, { replace: true })
      } else {
        const locationHasSearch = { ...location, search: 'back=true' }
        navigate(locationHasSearch)
      }
    },
    [navigate]
  )

  const initialCount = 10
  const [activeEvents, setActiveEvents] = React.useState<Array<ListType>>([])
  const [deactiveEvents, setDeactiveEvents] = React.useState<Array<ListType>>(
    []
  )
  const [numberItemsPaginationActive, setNumberItemsPaginationActive] =
    React.useState(initialCount)
  const [numberItemsPaginationDeactive, setNumberItemsPaginationDeactive] =
    React.useState(initialCount)
  const [isLoadMoreActiveEvent, setIsLoadMoreActiveEvent] = React.useState(true)
  const [isHasMore, setIsHasMore] = React.useState(false)

  // Fix white page is backed from event detail
  React.useEffect(() => {
    window.scrollTo(0, 1)
  }, [eventList])

  React.useEffect(() => {
    fetchEventsList()
  }, [fetchEventsList])

  function sortEvent(arr: Array<ListType>) {
    return arr.sort((a: ListType, b: ListType) => {
      return Date.parse(b.startDate) - Date.parse(a.startDate)
    })
  }

  React.useEffect(() => {
    const activeEventsTemp = eventList.filter((event: ListType) => {
      return (
        Date.parse(event.startDate) <= Date.parse(currentDate) &&
        Date.parse(currentDate) <= Date.parse(event.endDate)
      )
    })
    setActiveEvents(sortEvent(activeEventsTemp))
    if (activeEventsTemp.length > initialCount) {
      setIsHasMore(true)
    }
  }, [eventList, currentDate, setActiveEvents])

  React.useEffect(() => {
    const deactiveEventsTemp = eventList.filter((event: ListType) => {
      return (
        Date.parse(event.endDate) <= Date.parse(currentDate) &&
        Date.parse(currentDate) <= Date.parse(event.displayEndDate)
      )
    })
    setDeactiveEvents(sortEvent(deactiveEventsTemp))
    if (deactiveEventsTemp.length > initialCount) {
      setIsHasMore(true)
    }
  }, [eventList, currentDate, setDeactiveEvents])

  const loadMoreItems = (arg: PaginationObjectType) => {
    if (window.scrollY !== 0) {
      let numberItems
      if (arg.numberItemsPagination + initialCount < arg.length) {
        numberItems = arg.numberItemsPagination + initialCount
      } else {
        numberItems = arg.length
        setIsLoadMoreActiveEvent(false)
      }
      if (isLoadMoreActiveEvent) {
        setNumberItemsPaginationActive(numberItems)
      } else {
        setNumberItemsPaginationDeactive(numberItems)
        if (arg.numberItemsPagination + initialCount >= arg.length) {
          setIsHasMore(false)
        }
      }
    }
  }

  const paginationObject: PaginationObjectType = {
    length: isLoadMoreActiveEvent ? activeEvents.length : deactiveEvents.length,
    numberItemsPagination: isLoadMoreActiveEvent
      ? numberItemsPaginationActive
      : numberItemsPaginationDeactive,
  }

  return (
    <View style={STYLES.generalStyle.container}>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => loadMoreItems(paginationObject)}
        hasMore={isHasMore}
        loader={
          <ActivityIndicator
            key={0}
            animating
            color={COLORS.GRAY_C}
            size={30}
            style={styles.loadingIcon}
          />
        }
        initialLoad={false}
      >
        <Event
          list={activeEvents.slice(0, numberItemsPaginationActive)}
          title={'開催中のキャンペーン'}
          onPress={goToDetail}
          isShowEmpty={!activeEvents.length}
        />
        <Event
          list={deactiveEvents.slice(0, numberItemsPaginationDeactive)}
          title={'終了したキャンペーン'}
          onPress={goToDetail}
          isDeactiveEvent={true}
        />
      </InfiniteScroll>
      <QnA />
      <PrivacyPolicy />
    </View>
  )
}
