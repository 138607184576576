import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modules, Usecases } from '../core'

import PrefectureInput from '../components/screens/PrefectureInput'

export default function RegisteredPrefectureListContainer() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const prefectures = useSelector(
    (state: Modules.AppState) => state.prefectures
  )
  const dispatch = useDispatch<any>()
  const actions = React.useMemo(
    () => ({
      fetchPrefectures() {
        const reloader = () => {
          dispatch(Usecases.Prefectures.fetchPrefectures(reloader))
        }

        dispatch(Modules.Prefectures.clearPrefectures())
        dispatch(Usecases.Prefectures.fetchPrefectures(reloader))
      },
      clearScreenEmbeddedErrorAlert() {
        dispatch(Modules.ErrorModule.clearError())
      },
    }),
    [dispatch]
  )

  return (
    <PrefectureInput
      tenant={tenant}
      prefectures={prefectures}
      actions={actions}
    />
  )
}
