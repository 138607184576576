/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { shallowEqual, useSelector } from 'react-redux'
import { COLORS } from '../../../constants'
import { Modules } from '../../../core'
import ButtonSubmit from '../../share/ButtonSubmit'
import stylesModule from './styles.module.css'

interface Props {
  close: () => void
}

const styles = StyleSheet.create({
  modalContainer: {
    position: 'absolute',
    top: 0,
    end: 0,
    start: 0,
    bottom: 0,
    backgroundColor: '#0000004D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 16,
    zIndex: 30,
  },
  modal: {
    width: '100%',
    height: '100%',
    borderRadius: 6,
    backgroundColor: COLORS.WHITE,
    overflow: 'scroll',
  },
  messageTitle: {
    paddingTop: 24,
    paddingBottom: 16,
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.ORANGE,
    textAlign: 'center',
  },
  messageBody: {
    padding: 16,
    fontSize: 18,
    fontWeight: '300',
    letterSpacing: -0.36,
    lineHeight: 22,
    color: COLORS.GRAY_H,
  },
  prizeContainer: {
    paddingTop: 20,
    paddingBottom: 16,
    paddingHorizontal: 16,
    backgroundColor: COLORS.PINK_A,
  },
  prizeTitle: {
    fontSize: 22,
    fontWeight: '600',
    lineHeight: 24,
    color: COLORS.TOKYU_BLACK,
  },
  prizeDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  prizeImageContainer: {
    marginTop: 8,
  },
  bottomContainer: {
    paddingTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 16,
  },
  closeButton: {
    borderRadius: 24,
    backgroundColor: COLORS.WHITE,
    borderWidth: 1,
    borderColor: COLORS.ORANGE,
    height: 48,
    marginTop: 0,
  },
  closeButtonText: {
    color: COLORS.ORANGE,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
  },
})

export default function EntryCompleted(props: Props) {
  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )
  const { selectedPrize } = receipt
  const { close } = props

  return (
    <View style={styles.modalContainer}>
      <View style={styles.modal}>
        <Text style={styles.messageTitle}>
          ご応募ありがとう{'\n'}ございました
        </Text>
        <img
          src={receipt.event?.entriedImageUrl}
          alt=""
          className={stylesModule.video}
        />
        <Text style={styles.messageBody}>
          以下の賞品への応募を受け付けました。{'\n'}
          応募内容をメッセージでお送りしましたのでご確認ください。{'\n\n'}
          当選者の発表は、当選者にのみ2022年10月31日までに、このLINE公式アカウントからご連絡します。ブロックすると当選通知が届きませんので、ブロックしないようお願いします。
        </Text>
        <View style={styles.prizeContainer}>
          <Text style={styles.prizeTitle}>{selectedPrize?.title}</Text>
          <Text style={styles.prizeDescription}>
            {selectedPrize?.description}
          </Text>
          <View style={styles.prizeImageContainer}>
            <img
              alt=""
              src={selectedPrize?.image}
              className={stylesModule.prizeImage}
            />
          </View>
        </View>
        <View style={styles.bottomContainer}>
          <ButtonSubmit
            disabled={false}
            onPress={close}
            text="閉じる"
            buttonStyle={styles.closeButton}
            textStyle={styles.closeButtonText}
          />
        </View>
      </View>
    </View>
  )
}
