import * as React from 'react'
import { StyleSheet, TouchableOpacity, Text, Image } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 15,
    minHeight: 80,
    position: 'relative',
    justifyContent: 'center',
    marginBottom: 15,
  },
  title: {
    fontSize: 16,
    alignSelf: 'center',
    textAlign: 'center',
    width: '80%',
  },
  arrow: {
    width: 13,
    resizeMode: 'contain',
    paddingBottom: 20,
    position: 'absolute',
    right: 15,
    top: '37%',
  },
})
interface Props {
  title: string
  onPress: () => void
}
export default function Card(props: Props) {
  const { title, onPress } = props

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.title}>{title}</Text>
      <Image source={require('../../../assets/images/next_icon.png')} style={styles.arrow} />
    </TouchableOpacity>
  )
}
