import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import Collapsible from 'react-collapsible'
import { Domain } from '../../../core'
import { COLORS, FONTS } from '../../../constants'
import stylesModule from './styles.module.css'
import arrowDownIcon from '../../../assets/images/arrow_down.svg'

const styles = StyleSheet.create({
  gerneralPadding: {
    paddingHorizontal: 15,
  },
  title: {
    fontSize: 22,
    fontWeight: '600',
    lineHeight: 28,
    color: COLORS.ORANGE,
    textAlign: 'center',
  },
  subTitle: {
    fontSize: FONTS.SIZE.M,
    fontWeight: '600',
    color: COLORS.BLACK,
    paddingVertical: 16,
  },
  qnaItem: {
    borderBottomWidth: 1,
    borderColor: COLORS.PINK_A,
    paddingBottom: 15,
    backgroundColor: COLORS.WHITE,
  },
})

interface Props {
  item: Domain.Service.ServiceTemplate
}
interface CollapsibleProps {
  text: string
}

function CollapsibleTrigger(props: CollapsibleProps) {
  const { text } = props
  return (
    <div className={stylesModule.collapsible}>
      <span>{text}</span>
      <img src={arrowDownIcon} alt="" />
    </div>
  )
}

export default function Template(props: Props) {
  const { item } = props

  const ContentListRender = (
    contents: Array<Domain.Service.ServiceContentItem>
  ) => {
    return contents.map(
      (itemContent: Domain.Service.ServiceContentItem, index: number) => {
        return (
          <View
            style={[styles.qnaItem, styles.gerneralPadding]}
            key={`content-list-${index}`}
          >
            <Collapsible
              className={stylesModule.serviceTitleText}
              openedClassName={stylesModule.serviceTitleText}
              trigger={<CollapsibleTrigger text={itemContent.title} />}
            >
              <div
                className={stylesModule.serviceDescription}
                dangerouslySetInnerHTML={{ __html: itemContent.description }}
              />
            </Collapsible>
          </View>
        )
      }
    )
  }

  return (
    <View nativeID={item.id}>
      <div id="faq" />
      <Text style={styles.title}>{item.anchor}</Text>
      <View>
        {item.items &&
          item.items.length > 0 &&
          item.items.map(
            (itemChild: Domain.Service.ServiceItemList, index: number) => {
              return (
                <View key={index}>
                  <Text style={[styles.gerneralPadding, styles.subTitle]}>
                    {itemChild.title}
                  </Text>
                  {ContentListRender(itemChild.contents)}
                </View>
              )
            }
          )}
      </View>
    </View>
  )
}
