import { Dispatch } from 'redux'
import * as Domain from '../domain'
import { Api } from '../../lib'
import {
  FetchReceiptUploadURLResponseEntity,
  OCRPaperReceiptRequestEntity,
  OCRPaperReceiptResponseEntity,
  OCRPaperReceiptResponsePaymentInfoEntity,
  OCRSmartReceiptRequestEntity,
  OCRSmartReceiptResponseEntity,
  PrizeEntity,
  UploadReceiptImageRequestEntity,
  UploadReceiptImageResponseEntity,
  ValidateReceiptRequestEntity,
} from '../domain/event'
import { beginCommunication, endCommunication } from '../modules/network'
import { Modules } from '..'
import { setError } from '../modules/error'
import {
  errorObject,
  SYSTEM_ERROR_MESSAGE,
  validateOnLine,
} from '../../lib/api'

interface Data {
  eventsList: Array<Domain.Event.ListEntity>
  currentDate: string
}
type DetailEntity = Domain.Event.DetailEntity
type CheckEventEntiTy = Domain.Event.CheckEventEntiTy

export function fetchEventsList(
  reloader: () => void,
  didCallFetchEventsListApi: (data: Data) => void,
  params = ''
) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      'events',
      params,
      null,
      didCallFetchEventsListApi,
      dispatch,
      options
    )
  }
}

export function fetchEvent(
  reloader: () => void,
  eventId: string | undefined,
  didCallFetchEventApi: (data: DetailEntity) => void
) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      `events/${eventId}`,
      '',
      null,
      didCallFetchEventApi,
      dispatch,
      options
    )
  }
}

export function fetchEventEntryStatus(
  reloader: () => void,
  eventId: string | undefined,
  didCallFetchCheckEvent: (data: CheckEventEntiTy) => void
) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      `user-entry/status`,
      `&eventId=${eventId}`,
      null,
      didCallFetchCheckEvent,
      dispatch,
      options
    )
  }
}

export function registerEvent(
  body: Domain.Event.PostEventEntity,
  didCallPostEventApi: () => void
) {
  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.post,
      'user-entry',
      '',
      JSON.stringify(body),
      didCallPostEventApi,
      dispatch
    )
  }
}

export function entryEvent(
  body: Domain.Event.UserEntryMultipleRequestEntity,
  didCallUserEntryMultipleApi: () => void
) {
  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.post,
      'user-entry-multiple',
      '',
      JSON.stringify(body),
      didCallUserEntryMultipleApi,
      dispatch
    )
  }
}

export function fetchEventPrizes(
  reloader: () => void,
  eventId: string,
  didCallFetchEventPrizesApi: (data: PrizeEntity[]) => void
) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      `events/${eventId}/prizes`,
      '',
      null,
      didCallFetchEventPrizesApi,
      dispatch,
      options
    )
  }
}

export function uploadReceipt(
  eventId: string,
  body: UploadReceiptImageRequestEntity,
  didCallUploadReceiptApi: (data: UploadReceiptImageResponseEntity) => void
) {
  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.post,
      'user-entry/receipt',
      `&eventId=${eventId}`,
      JSON.stringify(body),
      didCallUploadReceiptApi,
      dispatch
    )
  }
}

export function uploadImageForSmartReceiptOCR(
  body: OCRSmartReceiptRequestEntity,
  didCallOCRSmartReceiptApi: (isSuccess: boolean, totalPrice?: number) => void
) {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(Modules.Receipt.clearOCRData())
      dispatch(beginCommunication())

      const headers = {
        Authorization: Domain.Auth.getAccessToken(),
        'Content-Type': 'application/json',
      }

      const response = await fetch(
        `${process.env.REACT_APP_TOKYU_API_ENDPOINT}/ocr/smart-receipt`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(body),
        }
      )

      if (response.status === 200) {
        const json: OCRSmartReceiptResponseEntity = await response.json()

        // Redux stateに読み取った情報を保存
        dispatch(
          Modules.Receipt.setOCRData(
            json.purchasedFacilityName,
            json.purchasedStoreName,
            json.purchasedDate,
            json.phoneNumber
          )
        )

        if (json.receiptAmount !== 0) {
          didCallOCRSmartReceiptApi(true, json.receiptAmount)
        } else {
          throw new Error(response.statusText)
        }
      } else {
        throw new Error(response.statusText)
      }
    } catch (_) {
      didCallOCRSmartReceiptApi(false)
    } finally {
      dispatch(endCommunication())
    }
  }
}

// TODO: 使わないようであれば削除
// const buildPurchasedFacilityName = (
//   storeInfo: OCRPaperReceiptResponseStoreInfoEntity
// ) => {
//   const { complexMallName, name, branch } = storeInfo
//   let purchasedFacilityName = ''

//   if (complexMallName != null && complexMallName.length > 0) {
//     purchasedFacilityName += complexMallName
//   }
//   if (name != null && name.length > 0) {
//     if (purchasedFacilityName.length > 0) purchasedFacilityName += ' '
//     purchasedFacilityName += name
//   }
//   if (branch != null && branch.length > 0) {
//     if (purchasedFacilityName.length > 0) purchasedFacilityName += ' '
//     purchasedFacilityName += branch
//   }
//   return purchasedFacilityName
// }

const buildPurchasedDate = (
  paymentInfo?: OCRPaperReceiptResponsePaymentInfoEntity
) => {
  if (paymentInfo == null) return ''
  const { date, time } = paymentInfo

  if (date != null && date.length > 0 && time != null && time.length > 0) {
    const dateTimeString = `${date}T${time}+09:00`
    return new Date(dateTimeString).toISOString()
  } else {
    return ''
  }
}

export function uploadImageForPaperReceiptOCR(
  body: OCRPaperReceiptRequestEntity,
  didCallOCRPaperReceiptApi: (isSuccess: boolean, totalPrice?: number) => void
) {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(Modules.Receipt.clearOCRData())
      dispatch(beginCommunication())

      const headers = {
        'Content-Type': 'application/json',
        'x-linebrain-apigw-api-key': `${process.env.REACT_APP_CLOVA_OCR_API_KEY}`,
      }

      const response = await fetch(
        `${process.env.REACT_APP_CLOVA_OCR_API_ENDPOINT}`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(body),
        }
      )

      if (response.status === 200) {
        const json: OCRPaperReceiptResponseEntity = await response.json()

        // Redux stateに読み取った情報を保存
        // TODO: 以下のフォーマット確認
        // ・purchasedFacilityName
        // ・purchasedStoreName
        // ・purchasedDate
        dispatch(
          Modules.Receipt.setOCRData(
            json.result?.storeInfo?.name ?? '',
            json.result?.storeInfo?.branch ?? '',
            buildPurchasedDate(json.result?.paymentInfo),
            json.result?.storeInfo?.tel ?? ''
          )
        )

        const totalPrice = json.result?.totalPrice?.price
        if (totalPrice != null) {
          didCallOCRPaperReceiptApi(true, totalPrice)
        } else {
          throw new Error(response.statusText)
        }
      } else {
        throw new Error(response.statusText)
      }
    } catch (_) {
      didCallOCRPaperReceiptApi(false)
    } finally {
      dispatch(endCommunication())
    }
  }
}

export function fetchReceiptUploadURL(
  eventId: string,
  fileExtension: string,
  didCallFetchReceiptUploadURLApi: (
    data: FetchReceiptUploadURLResponseEntity
  ) => void
) {
  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      'user-entry/receipt-upload-url',
      `&eventId=${eventId}&extension=${fileExtension}`,
      null,
      didCallFetchReceiptUploadURLApi,
      dispatch
    )
  }
}

export function uploadImage(
  url: string,
  fileBlob: Blob,
  fileType: string,
  userReceiptImagePath: string,
  didUploadImage: (userReceiptImagePath: string) => void
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(beginCommunication())

      const headers = { 'Content-Type': fileType }

      const response = await fetch(url, {
        method: 'PUT',
        headers,
        body: fileBlob,
      })

      if (response.ok) {
        didUploadImage(userReceiptImagePath)
      } else {
        throw new Error(response.statusText)
      }
    } catch (e) {
      if (!validateOnLine(dispatch)) {
        return
      }
      dispatch(setError({ ...errorObject, errorMessage: SYSTEM_ERROR_MESSAGE }))
    } finally {
      dispatch(endCommunication())
    }
  }
}

export function validateReceipt(
  body: ValidateReceiptRequestEntity,
  didCallValidateReceiptApi: () => void
) {
  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.post,
      'user-entry/receipt/validate',
      '',
      JSON.stringify(body),
      didCallValidateReceiptApi,
      dispatch
    )
  }
}
