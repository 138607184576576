import * as React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { CardType } from '../../../constants/card-types'
import { Domain, Modules } from '../../../core'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import Button from './Button'
import Link from '../../share/Link'
import Modal from '../../share/Modal'
import { STYLES } from '../../../constants'
import { useSelector } from 'react-redux'
const { liff } = window

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 16,
    marginBottom: 15,
  },
  cardInforGroup: {
    marginBottom: 25,
  },
  cardTitle: {
    borderBottomWidth: 1,
    paddingBottom: 5,
  },
  cardDetail: {
    fontSize: 20,
    marginTop: 10,
  },
  notice: {
    marginTop: 10,
    textAlign: 'center',
  },
  cancel: {
    marginTop: 20,
    marginHorizontal: 'auto',
  },
  modalButtons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButton: {
    margin: 'auto',
  },
  modalButtonLabel: {
    fontSize: 17,
    fontWeight: 'bold',
    margin: 'auto',
  },
})

interface Props {
  card: Domain.Card.Entity | null
  actions: {
    fetchRegisteredCard: () => void
    deleteRegisteredCard: (showConfirmedModal: () => void) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

const isDevelopment = process.env.NODE_ENV === 'development'

function getCardName(cardCd?: string): string {
  switch (cardCd) {
    case CardType.tokyuCard:
      return 'TOKYU CARD'
    case CardType.tokyuPointCardRed:
      return 'TOKYU POINT CARD（赤）'
    case CardType.tokyuPointCardGreen:
      return 'TOP&現金専用カード（緑）'
    default:
      return ''
  }
}

function getCardTitle(cardCd?: string): string {
  switch (cardCd) {
    case CardType.tokyuCard:
      return 'クレジットカード会員'
    case CardType.tokyuPointCardRed:
      return 'TOKYU POINT CARD（赤）'
    case CardType.tokyuPointCardGreen:
      return 'TOP&現金専用カード（緑）'
    default:
      return ''
  }
}

export default function CardRegistrationCompleted(props: Props) {
  const {
    card,
    actions: {
      fetchRegisteredCard,
      deleteRegisteredCard,
      clearScreenEmbeddedErrorAlert,
    },
  } = props
  const tenant = useSelector((state: Modules.AppState) => state.tenant)

  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  // Confirmation Modal
  const [needsToShowConfirmationModal, setNeedsToShowConfirmationModal] =
    React.useState(false)
  const [needsToShowConfirmedModal, setNeedsToShowConfirmedModal] =
    React.useState(false)

  const onPressDelete = React.useCallback(
    () => setNeedsToShowConfirmationModal(true),
    []
  )

  const onPressConfirmationModalOK = React.useCallback(() => {
    setNeedsToShowConfirmationModal(false)
    deleteRegisteredCard(() => setNeedsToShowConfirmedModal(true))
  }, [deleteRegisteredCard])

  const onPressConfirmationModalCancel = React.useCallback(
    () => setNeedsToShowConfirmationModal(false),
    []
  )

  const onPressConfirmedModallOK = React.useCallback(() => {
    if (isDevelopment) {
      // liff.closeWindow() doesn't work in develop(localhost) env
      setNeedsToShowConfirmedModal(false)
    } else {
      liff.closeWindow()
    }
  }, [])

  // TODO: connect API after API is available
  React.useEffect(() => fetchRegisteredCard(), [fetchRegisteredCard])

  const cardName = getCardName(card?.cardCd)
  const cardTitle = getCardTitle(card?.cardCd)

  return (
    <View>
      <Modal
        needsToShow={needsToShowConfirmationModal}
        title="登録中のカードを解除します"
        text={tenant.confirmDeleteCardText}
      >
        <View style={styles.modalButtons}>
          <TouchableOpacity onPress={onPressConfirmationModalCancel}>
            <Text style={styles.modalButtonLabel}>キャンセル</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onPressConfirmationModalOK}>
            <Text style={styles.modalButtonLabel}>解除する</Text>
          </TouchableOpacity>
        </View>
      </Modal>

      <Modal
        needsToShow={needsToShowConfirmedModal}
        text="カードが解除されました"
      >
        <TouchableOpacity
          onPress={onPressConfirmedModallOK}
          style={styles.modalButton}
        >
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>

      <View style={STYLES.generalStyle.container}>
        <View>
          <PageTitle title="ご登録中のカード" />
          <Text style={styles.subtitle}>登録中のカード情報を変更します</Text>

          <View style={styles.cardInforGroup}>
            <Text style={styles.cardTitle}>券種</Text>
            <Text style={styles.cardDetail}>{cardName}</Text>
          </View>
          <View style={styles.cardInforGroup}>
            <Text style={styles.cardTitle}>{cardTitle}</Text>
            <Text style={styles.cardDetail}>{card?.pointNo}</Text>
          </View>
          <Text style={styles.notice}>解除後にまた登録することができます</Text>
          <Button />
          <Link
            propsStyle={styles.cancel}
            title="解除する ＞"
            onPress={onPressDelete}
          />
        </View>
        <QnA />
      </View>
    </View>
  )
}
