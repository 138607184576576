import * as React from 'react'
import { ActivityIndicator, StyleSheet, Text } from 'react-native'
import styles from './LoadingPanel.module.css'
import { COLORS } from '../constants'
import { tenantIs100t } from '../lib/api'

interface Props {
  needsToShow: boolean
}

const styleSheetStyles = StyleSheet.create({
  text: {
    marginTop: 16,
    marginHorizontal: 16,
    fontSize: 18,
    textAlign: 'center',
    color: COLORS.WHITE,
  },
})

export default function LoadingPanel(props: Props) {
  if (!props.needsToShow) {
    return null
  }

  return (
    <div className={styles.container}>
      <ActivityIndicator animating color={COLORS.WHITE} size="large" />
      {tenantIs100t() && (
        <Text style={styleSheetStyles.text}>
          処理中{'\n'}15〜20秒ほど時間が掛かることがあります
        </Text>
      )}
    </div>
  )
}
