import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS } from '../../../../constants'
import SharedLink from '../../../share/Link'

interface Props {
  checked: boolean
  onChange: () => void
  onPressTermsOfCampaignLink: () => void
}

const styles = StyleSheet.create({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxNormal: {
    width: 15,
    height: 15,
    marginEnd: 20,
    borderRadius: 2,
    borderColor: COLORS.ORANGE,
    borderWidth: 2,
    backgroundColor: COLORS.WHITE,
  },
  checkboxIsSelected: {
    backgroundColor: COLORS.ORANGE,
  },
  checkboxText: {
    marginStart: 0,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 15,
    letterSpacing: -0.29,
    color: COLORS.TOKYU_BLACK,
  },
  checkboxLinkText: {
    color: COLORS.ORANGE,
  },
  checkmark: {
    borderColor: COLORS.WHITE,
    borderEndWidth: 2,
    borderBottomWidth: 2,
    top: '-10%',
    left: '25%',
    transform: [{ rotate: '45deg' }],
    width: 5,
    height: 10,
  },
})

export default function TermsOfCampaignCheckbox(props: Props) {
  const { checked, onChange, onPressTermsOfCampaignLink } = props
  return (
    <TouchableOpacity style={styles.checkboxContainer} onPress={onChange}>
      <View
        style={
          checked
            ? [styles.checkboxNormal, styles.checkboxIsSelected]
            : styles.checkboxNormal
        }
      >
        {checked && <View style={styles.checkmark} />}
      </View>
      <SharedLink
        title="キャンペーン規約"
        textStyle={[styles.checkboxText, styles.checkboxLinkText]}
        onPress={onPressTermsOfCampaignLink}
      />
      <Text style={styles.checkboxText}>に同意</Text>
    </TouchableOpacity>
  )
}
