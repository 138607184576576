import { Dispatch } from 'redux'
import { Prefecture } from '../domain'
import { Api } from '../../lib'
import { setPrefectures } from '../modules/prefectures'
interface Data {
  allPrefectures: Array<Prefecture.Entity>
}

// ----- GET /prefectures API -----
function didCallFetchPrefecturesApi(data: Data, dispatch: Dispatch) {
  dispatch(setPrefectures(data.allPrefectures))
}

export function fetchPrefectures (reloader: () => void) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'prefectures', '', null, didCallFetchPrefecturesApi, dispatch, options)
  }
}
