import * as React from 'react'
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native'
import { useNavigate } from 'react-router-dom'
import Card from './Card'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import { STYLES } from '../../../constants'
import Modal from '../../share/Modal'
import LoadingPanel from '../../LoadingPanel'
import { Domain } from '../../../core'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const PAGE_TITLE = 'お近くの{value0}を探す'
const CARD_TITLE = '現在地に近い{value0}を探す'

const styles = StyleSheet.create({
  modalAction: {
    margin: 'auto',
  },
  modalButtonLabel: {
    fontSize: 17,
    fontWeight: 'bold',
  },
})
interface Props {
  tenant: Domain.Tenant.Entity
  actions: {
    registerLocation: (location: Domain.Location.Entity) => void
  }
}

export default function AddStore(props: Props) {
  const {
    tenant,
    actions: { registerLocation },
  } = props

  const navigate = useNavigate()

  const navigateToPrefectureInput = React.useCallback(
    () => navigate('/prefecture-input'),
    [navigate]
  )
  const handleOnPrefectureInput = React.useCallback(() => {
    navigateToPrefectureInput()
  }, [navigate])

  // Disabled GPS Modal
  const [needToShowPermissionDeniedModal, setNeedToShowPermissionDeniedModal] =
    React.useState(false)
  // Geolocation Error Modal
  const [needToShowErrModal, setNeedToShowErrModal] = React.useState(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const confirmPermissionDeniedModal = React.useCallback(() => {
    setNeedToShowPermissionDeniedModal(false)
  }, [])

  const confirmErrModal = React.useCallback(() => {
    setNeedToShowErrModal(false)
  }, [])

  const navigateToNearbyStoreGPS = React.useCallback(
    () => navigate('/nearby-store-gps'),
    [navigate]
  )
  const handleGPS = React.useCallback(() => {
    function success(position: { coords: Domain.Location.Entity }) {
      registerLocation(position.coords)
      setIsLoading(false)
      navigateToNearbyStoreGPS()
    }

    function error(err: { code: number; message: string }) {
      setIsLoading(false)
      // GPS Permission Denied
      if (err.code === 1) {
        setNeedToShowPermissionDeniedModal(true)
      } else {
        setNeedToShowErrModal(true)
      }
    }

    if (navigator.geolocation) {
      setIsLoading(true)
      navigator.geolocation.getCurrentPosition(success, error, {
        timeout: 10000,
        maximumAge: 0,
      })
    } else {
      setIsLoading(false)
      setNeedToShowErrModal(true)
    }
  }, [navigateToNearbyStoreGPS, registerLocation])

  return (
    <View>
      <Modal
        needsToShow={needToShowPermissionDeniedModal}
        text="GPS設定がオフになっています。設定を確認してください。"
      >
        <TouchableOpacity
          onPress={confirmPermissionDeniedModal}
          style={styles.modalAction}
        >
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>
      <Modal
        needsToShow={needToShowErrModal}
        text="現在地が取得できません。時間をおいて再度お試しください。"
      >
        <TouchableOpacity onPress={confirmErrModal} style={styles.modalAction}>
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>
      {isLoading ? (
        <LoadingPanel needsToShow={true} />
      ) : (
        <View style={STYLES.generalStyle.subContainer}>
          <View>
            <PageTitle
              title={handleStoreGenreTitle(PAGE_TITLE, tenant.category)}
            />
            {tenant.isNearestStores && (
              <Card
                title={handleStoreGenreTitle(CARD_TITLE, tenant.category)}
                onPress={handleGPS}
              />
            )}
            {tenant.isNearbyStores && (
              <Card
                title="都道府県から探す"
                onPress={handleOnPrefectureInput}
              />
            )}
          </View>
          <QnA />
        </View>
      )}
    </View>
  )
}
