import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../../constants'
import CarouselImage from '../../share/CarouselImage'
import ButtonArrow from '../../share/ButtonArrow'
import arrowLeftIcon from '../../../assets/images/arrow_left.svg'
import stylesModule from './styles.module.css'

const styles = StyleSheet.create({
  generalPadding: {
    paddingHorizontal: 16,
  },
  primaryBg: {
    backgroundColor: COLORS.PINK_A,
  },
  whiteBg: {
    backgroundColor: COLORS.WHITE,
  },
  topServiceSection: {
    paddingTop: 0,
  },
  carouselSection: {
    marginBottom: 28,
    marginTop: 24,
  },
  buttonWrap: {
    marginTop: 8,
  },
  carouselItem: {
    marginHorizontal: 4,
  },
  imgWrapper: {
    margin: 0,
  },
})

export default React.memo(function CampaignServiceList() {
  const navigate = useNavigate()

  const goToServiceDetail = React.useCallback(() => {
    const location = {
      pathname: `/service-detail`,
    }
    navigate(location)
  }, [navigate])

  const goToTPSLiff = () => {
    if (!process.env.REACT_APP_TPS_LIFF) return
    window.location.href = process.env.REACT_APP_TPS_LIFF
  }

  const goToEventList = () => {
    const eventElement = document.getElementById('events-list')
    if (!eventElement) return
    eventElement.scrollIntoView({ behavior: 'smooth' })
  }

  const goToProductList = () => {
    const productListElement = document.getElementById('product-list')
    if (!productListElement) return
    productListElement.scrollIntoView({ behavior: 'smooth' })
  }

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'center',
    centerMode: true,
    centerPadding: '32px',
  }

  const campaignS3Link = process.env.REACT_APP_FAQ_BUCKET

  return (
    <View style={[styles.primaryBg, styles.topServiceSection]}>
      <View style={[styles.generalPadding, styles.whiteBg]}>
        <img
          className={stylesModule.bannerService01}
          src={`${process.env.REACT_APP_FAQ_BUCKET}/tokyustore/images/service_banner01.png`}
          alt=""
        />
        <img
          className={stylesModule.bannerService02}
          src={`${process.env.REACT_APP_FAQ_BUCKET}/tokyustore/images/service_banner02.png`}
          alt=""
        />
      </View>
      <View style={styles.carouselSection}>
        {campaignS3Link && (
          <CarouselImage settings={settings}>
            <div onClick={goToProductList}>
              <View style={styles.carouselItem}>
                <img
                  className={stylesModule.thumbnail}
                  src={`${campaignS3Link}/tokyustore/images/campaign_banner01.png`}
                  alt=""
                />
              </View>
            </div>
            <div onClick={goToEventList}>
              <View style={styles.carouselItem}>
                <img
                  className={stylesModule.thumbnail}
                  src={`${campaignS3Link}/tokyustore/images/campaign_banner02.png`}
                  alt=""
                />
              </View>
            </div>
            <div onClick={goToTPSLiff}>
              <View style={styles.carouselItem}>
                <img
                  className={stylesModule.thumbnail}
                  src={`${campaignS3Link}/tokyustore/images/campaign_banner03.png`}
                  alt=""
                />
              </View>
            </div>
          </CarouselImage>
        )}
      </View>
      <View style={[styles.generalPadding, styles.buttonWrap]}>
        <ButtonArrow
          onPress={goToServiceDetail}
          text="「3つのまる得」についての詳細はこちら"
          scrollTarget=""
          arrowIcon={arrowLeftIcon}
        />
      </View>
    </View>
  )
})
