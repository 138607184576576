import * as React from 'react'
import { View, Text, StyleSheet, Image } from 'react-native'
import { Domain } from '../../../core'
import { COLORS, FONTS } from '../../../constants'
import Duration from '../EventDetail/Duration'
import ButtonSubmit from '../../share/ButtonSubmit'
import stylesModule from '../EventDetail/styles.module.css'

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 28,
    marginTop: 30,
    fontSize: FONTS.SIZE.XL,
  },
  eventItem: {
    width: '100vw',
    paddingBottom: 30,
    paddingHorizontal: 15,
    marginTop: 30,
    marginLeft: '-15px',
    borderBottomColor: COLORS.GRAY_A,
    borderBottomWidth: 1,
  },
  eventItemLast: {
    paddingBottom: 30,
    paddingHorizontal: 15,
    marginTop: 30,
  },
  headLineTxt: {
    fontSize: FONTS.SIZE.M,
    textAlign: 'center',
    lineHeight: 28,
    marginTop: 20,
  },
  emptyMsg: {
    marginTop: 30,
    fontSize: FONTS.SIZE.M,
  },
  imageWrapper: {
    alignItems: 'center',
    marginTop: 30,
  },
  image: {
    width: 150,
    resizeMode: 'contain',
    paddingBottom: '50%',
    marginBottom: 30,
  },
  betweenListEventLine: {
    borderBottomColor: COLORS.GRAY_A,
    borderBottomWidth: 1,
    width: '100vw',
    marginLeft: '-15px',
  },
})

interface Props {
  list: Array<Domain.Event.ListEntity>
  title: string
  isShowEmpty?: boolean
  isDeactiveEvent?: boolean
  onPress: (eventId: string) => void
}
export default function Event(props: Props) {
  const { list, title, onPress, isShowEmpty, isDeactiveEvent } = props

  function handleNavigate(eventId: string) {
    onPress(eventId)
  }

  return (
    <View>
      {isDeactiveEvent && !!list.length && (
        <View style={styles.betweenListEventLine} />
      )}
      {(list.length || isShowEmpty) && (
        <Text style={styles.title}>{title}</Text>
      )}
      {isShowEmpty && (
        <>
          <Text style={styles.emptyMsg}>
            現在開催中のキャンペーンはありません。次回の開催をお楽しみに！
          </Text>
          <View style={styles.imageWrapper}>
            <Image
              source={require('../../../assets/images/empty-event-list.png')}
              style={styles.image}
            />
          </View>
        </>
      )}
      {list.map((event: Domain.Event.ListEntity, index: number) => {
        return (
          <View
            key={event.eventId}
            style={
              index + 1 === list.length
                ? styles.eventItemLast
                : styles.eventItem
            }
          >
            {!!event.eventImage && (
              <img
                className={stylesModule.eventBanner}
                src={`${event.eventImage}`}
                alt=""
              />
            )}
            <Text style={styles.headLineTxt}>{event.headLineText}</Text>
            <Duration
              startDate={event.startDate}
              endDate={event.endDate}
              isDisableDuration={event.isDisableDuration}
            />
            <ButtonSubmit
              onPress={() => handleNavigate(event.eventId)}
              disabled={false}
              text="詳しくはこちら"
            />
          </View>
        )
      })}
    </View>
  )
}
