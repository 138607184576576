import * as React from 'react'
import { View, TextInput, Text } from 'react-native'
import TextInputValidation from '../../share/TextInputValidation'
import RequiredLabel from '../../share/RequiredLabel'
import { COLORS, STYLES } from '../../../constants'

function isNumber(no: string): boolean {
  const pattern = /^\d*$/
  return no.match(pattern) !== null
}

function checkValid(phoneNo: string): boolean {
  return phoneNo.length === 0 || isNumber(phoneNo)
}

interface Props {
  setPhoneNoisValid: (valid: boolean) => void
  phoneNo: string
  setPhoneNo: (phoneNo: string) => void
}

export default function PhoneNumber(props: Props) {
  const { setPhoneNoisValid, phoneNo, setPhoneNo } = props
  const [errors, setErrors] = React.useState<Array<string>>([])

  const textInputsValidation = React.useCallback(() => {
    const isValid = checkValid(phoneNo)
    setPhoneNoisValid(isValid)

    const errorMessages = []
    if (!isValid) {
      errorMessages.push('数値を入力してください')
    }

    setErrors(errorMessages)
  }, [phoneNo, setPhoneNoisValid, setErrors])

  React.useEffect(() => {
    textInputsValidation()
  }, [textInputsValidation])

  const onChangePhoneNoText = React.useCallback(
    (newValue: string) => {
      setPhoneNo(newValue)
    },
    [setPhoneNo]
  )

  return (
    <View>
      <View style={STYLES.formGroupStyle.formSection}>
        <Text style={STYLES.formGroupStyle.formSectionTitle}>
          <RequiredLabel />
          電話番号を入力してください
        </Text>
        {errors.length ? (
          <TextInputValidation errors={errors} />
        ) : (
          <Text style={STYLES.formGroupStyle.formSectionTitle}>
            お届け頂いている固定電話番号または携帯電話番号を入力してください（ハイフン不要）
          </Text>
        )}
        <TextInput
          placeholder="0337110109"
          placeholderTextColor={COLORS.GRAY_E}
          style={STYLES.formGroupStyle.input}
          onChangeText={onChangePhoneNoText}
          keyboardType={'phone-pad'}
        />
      </View>
    </View>
  )
}
