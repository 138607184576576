import * as React from 'react'
import { StyleSheet, TouchableOpacity, Text, Image } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { Domain } from '../../../core'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: 20,
    marginBottom: 8,
  },
  title: {
    fontSize: 16,
    alignSelf: 'center',
  },
  arrow: {
    width: 13,
    resizeMode: 'contain',
    paddingBottom: 16,
    position: 'absolute',
    right: 15,
    top: '37%',
  },
})

type Props = {
  prefecture: Domain.Prefecture.Entity
}

export default function Card(props: Props) {
  const { prefecture } = props
  const navigate = useNavigate()
  const prefectureCd = prefecture?.prefectureCd
  const needsToShowMunicipality = prefecture?.needsToShowMunicipality

  const navigateToCityInput = React.useCallback(
    () => navigate(`/city-input/${prefectureCd}`),
    [navigate, prefectureCd]
  )
  const navigateToNearbyStoreInput = () =>
    navigate(`/nearby-store-input/${prefectureCd}`)

  const handleRedirect = React.useCallback(() => {
    if (needsToShowMunicipality) {
      sessionStorage.setItem('prefectureName', prefecture?.prefectureName)
      navigateToCityInput()
    } else {
      navigateToNearbyStoreInput()
    }
  }, [
    navigateToCityInput,
    navigateToNearbyStoreInput,
    needsToShowMunicipality,
    prefecture,
  ])

  return (
    <TouchableOpacity style={styles.container} onPress={handleRedirect}>
      <Text style={styles.title}>{prefecture?.prefectureName}</Text>
      <Image
        source={require('../../../assets/images/next_icon.png')}
        style={styles.arrow}
      />
    </TouchableOpacity>
  )
}
