import * as Domain from '../domain'

type Card = Domain.Card.Entity | null

export function createInitialState(): Card {
  return null
}

export const SET_CARD = 'card/set' as const
export const CLEAR_CARD = 'card/clear' as const

export function setCard(card: Card) {
  return {
    type: SET_CARD,
    payload: {
      card,
    },
  }
}

export function clearCard() {
  return {
    type: CLEAR_CARD,
  }
}

type SetCardAction = ReturnType<typeof setCard>
type ClearCardAction = ReturnType<typeof clearCard>
type Action = SetCardAction | ClearCardAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_CARD:
      return action.payload.card
    case CLEAR_CARD:
      return null
    default:
      return state
  }
}
