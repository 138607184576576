import { ChangeEvent } from 'react'
import heic2any from 'heic2any'

const imageLimitSize = 15728640 // 15MB

export type FileError = 'Format' | 'Load' | 'Size'

const blobToDataURL = (blob: Blob): Promise<string | undefined> =>
  new Promise((resolve) => {
    const fr = new FileReader()
    fr.onload = () => {
      const { result } = fr
      if (typeof result === 'string') {
        resolve(result)
      } else {
        resolve(undefined)
      }
    }
    fr.onerror = () => {
      resolve(undefined)
    }
    fr.readAsDataURL(blob)
  })

export const loadFile = async (
  e: ChangeEvent<HTMLInputElement>,
  willLoad: (file: File) => void,
  didFinishLoading: (file: File) => void,
  onSuccess: (
    fileDataURL: string,
    fileBlob: Blob,
    fileType: string,
    fileExtension: string
  ) => void,
  onFailure: (error: FileError) => void
) => {
  const acceptedFormat = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/heic',
    'image/heif',
  ]
  const convertTargetFormat = ['image/heic', 'image/heif']
  const image = (e.target.files as FileList)[0]

  // ファイル選択をキャンセルした場合、imageがundefinedになる
  if (image == null) return

  if (!acceptedFormat.includes(image.type)) {
    // ファイルフォーマットが非対応
    onFailure('Format')
  } else if (image.size > imageLimitSize) {
    // ファイルサイズ超過エラー
    onFailure('Size')
  } else {
    willLoad(image)

    const blob = new Blob([image])

    // 変換対象のイメージの場合、jpegに変換する
    let convertedBlob: Blob = blob
    let convertedImageType = image.type
    let convertedImageExtension = image.name.split('.').pop() ?? ''
    if (convertTargetFormat.includes(image.type)) {
      convertedImageType = 'image/jpeg'
      convertedImageExtension = 'jpg'
      const convertResult = await heic2any({
        blob,
        toType: convertedImageType,
      })
      convertedBlob = Array.isArray(convertResult)
        ? convertResult[0]
        : convertResult
    }

    const dataURL = await blobToDataURL(convertedBlob)
    if (dataURL === undefined) {
      // ファイル読み込みエラー
      onFailure('Load')
    } else {
      onSuccess(
        dataURL,
        convertedBlob,
        convertedImageType,
        convertedImageExtension
      )
    }

    didFinishLoading(image)
  }
}

interface ImageInfo {
  width: number
  height: number
}

// 変換後の画像の最大長辺
const MAX_LONG_SIDE = 1024
// 変換時の画像画質
const IMAGE_QUALITY_RATIO = 1.0

const convertResizedImageInfo = (imageInfo: ImageInfo): ImageInfo => {
  const { width, height } = imageInfo
  const longSide = width > height ? width : height
  // 最大長辺よりも小さい画像はそのままのサイズで返す
  if (longSide <= MAX_LONG_SIDE) {
    return imageInfo
  }

  const resizeRatio = MAX_LONG_SIDE / longSide
  const resizedImageInfo: ImageInfo = {
    width: Math.round(width * resizeRatio),
    height: Math.round(height * resizeRatio),
  }
  return resizedImageInfo
}

export const compressFile = (
  originalDataURL: string
): Promise<string | undefined> =>
  new Promise((resolve) => {
    const img = new Image()
    img.src = originalDataURL
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const resized = convertResizedImageInfo({
        width: img.width,
        height: img.height,
      })
      canvas.width = resized.width
      canvas.height = resized.height

      const ctx = canvas.getContext('2d')

      if (ctx != null) {
        ctx.drawImage(img, 0, 0, resized.width, resized.height)
        const dataURL = canvas.toDataURL('image/jpeg', IMAGE_QUALITY_RATIO)
        resolve(dataURL)
      } else {
        resolve(undefined)
      }
    }
    img.onerror = () => {
      resolve(undefined)
    }
  })
