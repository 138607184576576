import * as Domain from '../domain'

type Tenant = Domain.Tenant.Entity

const initialState = {
  tenantName: '',
  mainColor: '',
  tenantId: '',
  faqLink: '',
  favicon: '',
  nearbyStoresTitle: '',
  nearbyStoresDescription: '',
  registeredCardBtnText: '',
  cardCompleteRegisterText: '',
  bannerLink: {
    topBanner: {
      imageURL: '',
      redirectURL: ''
    },
    bottomBanner: {
      imageURL: '',
      redirectURL: ''
    }
  },
  flyerText: '',
  allowRegisterPremium: true,
  allowRegisterRed: true,
  allowRegisterStandard: true,
  allowRegisterGreen: true,
  isNearbyStores: true,
  isNearestStores: true,
  showFlyer: true,
  bannerStores: [],
  radioImage: '',
  nearbyStoreHoverColor: '',
  colorButtonText: '',
  buttonIcon: '',
  confirmDeleteCardText: '',
  text: {
    titleRegisteredStoreList: '',
    contentCardChangeDone: '',
    contentCardRegistrationComplete: '',
    contentStoreRegistrationCompleted: '',
  }
}

export function createInitialState(): Tenant {
  return initialState
}

export const SET_TENANT = 'tenant/set' as const
export const CLEAR_TENANT = 'tenant/clear' as const

export function setTenant(tenant: Tenant) {
  return {
    type: SET_TENANT,
    payload: {
      tenant,
    },
  }
}

export function clearTenant() {
  return {
    type: CLEAR_TENANT,
  }
}

type SetTenantAction = ReturnType<typeof setTenant>
type ClearTenantAction = ReturnType<typeof clearTenant>
type Action = SetTenantAction | ClearTenantAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TENANT:
      return action.payload.tenant
    case CLEAR_TENANT:
      return initialState
    default:
      return state
  }
}
