import * as React from 'react'
import { StyleSheet, Text } from 'react-native'
const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 28,
    marginVertical: 30,
  },
})

interface Props {
  title: string
}

export default function PageTitle(props: Props) {
  return <Text style={styles.title}>{props.title}</Text>
}
