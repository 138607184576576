import * as React from 'react'
import { Domain } from '../../../../core'
import stylesModule from '../styles.module.css'

interface Props {
  event: Domain.Event.DetailEntity
}

export default function Image(props: Props) {
  const { event } = props
  return (
    <img
      alt=""
      src={event.eventImageDetail}
      className={stylesModule.eventBanner}
    />
  )
}
