import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useNavigate, useParams } from 'react-router-dom'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import ButtonSubmit from '../../share/ButtonSubmit'
import { STYLES } from '../../../constants'
import { Domain } from '../../../core'
const { liff } = window

const styles = StyleSheet.create({
  subtitle: {
    marginBottom: 10,
    fontSize: 16,
  },
})
type Props = {
  tenant: Domain.Tenant.Entity
}

export default function CardRegistrationCompleted(props: Props) {
  const { tenant } = props
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const { storeRegist } = useParams()
  const navigateToAddStore = React.useCallback(
    () => navigate('/add-store'),
    [navigate]
  )
  const navigateToNearbyStore = React.useCallback(
    () => navigate('/nearby-store-input'),
    [navigate]
  )

  const onPressCloseWindow = React.useCallback(() => {
    liff.closeWindow()
  }, [])

  const handleOnPressGoToNextStep = React.useCallback(() => {
    if (!tenant.isNearestStores && !tenant.isNearbyStores) {
      navigateToNearbyStore()
    } else {
      navigateToAddStore()
    }
  }, [navigateToAddStore, navigateToNearbyStore, tenant])
  return (
    <View style={STYLES.generalStyle.subContainer}>
      <View>
        <PageTitle title="カードを登録しました" />
        {storeRegist === 'true' ? (
          <>
            <Text style={styles.subtitle}>
              {tenant.text.contentCardRegistrationComplete}
            </Text>
            <ButtonSubmit
              onPress={onPressCloseWindow}
              disabled={false}
              text="トーク画面に戻る"
            />
          </>
        ) : (
          <>
            <Text style={styles.subtitle}>
              {tenant.text.contentCardChangeDone}
            </Text>
            <ButtonSubmit
              onPress={handleOnPressGoToNextStep}
              disabled={false}
              text={tenant.registeredCardBtnText}
            />
          </>
        )}
      </View>
      <QnA />
    </View>
  )
}
