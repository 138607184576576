import * as React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../../constants'
import { FileError } from '../../../../core/functions/file'

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    paddingTop: 6,
    paddingBottom: 8,
    paddingHorizontal: 12,
    backgroundColor: COLORS.ERROR,
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: 18,
    height: 16,
    resizeMode: 'contain',
    marginEnd: 7,
    marginTop: 2,
  },
  text: {
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
})

export type InputError = 'TotalPrice' | 'ZipCode'

interface Props {
  error: FileError | InputError
}

const icon = require('../../../../assets/resources/100th/icon_error.png')

export default function ErrorView(props: Props) {
  const { error } = props

  const getMessage = React.useCallback((e: FileError | InputError) => {
    switch (e) {
      case 'Format':
        return '画像形式が正しくないためアップロードできません（対応フォーマット：PNG, JPG, JPEG, HEIF）'
      case 'Load':
        return '画像の読み込みに失敗しました'
      case 'Size':
        return '15MB以下の画像を選択してください'
      case 'TotalPrice':
        return 'ご利用金額は2,000円以上で応募可能です'
      case 'ZipCode':
        return '数字7桁で入力してください'
      default:
        return ''
    }
  }, [])

  return (
    <View style={styles.container}>
      <Image source={icon} style={styles.icon} />
      <Text style={styles.text}>{getMessage(error)}</Text>
    </View>
  )
}
