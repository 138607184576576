import React from 'react'
import { StyleSheet, TouchableOpacity, Text } from 'react-native'
import withModalRedirect from '../HOC/withModalRedirect'

const styles = StyleSheet.create({
  modalButton: {
    margin: 'auto',
  },
  modalButtonLabel: {
    fontSize: 17,
    fontWeight: 'bold',
  },
})

type Props = {
  redirectUrl: () => void
}

function RedirectModal(props: Props) {
  const { redirectUrl } = props
  return (
    <TouchableOpacity onPress={redirectUrl} style={styles.modalButton}>
      <Text style={styles.modalButtonLabel}>OK</Text>
    </TouchableOpacity>
  )
}
export default withModalRedirect(RedirectModal)
