import * as React from 'react'
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Keyboard,
} from 'react-native'
import { useNavigate } from 'react-router-dom'
import { CardType } from '../../../constants/card-types'
import { Domain } from '../../../core'
import { STYLES } from '../../../constants'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import Modal from '../../share/Modal'
import ButtonSubmit from '../../share/ButtonSubmit'
import MiddleAndLastPointNo from './MiddleAndLastPointNo'

const styles = StyleSheet.create({
  cardImage: {
    paddingBottom: '12%',
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  cardName: {
    textAlign: 'center',
    fontSize: 16,
    marginVertical: 10,
  },
  modalButton: {
    margin: 'auto',
  },
  modalButtonLabel: {
    fontSize: 17,
    fontWeight: 'bold',
  },
})

type CardToRegister = Domain.PointCardToRegister.Entity | null

interface Props {
  userStatus: Domain.UserStatus.Entity | null
  actions: {
    fetchUserStatus: () => void
    registerCard: (card: CardToRegister, navigate: () => void) => void
    updateCard: (card: CardToRegister, navigate: () => void) => void
    clearScreenEmbeddedErrorAlert: () => void
  }
}

const cardCd = CardType.tokyuPointCardGreen
const firstPartPointNo = '65023000'

export default function GreenCardInput(props: Props) {
  const {
    userStatus,
    actions: {
      fetchUserStatus,
      registerCard,
      updateCard,
      clearScreenEmbeddedErrorAlert,
    },
  } = props

  const navigate = useNavigate()
  React.useEffect(() => {
    return () => clearScreenEmbeddedErrorAlert()
  }, [clearScreenEmbeddedErrorAlert])

  React.useEffect(() => fetchUserStatus(), [fetchUserStatus])

  const [middlePartPointNo, setMiddlePartPointNo] = React.useState<string>('')
  const [lastPartPointNo, setLastPartPointNo] = React.useState<string>('')
  const [pointNosAreValid, setPointNosAreValid] = React.useState<boolean>(false)

  const submitButtonEnabled =
    pointNosAreValid &&
    middlePartPointNo.length !== 0 &&
    lastPartPointNo.length !== 0

  const submitButtonTitle = React.useMemo(() => {
    return userStatus?.cardRegist
      ? 'カード情報を更新する'
      : 'カード情報を登録する'
  }, [userStatus])

  const scroll = (view: React.RefObject<View>) => {
    if (view && view.current) {
      view.current.focus()
    }
  }
  const navigateToCardRegistrationCompleted = React.useCallback(
    () => navigate(`/card-registration-completed/${userStatus?.storeRegist}`),
    [navigate, userStatus]
  )
  const top = React.useRef<View>(null)
  const [validationModal, setValidationModal] = React.useState<boolean>(false)
  const handleOnPressSubmit = React.useCallback(() => {
    Keyboard.dismiss()
    scroll(top)
    // valid green card number is from 6502 3000 0000 0000 to 6502 3000 2999 9999
    if (parseInt(middlePartPointNo, 10) < 3000) {
      const pointNo = firstPartPointNo + middlePartPointNo + lastPartPointNo
      const cardEntered = {
        cardCd,
        pointNo,
      }
      if (userStatus?.cardRegist) {
        updateCard(cardEntered, navigateToCardRegistrationCompleted)
      } else {
        registerCard(cardEntered, navigateToCardRegistrationCompleted)
      }
    } else {
      setValidationModal(true)
    }
  }, [
    userStatus,
    middlePartPointNo,
    lastPartPointNo,
    registerCard,
    updateCard,
    navigateToCardRegistrationCompleted,
  ])
  const handleCloseValidationModal = React.useCallback(
    () => setValidationModal(false),
    []
  )

  return (
    <View>
      <Modal
        needsToShow={validationModal}
        text="該当の会員情報が見つかりませんでした。原因など、詳しくは「よくある質問」をご覧ください。"
      >
        <TouchableOpacity
          onPress={handleCloseValidationModal}
          style={styles.modalButton}
        >
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>
      <View style={STYLES.generalStyle.subContainer}>
        <View>
          <PageTitle title="カード情報を入力してください" />
          <Image
            source={require('../../../assets/images/green_card.png')}
            style={styles.cardImage}
          />
          <Text ref={top} style={styles.cardName}>
            TOP&現金専用カード（緑）を選択中
          </Text>
          <MiddleAndLastPointNo
            setPointNosAreValid={setPointNosAreValid}
            middlePartPointNo={middlePartPointNo}
            setMiddlePartPointNo={setMiddlePartPointNo}
            lastPartPointNo={lastPartPointNo}
            setLastPartPointNo={setLastPartPointNo}
          />
          <ButtonSubmit
            onPress={handleOnPressSubmit}
            disabled={!submitButtonEnabled}
            text={submitButtonTitle}
          />
        </View>
        <QnA />
      </View>
    </View>
  )
}
