/* eslint-disable react/require-default-props */
import React, { useCallback, useRef } from 'react'
import {
  StyleSheet,
  Text,
  Image,
  View,
  TouchableOpacity,
  ViewStyle,
  TextStyle,
} from 'react-native'
import { COLORS } from '../../../../constants'

interface Props {
  text: string
  handleChooseFile: (e: React.ChangeEvent<HTMLInputElement>) => void
  buttonStyle?: ViewStyle
  textStyle?: TextStyle
  noImage?: boolean
}

const { liff } = window

const icon = require('../../../../assets/resources/100th/icon_camera.png')

export default function FileButton({
  text,
  handleChooseFile,
  buttonStyle,
  textStyle,
  noImage = false,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  const styles = StyleSheet.create({
    button: {
      flexDirection: 'row',
      backgroundColor: COLORS.RED_A,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 24,
      height: 48,
    },
    buttonTitle: {
      color: COLORS.WHITE,
      fontSize: 18,
      lineHeight: 20,
      fontWeight: '300',
    },
    cameraIcon: {
      width: 19,
      height: 17,
      resizeMode: 'contain',
      marginEnd: 13,
    },
  })

  const onPress = useCallback(() => {
    if (inputRef.current != null) {
      inputRef.current.click()
    }
  }, [])

  return (
    <View>
      <TouchableOpacity style={[styles.button, buttonStyle]} onPress={onPress}>
        {!noImage && <Image style={styles.cameraIcon} source={icon} />}
        <Text style={[styles.buttonTitle, textStyle]}>{text}</Text>
      </TouchableOpacity>
      <input
        hidden
        ref={inputRef}
        id="file"
        onChange={(e) => {
          handleChooseFile(e)
        }}
        onClick={(e) => {
          // 同じファイルが選択されてもonChangeイベントが発火するように初期化
          e.currentTarget.value = ''
        }}
        type="file"
        capture={liff.getOS() === 'android' ? 'environment' : false}
        accept=".jpg, .jpeg, .png, .heif, .heic"
      />
    </View>
  )
}
