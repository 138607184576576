import { Dispatch } from 'redux'
import * as Domain from '../domain'
import { Api } from '../../lib'
import { setStores } from '../modules/stores'

interface Data {
  storeList: Array<Domain.Store.Entity>
}

// ----- GET /user's store API -----
function didCallFetchRegisteredStoresApi(data: Data, dispatch: Dispatch) {
  dispatch(setStores(data.storeList))
}

export function fetchRegisteredStore (reloader: () => void) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'store', '', null, didCallFetchRegisteredStoresApi, dispatch, options)
  }
}

// ----- PUT /store API -----
function didCallRegisterStoreApi(data: {}, dispatch: Dispatch, options?: Api.Options) {
  if (options && options.navigate) {
    options.navigate()
  }
}

export function registerStore (storeToRegister: Domain.StoreToRegister.Entity, navigate: () => void) {
  const options = { navigate }

  return async function(dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.post,
      'store',
      '',
      JSON.stringify(storeToRegister),
      didCallRegisterStoreApi,
      dispatch,
      options,
    )
  }
}

async function didCallDeleteRegistredStore (data: {}, dispatch: Dispatch, options?: Api.Options) {
  await Api.callApi(Api.HttpMethod.get, 'store', '', null, didCallFetchRegisteredStoresApi, dispatch, options)
}

export function deleteRegisteredStoreAndReloadStores (storeCd: string) {
  return async function(dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.delete, 'store', `&storeCd=${storeCd}`, null, didCallDeleteRegistredStore, dispatch)
  }
}
