import * as Domain from '../domain'

type FAQ = Array<Domain.FAQ.Entity>

export function createInitialState(): FAQ {
  return []
}

export const SET_FAQ = 'faq/set' as const
export const CLEAR_FAQ = 'faq/clear' as const

export function setFAQ(faq: FAQ) {
  return {
    type: SET_FAQ,
    payload: {
      faq,
    },
  }
}

export function clearFAQ() {
  return {
    type: CLEAR_FAQ,
  }
}

type SetFAQAction = ReturnType<typeof setFAQ>
type ClearFAQAction = ReturnType<typeof clearFAQ>
type Action = ClearFAQAction | SetFAQAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_FAQ:
      return action.payload.faq
    case CLEAR_FAQ:
      return []
    default:
      return state
  }
}
