import * as React from 'react'
import { Text, View, StyleSheet, Linking, TouchableOpacity } from 'react-native'
import { STYLES, COLORS } from '../../../constants'

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginVertical: 30,
    lineHeight: 28,
  },
  content: {
    marginBottom: 20,
    fontSize: 16,
    lineHeight: 24,
  },
  linkAction: {
    marginRight: 'auto',
  },
  link: {
    textDecorationLine: 'underline',
    color: COLORS.BLUE,
    width: '100%',
  },
})

export default function Maintenance() {
  const link = 'https://www.tokyu-store.co.jp/'
  const linkToTokyuStore = React.useCallback(() => {
    // @ts-ignore
    Linking.openURL(link, '_self')
  }, [])
  return (
    <View style={STYLES.generalStyle.subContainer}>
      <Text style={styles.title}>
        現在、システムメンテナンスを行っております。
      </Text>
      <Text style={styles.content}>
        サービス再開は9月28日（月）午前5時（日本時間）の予定です。{'\n'}
        ご不便をおかけし申し訳ございませんが、今しばらくお待ちください。
      </Text>
      <Text style={styles.content}>
        尚、店舗情報をご確認の場合は下記東急ストアHPをご参照ください。
      </Text>
      <TouchableOpacity onPress={linkToTokyuStore} style={styles.linkAction}>
        <Text style={[styles.content, styles.link]}>{link}</Text>
      </TouchableOpacity>
    </View>
  )
}
