import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { StyleSheet, Text, View } from 'react-native'
import { useParams } from 'react-router-dom'
import { COLORS } from '../../../../constants'
import Duration from '../Duration'
import Image from './Image'
import SharedLink from '../../../share/Link'
import ButtonSubmit from '../../../share/ButtonSubmit'
import TermsOfCampaignCheckbox from './TermsOfCampaignCheckbox'
import FileButton from './FileButton'
import { formatPrice } from '../../../../core/functions/format'
import SearchPrize from './SearchPrize'
import SelectPrize from './SelectPrize'
import {
  PrizeEntity,
  UserEntryMultipleRequestEntity,
} from '../../../../core/domain/event'
import Questionnaire from './Questionnaire'
import { FileError, loadFile } from '../../../../core/functions/file'
import { Modules } from '../../../../core'
import CampaignDetail from '../../../share/100th/CampaignDetail'
import DesiredPrizeConfirmation from './DesiredPrizeConfirmation'
import ErrorView from '../../../share/100th/ErrorView'
import Description from './Description'

const { liff } = window

interface Props {
  fetchActions: {
    fetchEvent: (eventId: string) => void
    fetchEventPrizes: (eventId: string) => void
  }
  fileLoadingActions: {
    willLoad: () => void
    didFinishLoading: () => void
    onSuccess: (
      fileDataURL: string,
      fileBlob: Blob,
      fileType: string,
      fileExtension: string
    ) => void
  }
  otherActions: {
    entry: (
      body: UserEntryMultipleRequestEntity,
      selectedPrize: PrizeEntity
    ) => void
    onClickSmartReceiptButton: () => void
    onClickReceiptLink: () => void
  }
}

const styles = StyleSheet.create({
  eventDateContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: COLORS.GRAY_E,
    borderBottomColor: COLORS.GRAY_E,
    height: 48,
    justifyContent: 'center',
  },
  eventDate: {
    marginTop: 0,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 16,
    color: COLORS.RED_A,
  },
  contentContainer: {
    paddingStart: 16,
    paddingEnd: 16,
    paddingBottom: 16,
  },
  step: {
    fontSize: 28,
    fontWeight: '600',
    marginTop: 24,
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  howToTakePhotoLinkView: {
    marginTop: 16,
  },
  howToTakePhotoLinkText: {
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
    color: COLORS.ORANGE,
  },
  tapHereForSmartreceiptUsersButton: {
    marginTop: 8,
    borderRadius: 24,
    backgroundColor: COLORS.WHITE,
    borderWidth: 1,
    borderColor: COLORS.ORANGE,
    height: 63,
  },
  tapHereForSmartreceiptUsersButtonText: {
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
    color: COLORS.ORANGE,
    textAlign: 'center',
  },
  entryButtonDisabledBeforeUploadingReceipt: {
    marginTop: 8,
    borderRadius: 24,
    backgroundColor: COLORS.GRAY_E,
    height: 48,
  },
  entryButtonDisabledAfterUploadingReceipt: {
    marginTop: 8,
    borderRadius: 24,
    backgroundColor: COLORS.GRAY_E,
    height: 48,
  },
  entryButton: {
    marginTop: 8,
    borderRadius: 24,
    backgroundColor: COLORS.RED_A,
    height: 48,
  },
  entryButtonText: {
    color: COLORS.WHITE,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
  },
  totalPrice: {
    borderRadius: 8,
    backgroundColor: '#FFEF89',
    height: 75,
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  totalPriceTitle: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  totalPriceValue: {
    marginTop: 4,
    fontSize: 28,
    fontWeight: '600',
    color: COLORS.TOKYU_BLACK,
    lineHeight: 30,
  },
  fileButton: {
    marginTop: 16,
  },
  termsOfCampaignContainerBeforeUploadingReceipt: {
    paddingHorizontal: 16,
    paddingBottom: 24,
  },
  termsOfCampaignContainerAfterUploadingReceipt: {
    paddingTop: 11,
    paddingHorizontal: 16,
    paddingBottom: 24,
  },
  receiptIsUploaded: {
    marginVertical: 8,
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 15,
    textAlign: 'center',
    color: COLORS.GRAY_H,
  },
})

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ParamTypes = {
  eventId: string
}

export default function EventDetail100th(props: Props) {
  const {
    fetchActions: { fetchEvent, fetchEventPrizes },
    fileLoadingActions: { willLoad, didFinishLoading, onSuccess },
    otherActions: { entry, onClickSmartReceiptButton, onClickReceiptLink },
  } = props

  const { eventId } = useParams<ParamTypes>()

  // state
  // ファイルエラー
  const [fileError, setFileError] = React.useState<FileError>()

  // キャンペーン利用規約のチェック状態
  const [isChecked, setIsChecked] = React.useState(false)

  // エントリーボタンタイトル
  const [entryButtonTitle] = React.useState('エントリーする')

  // ④賞品を選択 に表示する賞品一覧
  const [prizesToSelect, setPrizesToSelect] = React.useState<PrizeEntity[]>([])

  // ④賞品を選択 で選択した賞品
  const [selectedPrize, setSelectedPrize] = React.useState<PrizeEntity>()

  // ⑤アンケート で入力した郵便番号
  const [zipCode, setZipCode] = React.useState('')

  // ⑤アンケート で選択した施設
  const [facility, setFacility] = React.useState('---')

  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )

  const totalPriceSectionRef = React.useRef<HTMLDivElement>(null)
  const totalPriceSectionDiv = totalPriceSectionRef.current

  React.useEffect(() => {
    if (receipt.event == null) {
      fetchEvent(eventId ?? '')
    }
    if (receipt.prizes == null) {
      fetchEventPrizes(eventId ?? '')
    }
  }, [eventId, fetchEvent, fetchEventPrizes, receipt.event, receipt.prizes])

  const scrollToTotalPriceSection = React.useCallback(() => {
    totalPriceSectionDiv?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    })
  }, [totalPriceSectionDiv])

  const zipCodeIsValid = React.useCallback(
    (value: string) => value.match(/^\d{7}$/) !== null,
    []
  )

  React.useEffect(() => {
    scrollToTotalPriceSection()
  }, [scrollToTotalPriceSection])

  // TODO: イベント期間が終了している場合の表示
  // React.useEffect(() => {
  //   if (Date.parse(event.currentDate) < Date.parse(event.endDate)) {
  //     setEntryButtonTitle('エントリーする')
  //     setEntryButtonDisabled(false)
  //   } else {
  //     setEntryButtonTitle('終了しました')
  //     setEntryButtonDisabled(true)
  //   }
  // }, [event])

  const hasDataToShow = receipt.event != null && receipt.prizes != null
  const receiptIsUploaded = receipt.totalPrice != null

  const entryButtonDisabled = React.useMemo(() => {
    if (!receiptIsUploaded) return true

    if (
      // 賞品選択済み
      selectedPrize != null &&
      // 郵便番号7桁数字
      zipCodeIsValid(zipCode) &&
      // 今回ご利用いただいた施設選択済み
      facility !== '---' &&
      // キャンペーン利用規約にチェック済み
      isChecked
    )
      return false
    return true
  }, [
    receiptIsUploaded,
    selectedPrize,
    zipCode,
    facility,
    isChecked,
    zipCodeIsValid,
  ])

  const event = receipt.event!

  const entryButtonStyle = React.useMemo(() => {
    if (receiptIsUploaded) {
      return entryButtonDisabled
        ? styles.entryButtonDisabledAfterUploadingReceipt
        : styles.entryButton
    }
    return entryButtonDisabled
      ? styles.entryButtonDisabledBeforeUploadingReceipt
      : styles.entryButton
  }, [entryButtonDisabled, receiptIsUploaded])

  return (
    <View>
      {hasDataToShow && (
        <>
          {/* イベント画像 */}
          {event.eventImageDetail.length > 0 && <Image event={event} />}
          {/* 受付期間 */}
          <View style={styles.eventDateContainer}>
            <Duration
              startDate={event.startDate}
              endDate={event.endDate}
              eventDateStyle={styles.eventDate}
              isDisableDuration={event.isDisableDuration}
            />
          </View>
          {/* 説明文 */}
          <Description event={event} />
          <View style={styles.contentContainer}>
            <Text style={styles.step}>①レシート・領収書等を撮影</Text>
            {/* レシート画像の撮影方法 */}
            <SharedLink
              title="レシート画像の撮影方法"
              propsStyle={styles.howToTakePhotoLinkView}
              textStyle={styles.howToTakePhotoLinkText}
              onPress={() => {
                onClickReceiptLink()
              }}
            />
            {/* ファイルエラー表示 */}
            {fileError !== undefined && <ErrorView error={fileError} />}
            <FileButton
              text={receiptIsUploaded ? 'レシートを再撮影' : 'レシートを撮影'}
              handleChooseFile={(e) => {
                loadFile(
                  e,
                  willLoad,
                  didFinishLoading,
                  (
                    fileDataURL: string,
                    fileBlob: Blob,
                    fileType: string,
                    fileExtension: string
                  ) => {
                    setFileError(undefined)
                    onSuccess(fileDataURL, fileBlob, fileType, fileExtension)
                  },
                  (error: FileError) => {
                    setFileError(error)
                  }
                )
              }}
              buttonStyle={styles.fileButton}
            />
            {receiptIsUploaded && (
              <Text style={styles.receiptIsUploaded}>アップロード済み</Text>
            )}
            <ButtonSubmit
              disabled={false}
              onPress={onClickSmartReceiptButton}
              text={`東急ストアの${'\n'}スマートレシートはこちら`}
              buttonStyle={styles.tapHereForSmartreceiptUsersButton}
              textStyle={styles.tapHereForSmartreceiptUsersButtonText}
            />
            {/* ②ご利用金額の確認 */}
            {receiptIsUploaded && (
              <View>
                <div ref={totalPriceSectionRef} />
                <Text style={styles.step}>②ご利用金額の確認</Text>
                <View style={styles.totalPrice}>
                  <Text style={styles.totalPriceTitle}>ご利用金額</Text>
                  <Text style={styles.totalPriceValue}>
                    {`${formatPrice(receipt.totalPrice!)}円`}
                  </Text>
                </View>
              </View>
            )}
            {/* ③賞品を検索 */}
            {receiptIsUploaded && (
              <SearchPrize
                totalPrice={receipt.totalPrice!}
                prizes={receipt.prizes ?? []}
                setPrizesToSelect={setPrizesToSelect}
                prizesUrl={receipt.event?.prizesUrl}
              />
            )}
            {/* ④賞品を選択 */}
            {receiptIsUploaded && (
              <SelectPrize
                totalPrice={receipt.totalPrice!}
                prizes={prizesToSelect}
                selectedPrize={selectedPrize}
                setSelectedPrize={setSelectedPrize}
              />
            )}
            {/* ⑤アンケート */}
            {receiptIsUploaded && (
              <Questionnaire
                zipCode={zipCode}
                setZipCode={setZipCode}
                zipCodeIsValid={zipCodeIsValid}
                setFacility={setFacility}
              />
            )}
            {/* ⑥応募賞品確認 */}
            {selectedPrize != null && (
              <DesiredPrizeConfirmation prize={selectedPrize} />
            )}
          </View>
          <View
            style={
              receiptIsUploaded
                ? styles.termsOfCampaignContainerAfterUploadingReceipt
                : styles.termsOfCampaignContainerBeforeUploadingReceipt
            }
          >
            {/* 利用規約のチェックボックス */}
            {receiptIsUploaded && (
              <TermsOfCampaignCheckbox
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked)
                }}
                onPressTermsOfCampaignLink={() => {
                  const termsUrl = receipt.event?.termsUrl
                  if (termsUrl != null && termsUrl.length > 0) {
                    liff.openWindow({ url: termsUrl })
                  }
                }}
              />
            )}
            {/* エントリーボタン */}
            <ButtonSubmit
              noImage
              onPress={() => {
                const data: UserEntryMultipleRequestEntity = {
                  tenantId: process.env.REACT_APP_TENANT_ID ?? '',
                  eventId,
                  receiptAmount: receipt.totalPrice,
                  isModified: receipt.isModified,
                  purchasedFacilityName: receipt.purchasedFacilityName,
                  purchasedStoreName: receipt.purchasedStoreName,
                  purchasedDate: receipt.purchasedDate,
                  receiptType: (
                    receipt.isSmartReceipt != null
                      ? receipt.isSmartReceipt
                      : false
                  )
                    ? 1
                    : 0,
                  facilityType: facility,
                  desiredPrizeName: selectedPrize?.title,
                  desiredPrizePrice: selectedPrize?.price,
                  kind: selectedPrize?.kind,
                  userReceiptImagePath: receipt.userReceiptImagePath,
                  postalCode: zipCode,
                  phoneNumber: receipt.phoneNumber,
                }
                entry(data, selectedPrize!)
              }}
              disabled={entryButtonDisabled}
              text={entryButtonTitle}
              buttonStyle={entryButtonStyle}
              textStyle={styles.entryButtonText}
            />
          </View>
          <CampaignDetail />
        </>
      )}
    </View>
  )
}
