import React from 'react'
import { View, StyleSheet } from 'react-native'
import QnA from '../../share/QnA'
import PrivacyPolicy from '../../share/PrivacyPolicy'
import { COLORS } from '../../../constants'
import ButtonArrow from '../../share/ButtonArrow'
import arrowUpIcon from '../../../assets/images/arrow_up.svg'

const styles = StyleSheet.create({
  textFooterWrap: {
    marginLeft: 16,
  },
  textStyle: {
    color: COLORS.ORANGE,
    fontSize: 13,
    lineHeight: 13
  },
  btnWrap: {
    paddingHorizontal: 15,
    paddingTop: 16,
    paddingBottom: 24,
    backgroundColor: COLORS.PINK_A
  },
  container: {
    paddingBottom: 60
  },
  marutokuTopText: {
    color: COLORS.ORANGE,
    marginTop: 60,
    marginBottom: 25,
    marginHorizontal: 'auto'
  },
  qnaText: {
    color: COLORS.ORANGE,
    fontSize: 13,
    marginBottom: 47,
    marginHorizontal: 'auto'
  }
})

interface Props {
  statusLoadMore: boolean
}

export default function Footer (props: Props) {

  const { statusLoadMore } = props; 

  async function scrollToElement(id: string) {
    const element = await document.getElementById(id) as HTMLElement
    const top = element.getBoundingClientRect().top + window.pageYOffset - window.outerHeight / 2 + element.clientHeight
    window.scrollTo({top, behavior: 'smooth'})
  }

  React.useEffect(() => {
    const handleScroll = () => {
      scrollToElement(`${window.location.hash.substr(1)}`)
    }
    window.addEventListener('replacestate', handleScroll)
    return () => {
      window.removeEventListener('replacestate', handleScroll)
    }
  },[])

  const handleScrollToTop = () => {
    window.history.replaceState({}, '', `#filter`)
    window.dispatchEvent(new Event('replacestate'))
  }

  const qnaTokyuSoreTitle = '制度詳細・よくある質問'
  const qnaOtherAppTitle = 'よくある質問はこちら'
  const [isTokyuStore, setIsTokyuStore] = React.useState(false)

  React.useEffect(() => {
    if (process.env.REACT_APP_TENANT_ID === '0101') {
      setIsTokyuStore(true)
    }
  },[])

  return (
    <>
    {
      !statusLoadMore &&
        <View style={styles.container}>
          <View style={styles.btnWrap}>
            <ButtonArrow onPress={() => handleScrollToTop()} text="絞り込み条件へ戻る" scrollTarget="" arrowIcon={arrowUpIcon}/>
          </View>
          {isTokyuStore &&
            <QnA
              title={'まる得 TOP'}
              textStyle={styles.textStyle}
              textFooterWrap={styles.textFooterWrap}
              customUrl={'/top-campaign'}
              customStyle={styles.marutokuTopText}
              handleClick={() => { window.location.reload() }}
            />
          }
          <QnA
            title={isTokyuStore ? qnaTokyuSoreTitle : qnaOtherAppTitle}
            textStyle={styles.textStyle}
            textFooterWrap={styles.textFooterWrap}
            isExternalLink={!!isTokyuStore}
            customStyle={isTokyuStore ? styles.qnaText : undefined}
          />
          <PrivacyPolicy
            textStyle={styles.textStyle}
            textFooterWrap={styles.textFooterWrap}
          />
        </View>
    }
    </>
  )
}
