import * as React from 'react'
import { StyleSheet, View, TextInput, Text } from 'react-native'
import TextInputValidation from '../../share/TextInputValidation'
import RequiredLabel from '../../share/RequiredLabel'
import { COLORS, STYLES } from '../../../constants'

const styles = StyleSheet.create({
  inputSmall: {
    width: 70,
  },
  flexRow: {
    flexDirection: 'row',
  },
  birthDateSection: {
    maxWidth: 300,
  },
  birthDateGroup: {
    alignItems: 'flex-end',
    marginRight: 10,
  },
  birthDateUnit: {
    marginLeft: 5,
    fontSize: 16,
  },
})

function areEmpty(day: string, month: string, year: string): boolean {
  return day === '' && month === '' && year === ''
}

function isNumber(data: string): boolean {
  const pattern = /^\d*$/
  return data.match(pattern) !== null
}

function checkGeneralVaid(day: string, month: string, year: string): boolean {
  return (
    areEmpty(day, month, year) ||
    (isNumber(day) && isNumber(month) && isNumber(year))
  )
}

function checkYearValid(year: string): boolean {
  if (isNumber(year)) {
    const isFoursDigit = year.length === 4 || year === '' ? true : false
    return isFoursDigit
  }
  return false
}

function checkMonthValid(month: string): boolean {
  if (isNumber(month)) {
    const monthInt = parseInt(month, 10)
    const isValidMonth =
      (monthInt >= 1 && monthInt <= 12) || month === '' ? true : false
    return isValidMonth
  }
  return false
}

function checkDayValid(day: string): boolean {
  if (isNumber(day)) {
    const dayInt = parseInt(day, 10)
    const isValidDay =
      (dayInt >= 1 && dayInt <= 31) || day === '' ? true : false
    return isValidDay
  }
  return false
}

interface Props {
  setBirthdateIsValid: (valid: boolean) => void
  birthDay: string
  setBirthDay: (birthDay: string) => void
  birthMonth: string
  setBirthMonth: (birthMonth: string) => void
  birthYear: string
  setBirthYear: (birthYear: string) => void
}

export default function BirthDate(props: Props) {
  const {
    setBirthdateIsValid,
    birthDay,
    setBirthDay,
    birthMonth,
    setBirthMonth,
    birthYear,
    setBirthYear,
  } = props
  const [errors, setErrors] = React.useState<Array<string>>([])

  const textInputsValidation = React.useCallback(() => {
    const areGeneralValid = checkGeneralVaid(birthDay, birthMonth, birthYear)
    const isValidYear = checkYearValid(birthYear)
    const isValidMonth = checkMonthValid(birthMonth)
    const isValidDay = checkDayValid(birthDay)
    setBirthdateIsValid(
      areGeneralValid && isValidYear && isValidMonth && isValidDay
    )

    const errorMessages = []
    if (!areGeneralValid) {
      errorMessages.push('数値を入力してください')
    }
    if (!isValidYear) {
      errorMessages.push('年は4桁の西暦を入力してください')
    }
    if (!isValidMonth) {
      errorMessages.push('月は1〜12を入力してください')
    }
    if (!isValidDay) {
      errorMessages.push('日は1〜31を入力してください')
    }

    setErrors(errorMessages)
  }, [birthDay, birthMonth, birthYear, setBirthdateIsValid, setErrors])

  React.useEffect(() => {
    textInputsValidation()
  }, [textInputsValidation])

  const onChangeBirthDayText = React.useCallback(
    (newValue: string) => {
      setBirthDay(newValue)
    },
    [setBirthDay]
  )
  const onChangeBirthMonthText = React.useCallback(
    (newValue: string) => {
      setBirthMonth(newValue)
    },
    [setBirthMonth]
  )
  const onChangeBirthYearText = React.useCallback(
    (newValue: string) => {
      setBirthYear(newValue)
    },
    [setBirthYear]
  )

  const addZeroToBirthDayText = React.useCallback(() => {
    if (birthDay.length === 1) {
      const complementText = '0' + birthDay
      setBirthDay(complementText)
    }
  }, [birthDay, setBirthDay])

  const addZeroToBirthMonthText = React.useCallback(() => {
    if (birthMonth.length === 1) {
      const complementText = '0' + birthMonth
      setBirthMonth(complementText)
    }
  }, [birthMonth, setBirthMonth])

  return (
    <View>
      <View style={STYLES.formGroupStyle.formSection}>
        <Text style={STYLES.formGroupStyle.formSectionTitle}>
          <RequiredLabel />
          生年月日を入力してください
        </Text>
        <TextInputValidation errors={errors} />
        <View style={[styles.flexRow, styles.birthDateSection]}>
          <View style={[styles.flexRow, styles.birthDateGroup]}>
            <TextInput
              placeholder="9999"
              placeholderTextColor={COLORS.GRAY_E}
              style={[STYLES.formGroupStyle.input, styles.inputSmall]}
              onChangeText={onChangeBirthYearText}
              keyboardType={'phone-pad'}
              maxLength={4}
            />
            <Text style={styles.birthDateUnit}>年</Text>
          </View>
          <View style={[styles.flexRow, styles.birthDateGroup]}>
            <TextInput
              placeholder="12"
              placeholderTextColor={COLORS.GRAY_E}
              style={[STYLES.formGroupStyle.input, styles.inputSmall]}
              onChangeText={onChangeBirthMonthText}
              onBlur={addZeroToBirthMonthText}
              value={birthMonth}
              keyboardType={'phone-pad'}
              maxLength={2}
            />
            <Text style={styles.birthDateUnit}>月</Text>
          </View>
          <View style={[styles.flexRow, styles.birthDateGroup]}>
            <TextInput
              placeholder="31"
              placeholderTextColor={COLORS.GRAY_E}
              style={[STYLES.formGroupStyle.input, styles.inputSmall]}
              onChangeText={onChangeBirthDayText}
              onBlur={addZeroToBirthDayText}
              value={birthDay}
              keyboardType={'phone-pad'}
              maxLength={2}
            />
            <Text style={styles.birthDateUnit}>日</Text>
          </View>
        </View>
      </View>
    </View>
  )
}
