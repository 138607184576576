import * as React from 'react'
import { StyleSheet, Text, TextInput, TextStyle } from 'react-native'
import { Domain } from '../../../core'
import { COLORS, FONTS, STYLES } from '../../../constants'

const styles = StyleSheet.create({
  commentTitle: {
    marginTop: 28,
    marginBottom: 15,
    fontSize: FONTS.SIZE.M,
  },
})

interface Props {
  data: Domain.Event.CommentEntity
  comment: never[]
  setComment: (comment: never[]) => void
}

export default function Comment(props: Props) {
  const { data, comment, setComment } = props
  const findIndexComment = (arg: {}) => {
    return data.comment_title in arg
  }

  const onChangeCommentText = (inputedValue: string) => {
    let value = ''
    const temp = JSON.parse(JSON.stringify(comment))
    const index = temp.findIndex(findIndexComment)
    if (inputedValue.replace(/\s/g, '').length) {
      value = inputedValue
    } else {
      value = ''
    }
    if (index === -1) {
      temp.push({ [data.comment_title]: value })
    } else {
      temp[index][data.comment_title] = value
    }
    setComment(temp)
  }

  return (
    <>
      <Text style={styles.commentTitle}>{data.comment_txt}</Text>
      <TextInput
        style={[
          STYLES.formGroupStyle.input,
          { height: data.height } as TextStyle,
        ]}
        onChangeText={onChangeCommentText}
        placeholder={data.placeholder}
        placeholderTextColor={COLORS.GRAY_E}
        multiline={true}
      />
    </>
  )
}
