import * as React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { COLORS } from '../../../constants'
import { Domain } from '../../../core'
import { FileError, loadFile } from '../../../core/functions/file'
import CampaignDetail from '../../share/100th/CampaignDetail'
import ButtonSubmit from '../../share/ButtonSubmit'
import FileButton from '../EventDetail/100th/FileButton'
import stylesModule from './styles.module.css'
import ErrorView from '../../share/100th/ErrorView'

interface Props {
  receipt: Domain.Receipt.Entity
  actions: {
    uploadImage: (
      totalPrice: number,
      userModifiedTotalPriceAfterOCR?: boolean
    ) => void
    willLoadFile: () => void
    didFinishFileLoading: () => void
    fileLoadingOnSuccess: (
      fileDataURL: string,
      fileBlob: Blob,
      fileType: string,
      fileExtension: string
    ) => void
    uploadImageForReceiptOCR: (
      originalDataURL: string,
      setTotalPrice: React.Dispatch<React.SetStateAction<string>>,
      setOCRTotalPrice: React.Dispatch<React.SetStateAction<string>>,
      setOCRIsSuccess: React.Dispatch<
        React.SetStateAction<boolean | undefined>
      >,
      setUserModifiedTotalPriceAfterOCR: React.Dispatch<
        React.SetStateAction<boolean | undefined>
      >
    ) => void
  }
}

const receiptOCRResultMessageSuccess =
  'アップロードしたレシートの内容を読み取りました。正しく読み込めていない場合は、正しい金額を入力してください。'
const receiptOCRResultMessageFailure =
  'アップロードしたレシートが正しく読み込めませんでした。レシートに表示されているご利用合計金額（税込）をご入力いただくか、再度レシートを撮影してください。'

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  title: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.TOKYU_BLACK,
  },
  message: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  messageError: {
    color: COLORS.ORANGE,
  },
  imageContainer: {
    marginTop: 11,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  priceContainer: {
    paddingStart: 16,
    flexGrow: 1,
  },
  totalPriceLabel: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  totalPriceInputContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  totalPriceInput: {
    height: 48,
    width: 121,
    borderWidth: 1,
    borderColor: COLORS.GRAY_E,
    borderRadius: 4,
    paddingTop: 16,
    paddingBottom: 16,
    paddingStart: 8,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  yenLabel: {
    marginStart: 8,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  doneButtonDisabled: {
    marginTop: 18,
    borderRadius: 24,
    backgroundColor: COLORS.GRAY_E,
    height: 48,
  },
  doneButton: {
    marginTop: 18,
    borderRadius: 24,
    backgroundColor: COLORS.RED_A,
    height: 48,
  },
  doneButtonText: {
    color: COLORS.WHITE,
    fontSize: 20,
    fontWeight: '300',
    lineHeight: 20,
  },
  redoButton: {
    borderRadius: 24,
    backgroundColor: COLORS.WHITE,
    borderWidth: 1,
    borderColor: COLORS.ORANGE,
    height: 48,
    marginTop: 8,
  },
  redoButtonText: {
    color: COLORS.ORANGE,
    fontSize: 20,
    fontWeight: '300',
    lineHeight: 20,
  },
})

export default function ReceiptOCR(props: Props) {
  const {
    receipt,
    actions: {
      uploadImage,
      willLoadFile,
      didFinishFileLoading,
      fileLoadingOnSuccess,
      uploadImageForReceiptOCR,
    },
  } = props

  // state
  // ご利用金額2000円未満エラーを表示するかどうか
  const [showTotalPriceError, setShowTotalPriceError] =
    React.useState<boolean>(false)

  // ファイルエラー
  const [fileError, setFileError] = React.useState<FileError>()

  // 入力した ご利用金額（税込）
  const [totalPrice, setTotalPrice] = React.useState('')

  // OCRで読み取ったご利用金額（税込）
  const [ocrTotalPrice, setOCRTotalPrice] = React.useState('')

  // OCRで読み取った金額を手入力で変更したかどうか
  const [userModifiedTotalPriceAfterOCR, setUserModifiedTotalPriceAfterOCR] =
    React.useState<boolean>()

  // OCR結果
  const [ocrIsSuccess, setOCRIsSuccess] = React.useState<boolean>()

  React.useEffect(() => {
    // 同一ファイル選択時にOCRをトリガーするためにfileDataURLが空文字になることで
    // base64EncodedStringも空文字になるがその時はOCRは行わない
    if (receipt.fileDataURL == null || receipt.fileDataURL === '') return

    // OCR
    uploadImageForReceiptOCR(
      receipt.fileDataURL,
      setTotalPrice,
      setOCRTotalPrice,
      setOCRIsSuccess,
      setUserModifiedTotalPriceAfterOCR
    )
  }, [receipt.fileDataURL, uploadImageForReceiptOCR])

  const totalPriceIsEnough = React.useMemo(
    () => Number(totalPrice) >= 2000,
    [totalPrice]
  )

  const doneButtonDisabled = React.useMemo(() => {
    // 金額4桁以上の数字 かつ 2000円以上
    if (totalPrice.match(/^\d{4,}$/) !== null && totalPriceIsEnough) {
      // 決定する ボタンが活性化するタイミングでご利用金額2000円未満エラーは非表示にする
      setShowTotalPriceError(false)
      return false
    }
    return true
  }, [totalPrice, totalPriceIsEnough])

  const message = React.useMemo(() => {
    if (ocrIsSuccess == null) return ''
    if (!ocrIsSuccess) return receiptOCRResultMessageFailure

    // OCR読取時に金額が2,000円未満の場合はご利用金額2000円未満エラーを表示する
    setShowTotalPriceError(!(Number(ocrTotalPrice) >= 2000))

    return receiptOCRResultMessageSuccess
  }, [ocrIsSuccess, ocrTotalPrice])

  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.title}>レシート登録</Text>
        <Text
          style={
            message === receiptOCRResultMessageSuccess
              ? styles.message
              : [styles.message, styles.messageError]
          }
        >
          {message}
        </Text>
        <View style={styles.imageContainer}>
          <img
            alt=""
            src={receipt.fileDataURL}
            className={stylesModule.receiptImage}
          />
          <View style={styles.priceContainer}>
            <Text style={styles.totalPriceLabel}>ご利用金額（税込）</Text>
            <View style={styles.totalPriceInputContainer}>
              <TextInput
                style={styles.totalPriceInput}
                value={totalPrice}
                onChangeText={(newText) => {
                  setTotalPrice(newText)
                  // OCRで読み取った金額を手入力で変更した フラグを立てる
                  if (ocrIsSuccess != null && ocrIsSuccess) {
                    setUserModifiedTotalPriceAfterOCR(true)
                  }
                }}
                onBlur={() => {
                  setShowTotalPriceError(!totalPriceIsEnough)
                }}
                keyboardType="number-pad"
              />
              <Text style={styles.yenLabel}>円</Text>
            </View>
          </View>
        </View>
        {/* ご利用金額2000円未満エラー表示 */}
        {showTotalPriceError && <ErrorView error="TotalPrice" />}
        {/* ファイルエラー表示 */}
        {fileError !== undefined && <ErrorView error={fileError} />}
        <ButtonSubmit
          noImage
          onPress={() => {
            // レシートアップロード
            uploadImage(Number(totalPrice), userModifiedTotalPriceAfterOCR)
          }}
          disabled={doneButtonDisabled}
          text="決定する"
          buttonStyle={
            doneButtonDisabled ? styles.doneButtonDisabled : styles.doneButton
          }
          textStyle={styles.doneButtonText}
        />
        <FileButton
          noImage
          text="やり直す"
          handleChooseFile={(e) => {
            loadFile(
              e,
              willLoadFile,
              didFinishFileLoading,
              (
                fileDataURL: string,
                fileBlob: Blob,
                fileType: string,
                fileExtension: string
              ) => {
                setFileError(undefined)
                fileLoadingOnSuccess(
                  fileDataURL,
                  fileBlob,
                  fileType,
                  fileExtension
                )
              },
              (error: FileError) => {
                setFileError(error)
              }
            )
          }}
          buttonStyle={styles.redoButton}
          textStyle={styles.redoButtonText}
        />
      </View>
      <CampaignDetail />
    </View>
  )
}
