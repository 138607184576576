import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { Domain } from '../../../core'
import Card from './Card'
import Banner from './Banner'
import QnA from '../../share/QnA'
import PrivacyPolicy from '../../share/PrivacyPolicy'
import PageTitle from '../../share/PageTitle'

const styles = StyleSheet.create({
  cardWrapper: {
    paddingHorizontal: 15,
  },
})

interface Props {
  tenant: Domain.Tenant.Entity
}

export default function SelectCard(props: Props) {
  const { tenant } = props
  const cards = [
    {
      title: 'TOKYU CARD',
      subTitle: '(ClubQ、提携カード含む)',
      imageSrc: require('../../../assets/images/normal_card.png'),
      link: '/normal-card-input',
      allowRegister: tenant.allowRegisterStandard,
    },
    {
      title: 'TOKYU POINT CARD（赤）',
      subTitle: '',
      imageSrc: require('../../../assets/images/red_card.png'),
      link: '/red-card-input',
      allowRegister: tenant.allowRegisterRed,
    },
    {
      title: 'TOP&現金専用カード（緑）',
      subTitle: '',
      imageSrc: require('../../../assets/images/green_card.png'),
      link: '/green-card-input',
      allowRegister: tenant.allowRegisterGreen,
    },
  ]

  const banners = [tenant.bannerLink.topBanner, tenant.bannerLink.bottomBanner]
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const getOffset = React.useCallback(() => window.scrollY, [])
  return (
    <View>
      <View style={styles.cardWrapper}>
        <PageTitle title="カードを選択してください" />
        {cards.map((card, index: number) => {
          if (card.allowRegister) {
            return (
              <Card
                title={card.title}
                subTitle={card.subTitle}
                imageSrc={card.imageSrc}
                link={card.link}
                getOffset={getOffset}
                key={index}
              />
            )
          }
          return null
        })}
      </View>
      {banners.map((banner, index: number) => {
        if (banner.imageURL) {
          return (
            <Banner
              imageSrc={banner.imageURL}
              link={banner.redirectURL}
              getOffset={getOffset}
              key={index}
            />
          )
        } else {
          return null
        }
      })}
      <QnA />
      <PrivacyPolicy />
    </View>
  )
}
