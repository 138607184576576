import { View, Text, StyleSheet } from 'react-native'
import { Domain } from '../../../core'
import { COLORS, FONTS } from '../../../constants'
import stylesModule from './styles.module.css'
import React from 'react'

const styles = StyleSheet.create({
  productItem: {
    width: 'calc(100% /3)',
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.PINK_A,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  productContent: {
    justifyContent: 'space-between',
    height: '100%',
    flexShrink: 1,
    padding: 8,
  },
  productCategory: {
    color: COLORS.GRAY_F,
    fontSize: 11,
    lineHeight: 11,
    paddingBottom: 8,
  },
  productDescription: {
    color: COLORS.GRAY_H,
    fontSize: FONTS.SIZE.XS,
    lineHeight: 16,
  },
  productPrice: {
    color: COLORS.RED_A,
    fontSize: 15,
    lineHeight: 13,
    fontWeight: '600',
    marginTop: 8,
  },
  imageWrapper: {
    width: '100%',
    height: '124px',
  },
})

interface Props {
  product: Domain.Product.ListEntity
}

export default function ProductItem(props: Props) {
  const { product } = props
  const [productItem, setProductItem] = React.useState(product)

  React.useEffect(() => {
    setProductItem(product)
  }, [product])

  const urlImage = `${process.env.REACT_APP_CAMPAIGN_BUCKET}/master/products/images/`

  return (
    <View style={styles.productItem}>
      <View style={styles.imageWrapper}>
        <img
          className={stylesModule.productImage}
          src={`${urlImage}${product.prd_image}`}
          alt=""
        />
      </View>
      <View style={styles.productContent}>
        <View>
          <Text style={styles.productCategory}>{productItem.maker_name}</Text>
          <Text
            style={styles.productDescription}
          >{`${productItem.prd_name} ${productItem.standards}`}</Text>
        </View>
        <Text
          style={styles.productPrice}
        >{`${productItem.point}ポイント`}</Text>
      </View>
    </View>
  )
}
