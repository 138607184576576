import React from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import InfiniteScroll from 'react-infinite-scroller'
import { useNavigate } from 'react-router-dom'
import { Domain } from '../../../core'
import { COLORS } from '../../../constants'
import Event from '../../share/Event'
import ButtonArrow from '../../share/ButtonArrow'
import arrowLeftIcon from '../../../assets/images/arrow_left.svg'
import stylesModule from './styles.module.css'

const styles = StyleSheet.create({
  generalPadding: {
    paddingHorizontal: 16,
  },
  primaryBg: {
    backgroundColor: COLORS.PINK_A,
  },
  activeEventSection: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  bannerSection: {
    paddingBottom: 8,
  },
  buttonWrap: {
    marginTop: 16,
  },
  loadingIcon: {
    marginVertical: 15,
  },
})

type PaginationObjectType = {
  length: number
  numberItemsPagination: number
}
interface Props {
  activeEventList: Array<Domain.Event.ListEntity>
  currentDate: string
}

export default React.memo(function ActiveEventList(props: Props) {
  const { activeEventList, currentDate } = props

  const initialCount = 10
  const [activeEvents, setActiveEvents] = React.useState<
    Array<Domain.Event.ListEntity>
  >([])
  const [deactiveEvents, setDeactiveEvents] = React.useState<
    Array<Domain.Event.ListEntity>
  >([])
  const [numberItemsPaginationActive, setNumberItemsPaginationActive] =
    React.useState(initialCount)
  const [numberItemsPaginationDeactive, setNumberItemsPaginationDeactive] =
    React.useState(initialCount)
  const [isLoadMoreActiveEvent, setIsLoadMoreActiveEvent] = React.useState(true)
  const [isHasMore, setIsHasMore] = React.useState(false)

  function sortEvent(arr: Array<Domain.Event.ListEntity>) {
    return arr.sort(
      (a: Domain.Event.ListEntity, b: Domain.Event.ListEntity) => {
        return Date.parse(b.startDate) - Date.parse(a.startDate)
      }
    )
  }

  React.useEffect(() => {
    const activeEventsTemp = activeEventList.filter(
      (event: Domain.Event.ListEntity) => {
        return (
          Date.parse(event.startDate) <= Date.parse(currentDate) &&
          Date.parse(currentDate) <= Date.parse(event.endDate)
        )
      }
    )
    setActiveEvents(sortEvent(activeEventsTemp))
    if (activeEventsTemp.length > initialCount) {
      setIsHasMore(true)
    }
  }, [activeEventList, currentDate, setActiveEvents])

  React.useEffect(() => {
    const deactiveEventsTemp = activeEventList.filter(
      (event: Domain.Event.ListEntity) => {
        return (
          Date.parse(event.endDate) <= Date.parse(currentDate) &&
          Date.parse(currentDate) <= Date.parse(event.displayEndDate)
        )
      }
    )
    setDeactiveEvents(sortEvent(deactiveEventsTemp))
    if (deactiveEventsTemp.length > initialCount) {
      setIsHasMore(true)
    }
  }, [activeEventList, currentDate, setDeactiveEvents])

  const loadMoreItems = (arg: PaginationObjectType) => {
    if (window.scrollY !== 0) {
      let numberItems
      if (arg.numberItemsPagination + initialCount < arg.length) {
        numberItems = arg.numberItemsPagination + initialCount
      } else {
        numberItems = arg.length
        setIsLoadMoreActiveEvent(false)
      }
      if (isLoadMoreActiveEvent) {
        setNumberItemsPaginationActive(numberItems)
      } else {
        setNumberItemsPaginationDeactive(numberItems)
        if (arg.numberItemsPagination + initialCount >= arg.length) {
          setIsHasMore(false)
        }
      }
    }
  }

  const navigate = useNavigate()

  const goToDetail = React.useCallback(
    (eventId: string) => {
      const location = {
        pathname: `/events/${eventId}`,
      }
      const locationHasSearch = { ...location, search: 'back=true' }
      navigate(locationHasSearch)
    },
    [navigate]
  )

  const goToEndedEvent = React.useCallback(() => {
    const location = {
      pathname: `/ended-events`,
    }
    navigate(location)
  }, [navigate])

  const paginationObject: PaginationObjectType = {
    length: isLoadMoreActiveEvent ? activeEvents.length : deactiveEvents.length,
    numberItemsPagination: isLoadMoreActiveEvent
      ? numberItemsPaginationActive
      : numberItemsPaginationDeactive,
  }

  return (
    <View
      style={[styles.primaryBg, styles.activeEventSection]}
      nativeID={'events-list'}
    >
      <View style={[styles.bannerSection, styles.generalPadding]}>
        <img
          className={stylesModule.thumbnail}
          src={require('../../../assets/images/active_event_banner.png')}
          alt=""
        />
      </View>

      <InfiniteScroll
        pageStart={0}
        loadMore={() => loadMoreItems(paginationObject)}
        hasMore={isHasMore}
        loader={
          <ActivityIndicator
            key={0}
            animating
            color={COLORS.GRAY_C}
            size={30}
            style={styles.loadingIcon}
          />
        }
        initialLoad={false}
      >
        <Event
          lists={activeEvents.slice(0, numberItemsPaginationActive)}
          title={'開催中のキャンペーン'}
          theme={'light'}
          emptyMsg={
            '現在募集中のキャンペーンはありません。次回の開催をお楽しみに！'
          }
          onPress={goToDetail}
          isShowEmpty={!activeEventList.length}
        />
        <Event
          lists={deactiveEvents.slice(0, numberItemsPaginationDeactive)}
          title={'開催中のキャンペーン'}
          theme={'light'}
          emptyMsg={
            '現在募集中のキャンペーンはありません。次回の開催をお楽しみに！'
          }
          onPress={goToDetail}
        />
      </InfiniteScroll>
      <View style={[styles.generalPadding, styles.buttonWrap]}>
        <ButtonArrow
          onPress={goToEndedEvent}
          text="終了したキャンペーンを見る"
          scrollTarget=""
          arrowIcon={arrowLeftIcon}
        />
      </View>
    </View>
  )
})
