import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import Modal from '../Modal'
import { Domain } from '../../../core'

const styles = StyleSheet.create({
  modalButton: {
    margin: 'auto'
  },
  modalButtonLabel: {
    fontSize: 17,
    fontWeight: 'bold',
  },
})

interface Props {
  error: Domain.ApiErrorResponse.Entity
  onPress: () => void
}

export default function ModalErrorAlert(props: Props) {
  const { error, onPress } = props
  return (
    <Modal needsToShow={error !== null} text={error.errorMessage}>
      <TouchableOpacity style={styles.modalButton} onPress={onPress}>
        <Text style={styles.modalButtonLabel}>OK</Text>
      </TouchableOpacity>
    </Modal>
  )
}
