import { Dispatch } from 'redux'
import * as Domain from '../domain'
import { setLocation } from '../modules/location'

type Data = Domain.Location.Entity

// ----- SET Location -----
export function didCallSetLocation(data: Data) {
  return function(dispatch: Dispatch) {
    dispatch(setLocation(data))
  }
}
