import { RecoverableError, ApiErrorResponse } from '../domain'

type State = ApiErrorResponse.Entity | RecoverableError | null

export function createInitialState(): State {
  return null
}

export const SET_ERROR = 'error/set' as const
export const CLEAR_ERROR = 'error/clear' as const

export function setError(error: ApiErrorResponse.Entity | RecoverableError) {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  }
}
export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

type SetErrorAction = ReturnType<typeof setError>
type ClearErrorAction = ReturnType<typeof clearError>
export type Action = SetErrorAction | ClearErrorAction

export function reducer(state = createInitialState(), action: Action): State {
  switch (action.type) {
    case SET_ERROR:
      return action.payload.error
    case CLEAR_ERROR:
      return null
    default:
      return state
  }
}
