import React from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import { COLORS } from '../../../constants'
import { Domain } from '../../../core'
import { SortType } from '../../../constants/sort-type'
import ProductItem from './ProductItem'
import InfiniteScroll from 'react-infinite-scroller'
import Select from '../../share/Select'
import stylesModule from './styles.module.css'

const styles = StyleSheet.create({
  primaryBg: {
    backgroundColor: COLORS.PINK_A,
  },
  paddingWrap: {
    padding: 16,
    paddingTop: 0,
  },
  productList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  formSelect: {
    flexDirection: 'row',
    backgroundColor: COLORS.PINK_A,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 15,
    justifyContent: 'space-between',
  },
  selectCategory: {
    width: 'calc(60% - 8px)',
  },
  selectSort: {
    flexShrink: 1,
    marginLeft: 8,
    width: '40%',
  },
  loadingIcon: {
    marginTop: 10,
    marginBottom: 70,
  },
})
interface Props {
  listProductCampaign: Array<Domain.Product.ListEntity>
  getStatusLoadMore: (status: boolean) => void
}

const listSort = [
  { id: SortType.default, name: 'ポイント昇順' },
  { id: SortType.decreasing, name: 'ポイント降順' },
]

function ProductlistWrapper(props: Props) {
  const { listProductCampaign, getStatusLoadMore } = props
  const [listProducts, setListProduct] =
    React.useState<Array<Domain.Product.ListEntity>>(listProductCampaign)
  const [listCategories, setListCategories] = React.useState<
    Array<Domain.Product.ListSelect>
  >([])

  const [valueSort, setValueSort] = React.useState(1)
  const initialLoad = 24
  const [isHasMore, setIsHasMore] = React.useState(true)
  const [records, setRecords] = React.useState(0)

  const showItemProduct = (products: Array<Domain.Product.ListEntity>) => {
    const items = []
    for (let i = 0; i < records; i++) {
      items.push(<ProductItem product={products[i]} key={i} />)
    }
    return items
  }

  const loadMoreProducts = () => {
    const lastRecords = listProducts.length - records
    const newRecords =
      lastRecords < initialLoad ? records + lastRecords : records + initialLoad

    if (records === listProducts.length) {
      setIsHasMore(false)
      getStatusLoadMore(false)
    } else {
      setRecords(newRecords)
    }
  }

  const sortByPoint = (
    sortType: number,
    productSortList: Array<Domain.Product.ListEntity>
  ) => {
    return productSortList.sort((firstItem, secondItem) => {
      if (firstItem.point !== secondItem.point) {
        if (+sortType === 1) {
          return firstItem.point - secondItem.point
        } else {
          return secondItem.point - firstItem.point
        }
      }
      return firstItem.orderId - secondItem.orderId
    })
  }

  const handleChangeSort = (
    e: React.ChangeEvent<HTMLSelectElement>,
    listProductSort: Array<Domain.Product.ListEntity>
  ) => {
    setValueSort(+e.target.value)
    const newList = sortByPoint(+e.target.value, listProductSort)
    setListProduct(newList)
  }

  const handleFilterCategories = (
    e: React.ChangeEvent<HTMLSelectElement>,
    listProductFilter: Array<Domain.Product.ListEntity>
  ) => {
    const newList = listProductFilter.filter(
      (x) => +x.category_id === +e.target.value
    )
    const newListSort =
      e.target.value === 'default'
        ? sortByPoint(valueSort, listProductCampaign)
        : sortByPoint(valueSort, newList)
    setRecords(newListSort.length)
    setListProduct(newListSort)
  }

  const removeDuplicates = (
    listProductCurrent: Array<Domain.Product.ListEntity>
  ) => {
    return listProductCurrent.reduce(
      (
        newListProduct: Array<Domain.Product.ListEntity>,
        item: Domain.Product.ListEntity
      ) => {
        const removed = newListProduct.filter(
          (x: Domain.Product.ListEntity) => x.category_id !== item.category_id
        )
        return [...removed, item]
      },
      []
    )
  }

  const getListCategories = React.useCallback(
    (productList: Array<Domain.Product.ListEntity>) => {
      const newList = removeDuplicates(productList)
      const items = [{ id: 'default', name: '指定なし' }]
      for (let i = 0; i < newList.length; i++) {
        items.push({
          id: `${newList[i].category_id}`,
          name: newList[i].category_name,
        })
      }
      return items
    },
    []
  )

  React.useEffect(() => {
    const newList = sortByPoint(SortType.default, listProductCampaign)
    const listCategory = getListCategories(listProductCampaign)
    setRecords(
      listProductCampaign.length > initialLoad
        ? initialLoad
        : listProductCampaign.length
    )
    setListProduct(newList)
    setListCategories(listCategory)
  }, [listProductCampaign, getListCategories])

  return (
    <View nativeID="product-list">
      <View style={styles.primaryBg}>
        <View style={styles.paddingWrap}>
          <img
            className={stylesModule.thumbnail}
            src={require('../../../assets/images/img_logo_point.png')}
            alt=""
          />
        </View>
      </View>
      <View style={styles.formSelect} nativeID="filter">
        <View style={styles.selectCategory}>
          <Select
            handleChange={(e) => handleFilterCategories(e, listProductCampaign)}
            listSelect={listCategories}
          />
        </View>
        <View style={styles.selectSort}>
          <Select
            handleChange={(e) => handleChangeSort(e, listProducts)}
            listSelect={listSort}
          />
        </View>
      </View>
      {listProducts.length > 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            setTimeout(() => {
              loadMoreProducts()
            }, 500)
          }}
          hasMore={isHasMore}
          initialLoad={false}
          loader={
            <View style={styles.loadingIcon}>
              <ActivityIndicator
                key={0}
                animating
                color={COLORS.GRAY_C}
                size={30}
              />
            </View>
          }
        >
          <View style={styles.productList}>
            {showItemProduct(listProducts)}
          </View>
        </InfiniteScroll>
      )}
    </View>
  )
}
export default ProductlistWrapper
