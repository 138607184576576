import React from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import { Domain, Functions } from '../../../core'
import { COLORS, FONTS } from '../../../constants'
import stylesModule from './styles.module.css'

const styles = StyleSheet.create({
  contentWrap: {
    width: '70%',
    justifyContent: 'space-between',
    paddingLeft: 8,
  },
  imageWrap: {
    width: '30%',
    backgroundColor: COLORS.WHITE,
  },
  eventSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
    marginTop: 8,
    borderRadius: 6,
  },
  eventWrap: {
    marginHorizontal: 16,
  },
  entryLabel: {
    display: 'flex',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 28,
    marginTop: 30,
    fontSize: FONTS.SIZE.XL,
  },
  emptyMsg: {
    marginTop: 30,
    fontSize: FONTS.SIZE.M,
  },
  imageWrapper: {
    alignItems: 'center',
    marginTop: 30,
  },
  image: {
    width: 150,
    resizeMode: 'contain',
    paddingBottom: '50%',
    marginBottom: 30,
  },
})

interface Props {
  lists: Array<Domain.Event.ListEntity>
  title?: string
  theme: string
  emptyMsg?: string
  isShowEmpty?: boolean
  isHideEntryStatus?: boolean
  onPress: (eventId: string) => void
}

export default function EventList(props: Props) {
  const {
    lists,
    title,
    emptyMsg,
    onPress,
    theme,
    isShowEmpty,
    isHideEntryStatus,
  } = props
  return (
    <View style={styles.eventWrap}>
      {isShowEmpty && (
        <>
          {title && <Text style={styles.title}>{title}</Text>}
          <Text style={styles.emptyMsg}>{emptyMsg}</Text>
          <View style={styles.imageWrapper}>
            <Image
              source={require('../../../assets/images/empty-event-list.png')}
              style={styles.image}
            />
          </View>
        </>
      )}
      {lists.length > 0 &&
        lists.map((eventItem: Domain.Event.ListEntity) => {
          const eventImageSrc = {
            backgroundImage: `url("${eventItem.eventImage}")`,
          }
          const entryLabelBg = {
            backgroundColor: eventItem.haveEntried ? '#596275' : '#E66767',
          }
          const entryLabelText = eventItem.haveEntried
            ? 'エントリー済'
            : isHideEntryStatus
            ? null
            : '要エントリー'
          const eventStyle = {
            backgroundColor: theme === 'dark' ? COLORS.GRAY_E : COLORS.WHITE,
          }

          return (
            <div
              onClick={() => onPress(eventItem.eventId)}
              key={eventItem.eventId}
            >
              <View style={[styles.eventSection, eventStyle]}>
                {!!eventItem.eventImage && (
                  <View style={styles.imageWrap}>
                    <div
                      className={stylesModule.eventImage}
                      style={eventImageSrc}
                    />
                  </View>
                )}
                <View style={styles.contentWrap}>
                  <div className={stylesModule.titleEvent}>
                    {eventItem.headLineText}
                  </div>
                  <div className={stylesModule.dateEvent}>
                    {Functions.Format.formatEndedDate(eventItem.endDate)}
                  </div>
                  <Text style={styles.entryLabel}>
                    {entryLabelText && (
                      <span
                        style={entryLabelBg}
                        className={stylesModule.entryLabel}
                      >
                        {entryLabelText}
                      </span>
                    )}
                  </Text>
                </View>
              </View>
            </div>
          )
        })}
    </View>
  )
}
