function formatDate(dateParams: string) {
  const date = new Date(dateParams).getDate()
  const month = new Date(dateParams).getMonth() + 1
  return `${month}/${date}`
}

function formatEndDate(dateParams: string) {
  const year = new Date(dateParams).getFullYear()
  const date = new Date(dateParams).getDate()
  const month = new Date(dateParams).getMonth() + 1
  return `${year}/${month}/${date}まで`
}

export function formatStartEndDate(startDate: string, endDate: string) {
  return `${formatDate(startDate)} ~ ${formatDate(endDate)}`
}
export function formatEndedDate(endDate: string) {
  return `${formatEndDate(endDate)}`
}
export function clearText(text: string) {
  return text.trim().replace(/\s\s+/g, ' ')
}

export function formatPrice(price: number) {
  return Intl.NumberFormat('ja-JP').format(price)
}
