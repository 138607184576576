import { Dispatch } from 'redux'
import { Municipality, Store } from '../domain'
import { Api } from '../../lib'
import { setMunicipalities } from '../modules/municipalities'
import { setStores } from '../modules/stores'

interface Data {
  allMunicipalities: Array<Municipality.Entity>
  storeList: Array<Store.Entity>
}

// ----- GET /municipalities API -----
function didCallFetchMunicipalitiesAndStoresApi(data: Data, dispatch: Dispatch) {
  dispatch(setStores(data.storeList))
  dispatch(setMunicipalities(data.allMunicipalities))
}

export function fetchStores (reloader: () => void, prefectureCd: string | undefined) {
  const options = {
    reload: reloader,
  }

  return async function (dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      'nearbystores',
      `&prefectureCd=${prefectureCd}`,
      null,
      didCallFetchMunicipalitiesAndStoresApi,
      dispatch,
      options,
    )
  }
}
