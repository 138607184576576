import * as React from 'react'
import { View } from 'react-native'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Receipt from '../components/screens/Receipt'
import { Modules } from '../core'
import { beginCommunication, endCommunication } from '../core/modules/network'

const { liff } = window

export default function ReceiptContainer() {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const navigateToReceiptOCR = React.useCallback(() => {
    navigate('/receipt-ocr')
  }, [navigate])

  const actions = React.useMemo(
    () => ({
      willLoadFile: () => {
        dispatch(beginCommunication())
      },
      didFinishFileLoading: () => {
        dispatch(endCommunication())
      },
      fileLoadingOnSuccess: (
        fileDataURL: string,
        fileBlob: Blob,
        fileType: string,
        fileExtension: string
      ) => {
        dispatch(
          Modules.Receipt.setFile(
            fileDataURL,
            fileBlob,
            fileType,
            fileExtension
          )
        )
        dispatch(Modules.Receipt.setIsSmartReceipt(false))
        navigateToReceiptOCR()
      },
    }),
    [dispatch, navigateToReceiptOCR]
  )

  const receipt = useSelector(
    (state: Modules.AppState) => state.receipt,
    shallowEqual
  )

  if (receipt.event == null) {
    // ブラウザリロード時LIFFアプリを閉じる
    liff.closeWindow()
    return <View />
  }
  return <Receipt actions={actions} />
}
