import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { STYLES } from '../../../constants'
import QnA from '../../share/QnA'
import PageTitle from '../../share/PageTitle'
import ButtonSubmit from '../../share/ButtonSubmit'
import { useSelector } from 'react-redux'
import { Modules } from '../../../core'
import { handleStoreGenreTitle } from '../../../core/functions/text'

const PAGE_TITLE = '{value0}を登録しました'
const TENANT_ID = process.env.REACT_APP_TENANT_ID

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 16,
    lineHeight: 24,
  },
})

export default function StoreRegistrationCompleted() {
  const tenant = useSelector((state: Modules.AppState) => state.tenant)
  const navigate = useNavigate()
  const navigateToRegisteredStoreList = React.useCallback(
    () => navigate('/registered-store-list'),
    [navigate]
  )
  const onPress = React.useCallback(() => {
    navigateToRegisteredStoreList()
  }, [navigateToRegisteredStoreList])

  return (
    <View style={STYLES.generalStyle.container}>
      <PageTitle
        title={
          TENANT_ID?.includes('0302')
            ? '施設利用用途を登録いたしました。'
            : handleStoreGenreTitle(PAGE_TITLE, tenant.category)
        }
      />
      <Text style={styles.subtitle}>
        {tenant.text.contentStoreRegistrationCompleted}
      </Text>
      <ButtonSubmit onPress={onPress} disabled={false} text="確認する" />
      <QnA />
    </View>
  )
}
