import { LiffError, LoginConfig } from '../../@types/liff'

const { liff } = window

export function init(isToLCampaign: boolean): Promise<boolean> {
  const LIFF_ID = isToLCampaign
    ? process.env.REACT_APP_TOL_CAMPAIGN_LIFF_ID
    : process.env.REACT_APP_LIFF_ID
  if (!LIFF_ID) {
    throw new Error('LIFF ID is undefined')
  }
  return liff
    .init({
      liffId: LIFF_ID,
    })
    .then(() => {
      return true
    })
    .catch((error: LiffError) => {
      throw error
    })
}

export function signIn(
  loginConfig: LoginConfig | undefined = undefined
): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      if (!liff.isLoggedIn()) {
        liff.login(loginConfig)
      }
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export function getAccessToken() {
  return liff.getAccessToken()
}
