import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../constants'
import { FileError, loadFile } from '../../../core/functions/file'
import FileButton from '../EventDetail/100th/FileButton'
import stylesModule from './styles.module.css'
import ErrorView from '../../share/100th/ErrorView'
import CampaignDetail from '../../share/100th/CampaignDetail'

interface Props {
  actions: {
    willLoadFile: () => void
    didFinishFileLoading: () => void
    fileLoadingOnSuccess: (
      fileDataURL: string,
      fileBlob: Blob,
      fileType: string,
      fileExtension: string
    ) => void
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    paddingStart: 16,
    paddingEnd: 16,
    paddingBottom: 24,
    backgroundColor: COLORS.WHITE,
  },
  titleLabel: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 30,
    color: COLORS.ORANGE,
    textAlign: 'center',
  },
  itemContainer: {
    marginTop: 16,
    backgroundColor: COLORS.PINK_A,
    padding: 16,
  },
  itemImageContainer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  itemImageTitleLabel: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    color: COLORS.TOKYU_BLACK,
  },
  itemImageBodyLabel: {
    width: '58%',
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.36,
    color: COLORS.GRAY_H,
  },
  twoColumnsImageContainer: {
    marginTop: 16,
    minHeight: 270,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumnContainer: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: COLORS.PINK_A,
    paddingTop: 16,
    paddingStart: 22,
    paddingEnd: 22,
    paddingBottom: 25,
  },
  rightColumnContainer: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: COLORS.PINK_A,
    marginStart: 17,
    paddingTop: 16,
    paddingStart: 22,
    paddingEnd: 22,
    paddingBottom: 25,
  },
  imageInColumnTitleLabel: {
    marginBottom: 14,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    letterSpacing: -0.24,
    color: COLORS.TOKYU_BLACK,
    textAlign: 'center',
  },
  noteContainer: {
    marginTop: 20,
  },
  noteTitleLabel: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 20,
    letterSpacing: -0.24,
    color: COLORS.TOKYU_BLACK,
  },
  noteBodyLabel: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 22,
    letterSpacing: -0.3,
    color: COLORS.GRAY_H,
  },
  fileButton: {
    marginTop: 24,
  },
})

const ss01 = require('../../../assets/resources/100th/receipt_ss01.png')
const ss02 = require('../../../assets/resources/100th/receipt_ss02.png')
const ss03 = require('../../../assets/resources/100th/receipt_ss03.png')
const ss04 = require('../../../assets/resources/100th/receipt_ss04.png')
const ss05 = require('../../../assets/resources/100th/receipt_ss05.png')
const ss06 = require('../../../assets/resources/100th/receipt_ss06.png')

export default function Receipt(props: Props) {
  const {
    actions: { willLoadFile, didFinishFileLoading, fileLoadingOnSuccess },
  } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // ファイルエラー
  const [fileError, setFileError] = React.useState<FileError>()

  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.titleLabel}>レシート画像の撮影方法</Text>
        <View style={styles.itemContainer}>
          <Text style={styles.itemImageTitleLabel}>全体を撮影してください</Text>
          <View style={styles.itemImageContainer}>
            <Text style={styles.itemImageBodyLabel}>
              レシートの内容がすべて分かるように1枚の写真で撮影してください。
            </Text>
            <img src={ss01} alt="" className={stylesModule.image1} />
          </View>
        </View>
        <View style={styles.itemContainer}>
          <Text style={styles.itemImageTitleLabel}>
            領収書・明細書は１画像に
          </Text>
          <View style={styles.itemImageContainer}>
            <Text style={styles.itemImageBodyLabel}>
              領収書・明細書が2枚以上の場合、1枚の画像に収まるよう全体を撮影してください。
            </Text>
            <img src={ss02} alt="" className={stylesModule.image1} />
          </View>
        </View>
        <View style={styles.twoColumnsImageContainer}>
          <View style={styles.leftColumnContainer}>
            <Text style={styles.imageInColumnTitleLabel}>
              個人情報の記載がある場合は隠してください
            </Text>
            <img src={ss03} alt="" className={stylesModule.imageInColumn} />
          </View>
          <View style={styles.rightColumnContainer}>
            <Text style={styles.imageInColumnTitleLabel}>長いものも１枚に</Text>
            <img src={ss04} alt="" className={stylesModule.imageInColumn} />
          </View>
        </View>
        <View style={styles.twoColumnsImageContainer}>
          <View style={styles.leftColumnContainer}>
            <Text style={styles.imageInColumnTitleLabel}>ブレないように</Text>
            <img src={ss05} alt="" className={stylesModule.imageInColumn} />
          </View>
          <View style={styles.rightColumnContainer}>
            <Text style={styles.imageInColumnTitleLabel}>光の反射に注意</Text>
            <img src={ss06} alt="" className={stylesModule.imageInColumn} />
          </View>
        </View>
        <View style={styles.noteContainer}>
          <Text style={styles.noteTitleLabel}>その他の注意事項</Text>
          <Text style={styles.noteBodyLabel}>
            ●写真のアップロードは1枚（1会計）のみです。{'\n'}
            ●別会計のレシートを複数枚同時に撮影しても無効になります。（合算不可）
            {'\n'}
            ●レシートの内容が判別できない場合は、応募が無効になります。
          </Text>
        </View>
        {/* ファイルエラー表示 */}
        {fileError !== undefined && <ErrorView error={fileError} />}
        <FileButton
          text="レシートを撮影"
          handleChooseFile={(e) => {
            loadFile(
              e,
              willLoadFile,
              didFinishFileLoading,
              (
                fileDataURL: string,
                fileBlob: Blob,
                fileType: string,
                fileExtension: string
              ) => {
                setFileError(undefined)
                fileLoadingOnSuccess(
                  fileDataURL,
                  fileBlob,
                  fileType,
                  fileExtension
                )
              },
              (error: FileError) => {
                setFileError(error)
              }
            )
          }}
          buttonStyle={styles.fileButton}
        />
      </View>
      <CampaignDetail />
    </View>
  )
}
